import axios from 'axios';
import { API_ENDPOINT } from '../utils/constants/apiConstants';

import { getAuthToken } from "../helpers/storage";

const getAuthHeader = () => {
  const token = getAuthToken();
  return {
    Authorization: `token ${token}`,
  };
};

export const getFavorite = async (id) => {
  const url = `${API_ENDPOINT}favorites/user/${id}/`;
  try {
    const response = await axios.get(url, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const addToFavorite = async (drugstore, is_favorite, user) => {
  const url = `${API_ENDPOINT}favorite/drugstore/`;

  const data = {
            "drugstore": drugstore,
            "is_favorite": is_favorite,
            "user": user
            }

  console.log(data);

  try {
    const response = await axios.post(url, data,{
        headers: getAuthHeader(),
      });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};
