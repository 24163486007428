import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getFromLocalStorage } from "../../helpers/storage";

import {
  NewsList,
  TipsList,
  SignUpLink,
  Message,
} from "../components/projects";
import { SearchStore } from "../components/ui";

// お知らせダミーデータ
const newsItems = [
  {
    date: "2021/12/21",
    time: "13:22",
    text: "お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。",
    id: 1,
  },
  {
    date: "2021/12/21",
    time: "13:22",
    text: "お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。",
    id: 2,
  },
];

export default function Thanks() {
  getFromLocalStorage("token") != null ? navigate("/mypage") : console.log("");
  const navigate = useNavigate();

  const setRoute = () => {
    localStorage.removeItem("token");
    setTimeout(() => {
      navigate("/");
    }, 7000);
  };

  useEffect(() => setRoute(), []);

  return (
    <main className="p-thanks">
      {/* <Message text="会員認証に失敗しました" /> */}

      <p className="c-message-cross">会員認証に失敗しました</p>

      <article className="c-formMessage">
        <p
          className="c-formMessage__bold"
          style={{ display: "flex", justifyContent: "center" }}
        >
          会員認証に失敗したため、トップページに移動します。
          お手数ですが再度会員登録をお試しください。
        </p>
      </article>

      <SignUpLink />

      {/* <section className="p-home__news">
        <h2 className="c-sectionTitle">じぶん薬局からのお知らせ</h2>
        <dl>
          {newsItems.map((item, i) => {
            return (
              <NewsList 
                key={i}
                to={`/news/${item.id}`}
                item={item}
              />
            )
          })}
        </dl>
      </section>

      <section className="p-home__tips">
        <h2 className="c-sectionTitle">おくすりTips</h2>
        <TipsList 
          number={4}
        />
        <div className="c-textLinkBlue">
          <Link to='/'>おくすりTipsのコンテンツ一覧をみる</Link>
        </div>
      </section> */}

      <SearchStore />
    </main>
  );
}
