import { memo } from "react";
import { Link } from "react-router-dom";
import { Pagination } from "antd";

const MemoList = memo((props) => {
  const { created_at, pharmacy_name, pharmacist, title, id } = props.item;
  var date = "";

  for (var i = 0; i < created_at.length; i++) {
    if (created_at[i] == "T") {
      break;
    }
    date = date + created_at[i];
  }

  const itemDrugStore = props.favorites.filter((item) => item.drugstore === Number(pharmacy_name))
  
  return (
    <>
      <li className={`c-mypageList gray`}>
        <Link to={`/mypage/memo/${id}`}>
          <p className="c-mypageList__time">
            <span>{date}</span>
          </p>
          <p className="c-mypageList__hospital">
            {itemDrugStore.length && itemDrugStore[0].drugstore_name}
            <span>{pharmacist}</span>
          </p>
          <p className="c-mypageList__name">{title}</p>
        </Link>
      </li>
    </>
  );
});

export default MemoList;
