import { memo } from "react";
import { Link } from "react-router-dom";

const History = memo((props) => {
  const {
    prescription_date,
    medical_institution_name,
    prescribing_physician,
    name,
    limit,
    id,
  } = props.item;

  return (
    <li className={`c-mypageList ${props.class}`}>
      <Link to={`/mypage/prescription/${id}`}>
        <p className="c-mypageList__time">
          <span>{prescription_date}</span>
        </p>
        <p className="c-mypageList__hospital">
          {medical_institution_name}
          <span>{prescribing_physician}</span>
        </p>
        <p className="c-mypageList__name">{name}</p>
        {limit && props.class === "red" && (
          <span className="c-mypageList__label">
            有効期限 &#058; {limit} まで
          </span>
        )}
        {!limit && props.class === "red" && (
          <span className="c-mypageList__label">未訪問</span>
        )}
      </Link>
    </li>
  );
});

export default History;
