import { memo, useState, useEffect } from "react";
const FormRadioExtended = memo((props) => {
  const {
    title,
    name,
    options,
    required,
    onChange,
    defaultValue,
    errorMessage,
  } = props;
  const [value, setValue] = useState("");

  useEffect(() => {
    console.log(" gopu ", value.length);
    if (props.hasOwnProperty("defaultValue")) {
      if (Object.values(defaultValue).length !== 0) {
        setValue(defaultValue);
      }
    }
  }, [defaultValue]);

  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    }
    setValue(e.target.value);
  };

  return (
    <div className="l-formInput__radio">
      <p>
        {title}
        {required && <span className="l-formInput__required">必須</span>}
      </p>
      {options.map((option, i) => {
        return (
          <label key={i}>
            <input
              type="radio"
              name={name}
              value={option.value}
              onChange={handleChange}
              checked={value == option.value ? "checked" : ""}
              required={required == true ? true : false}
            />
            <span className="l-formInput__radio__text">{option.label}</span>
          </label>
        );
      })}
      <p>
        {errorMessage && (
          <span className="l-formInput__errorMessage">{errorMessage}</span>
        )}
      </p>
    </div>
  );
});

export default FormRadioExtended;
