// 今日
export const todayDate = () => {
  const today = new Date()
  today.setDate(today.getDate())
  const yyyy = today.getFullYear()
  const mm = ("0"+(today.getMonth()+1)).slice(-2)
  const dd = ("0"+today.getDate()).slice(-2)
  const initialValue  =`${yyyy}-${mm}-${dd}`

  return initialValue
}

// n年後
export const laterDate = (value) => {
  const today = new Date()
  today.setDate(today.getDate())
  const yyyy = today.getFullYear() + value
  const mm = ("0"+(today.getMonth()+1)).slice(-2)
  const dd = ("0"+today.getDate()).slice(-2)
  const initialValue  =`${yyyy}-${mm}-${dd}`

  return initialValue
}

// n年前
export const agoDate = (value) => {
  const today = new Date()
  today.setDate(today.getDate())
  const yyyy = today.getFullYear() - value
  const mm = ("0"+(today.getMonth()+1)).slice(-2)
  const dd = ("0"+today.getDate()).slice(-2)
  const initialValue  =`${yyyy}-${mm}-${dd}`

  return initialValue
}