// 処理を伴うボタン
import { memo } from "react";
import { Circles } from "react-loader-spinner";

const MainButton = memo((props) => {
  const { text, onClick, color, disabled } = props;
  var img_name = "";

  if (props.hasOwnProperty("imgName")) {
    img_name = props.imgName;
  }
  return (
    <>
      <button
        className={`c-mainButton ${color}`}
        onClick={onClick && onClick}
        disabled={disabled && disabled}
        style={
          disabled && disabled
            ? { backgroundColor: "#a6e3d8", cursor: "default" }
            : {}
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div>{text}</div>
          {disabled && (
            <div>
              {" "}
              <Circles
                height="20"
                width="20"
                color="#fff"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}
        </div>
      </button>
      <span style={{ top: "-8.3vh", position: "relative" }}>{img_name}</span>
    </>
  );
});

export default MainButton;
