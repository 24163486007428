import { useEffect, useState } from "react";

import { MypageTopMenu } from "../components/ui";
import {
  ItemsList,
  MemoMessage,
  FormDateTimeInput,
  MainButton,
  LinkText,
} from "../components/projects";
import { todayDate } from "../../utils/data";
import { isEntered } from "../../utils/validation";
import getReceipt from "../../apis/prescriptionreceipt";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { getAuthToken } from "../../helpers/storage";
import { cardActionsClasses } from "@mui/material";
import { getDateFromValue } from "../../utils/hooks";
import { Navigate } from "react-big-calendar";
import { margin } from "@mui/system";
import Loader from "../components/ui/loader";

// ダミーデータ
// const item = [
//   {
//     title: "かかりつけ薬局",
//     text: "薬店名",
//   },
//   {
//     title: "予約申請日",
//     text: "2021/12/21 13:00",
//   },
//   {
//     title: "申請する処方箋",
//     date: "2021/12/21",
//     hospital: "医療機関名",
//     doctor: "処方した医師の名前",
//     name: "お薬の名前。お薬の名前。お薬の名前。お薬の名前。",
//     limit: "YYYY年 MM月 DD日",
//     red: true,
//   },
//   {
//     title: "リモート服薬",
//     text: "希望する",
//   },
//   {
//     title: "指導予約日",
//     text: "2021/12/21 13:00",
//   },
//   {
//     title: "服薬指導担当薬剤師",
//     text: "薬剤師名",
//   },
// ];

const message = [
  {
    date: "2021/12/22",
    time: "14:55",
    text: "担当薬剤師からの服薬指導メッセージ。担当薬剤師からの服薬指導メッセージ。担当薬剤師からの服薬指導メッセージ。担当薬剤師からの服薬指導メッセージ。担当薬剤師からの服薬指導メッセージ。担当薬剤師からの",
  },
];

const messageAll = [
  {
    date: "2021/12/22",
    time: "14:55",
    text: "担当薬剤師からの服薬指導メッセージ。担当薬剤師からの服薬指導メッセージ。担当薬剤師からの服薬指導メッセージ。担当薬剤師からの服薬指導メッセージ。担当薬剤師からの服薬指導メッセージ。担当薬剤師からの",
  },
  {
    date: "2021/12/22",
    time: "14:55",
    text: "ユーザーのメッセージ。ユーザーのメッセージ。ユーザーのメッセージ。ユーザーのメッセージ。ユーザーのメッセージ。ユーザーのメッセージ。ユーザーのメッセージ。",
    user: true,
  },
  {
    date: "2021/12/22",
    time: "14:55",
    text: "担当薬剤師からの服薬指導メッセージ。担当薬剤師からの服薬指導メッセージ。担当薬剤師からの服薬指導メッセージ。担当薬剤師からの服薬指導メッセージ。担当薬剤師からの服薬指導メッセージ。担当薬剤師からの",
  },
  {
    date: "2021/12/22",
    time: "14:55",
    text: "ユーザーのメッセージ。ユーザーのメッセージ。ユーザーのメッセージ。ユーザーのメッセージ。ユーザーのメッセージ。ユーザーのメッセージ。ユーザーのメッセージ。",
    user: true,
  },
];

const reservationData = true;
const remortData = true;

export default function Reserve() {
  const initialValue = todayDate;

  const [enable, setEnable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isWrong, setIsWrong] = useState(false);
  const [data, setData] = useState();
  const [drugstore_name, setDrugStore_name] = useState([]);
  const token = "token" + " " + getAuthToken();
  const headers = { Authorization: token };

  const id = useParams("id");
  console.log("id", id);

  const navigate = useNavigate();

  const getData = async () => {
    try {
      const response = await getReceipt.getReceiptDataID(id.id);
      getReceipt.messageSeen(id.id);
      console.log("useEffect works in reserve", response);
      setData(response.data);
      setDrugStore_name(response.data.pharmacy.id);
      setIsLoading(false);
    } catch (e) {
      setIsWrong(true);
    }
  };

  useEffect(() => {
    getAuthToken() == null ? navigate("/") : setEnable(true);
  });
  useEffect(() => getData(), [enable]);

  const item = [
    {
      title: "かかりつけ薬局",
      // text: "薬店名",
      text: data?.pharmacy?.drugstore_name,
      red: false,
    },
    {
      title: "予約申請日",
      text: data?.created_at
        ? moment(data?.created_at).format("YYYY年MM月DD日h:mm")
        : " ",
      red: false,
    },
    {
      title: "申請する処方箋",
      date: data?.guidance_scheduled_time
        ? moment(data?.guidance_scheduled_time).format("YYYY年MM月DD")
        : "",
      prescription: data?.prescription,
      name: data?.patient?.name,
      limit: data?.guidance_scheduled_time
        ? moment(
            moment(data?.guidance_scheduled_time).format("YYYY年MM月DD"),
            "YYYY年MM月DD"
          )
            .add(4, "days")
            .format("YYYY年MM月DD")
        : " ",
      red: true,
    },
    {
      title: "リモート服薬",
      text: data?.is_remote_accepted === true ? "はい" : "いいえ",
      red: false,
    },
    {
      title: "指導予約日",
      text: data?.guidance_scheduled_time
        ? moment(data?.guidance_scheduled_time).format("YYYY年MM月DD日h:mm")
        : " ",
      red: false,
    },
    // {
    //   title: "服薬指導担当薬剤師",
    //   //  text: data?.prescription[0].prescribing_physician,
    //   red: false,
    // },
  ];

  // ラジオボタン
  const reserveOptions = [
    {
      label: "承諾する",
      value: "承諾する",
      initial: true,
    },
    {
      label: "承諾しない",
      value: "承諾しない",
      initial: false,
    },
  ];

  const [reserveValue, setReserveValue] = useState(reserveOptions[0].value);
  const handleChange = (e) => {
    setReserveValue(e.target.value);
  };

  const [teachValue, setTeachValue] = useState(reserveOptions[0].value);
  const handleTeachChange = (e) => {
    setTeachValue(e.target.value);
  };

  // バリデーション
  const [errorMessages, setErrorMessages] = useState({});
  const isValidate = (values) => {
    let messages = {};

    messages.reservationDateTime =
      reserveValue === "承諾しない"
        ? isEntered("予約申請日時", values.reservationDate, false) ||
          isEntered("予約申請日時", values.reservationTime, false)
        : "";
    messages.teachDateTime =
      teachValue === "承諾しない"
        ? isEntered("予約申請日時", values.teachDate, false) ||
          isEntered("予約申請日時", values.teachTime, false)
        : "";

    setErrorMessages({ ...messages });
    console.log(messages);
    return Object.values(messages).every((message) => message === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const values = Object.fromEntries(data.entries());

    if (isValidate(values)) {
      console.log("ok");
      console.log(values);
    }

    const valueObj = {};

    const reservation_scheduled_time = moment(
      values.reservationDate + " " + values.reservationTime
    );

    const guidance_scheduled_time = moment(
      values.teachDate + " " + values.teachTime
    );
    console.log("reservation_scheduled_time");
    console.log(reservation_scheduled_time._i);

    console.log(
      "formatted date",
      moment(reservation_scheduled_time._i).format("YYYY-MM-DDTHH:mm:ms")
    );

    console.log("guidance_scheduled_time");
    console.log(guidance_scheduled_time._i);

    if (values.is_accepted === "承諾しない") {
      valueObj.is_accepted = false;
    }
    if (values.is_accepted === "承諾しない") {
      valueObj.reservation_scheduled_time =
        moment(reservation_scheduled_time._i).format("YYYY-MM-DDThh:mm:00") +
        "Z";
    } else {
      valueObj.is_accepted = true;
    }

    if (values.medication_guidence === "承諾しない") {
      valueObj.medication_guidence = false;
    }
    if (values.medication_guidence === "承諾しない") {
      valueObj.guidance_scheduled_time =
        moment(guidance_scheduled_time._i).format("YYYY-MM-DDThh:mm:00") + "Z";
    } else {
      valueObj.medication_guidence = true;
    }

    valueObj.message = values.message;
    valueObj.pharmacy = drugstore_name;
    console.log("valueObj");
    console.log(valueObj);

    console.log("drug", drugstore_name);
    console.log("valueObj", valueObj);

    try {
      const response = getReceipt.putData(valueObj, headers, id.id);
      console.log("resonse", response);
      // toast.success(
      //   "'メッセージが送信されました",
      //   {
      //     position: toast.POSITION.TOP_CENTER,
      //   },
      //   2000
      // );

      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };
  const line = () => {
    window.open("https://line.me/R/nv/chat");
  };

  if (isLoading) return <Loader />;
  console.log("data", data);
  return (
    <main className="p-reserve">
      <MypageTopMenu />

      <section className="p-reserve__reserve">
        <h2 className="c-sectionTitle c-mypageHeading">処方箋受け取り予約</h2>
        <dl>
          {item
            .filter((d) => d.text != " ")
            .map((value, i) => {
              return <ItemsList key={i} item={value} />;
            })}
        </dl>

        <MainButton
          text="LINE通話でリモート服薬指導を始める"
          color="green"
          onClick={() => {
            line();
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // justifyContent: "center",
          }}
        >
          <p
            style={{
              marginTop: "0px",
              paddingLeft: "27px",
              paddingRight: "27px",
              paddingTop: "0px",
              paddingBottom: "0px",
              marginBottom: "0px",
              font: "11px",
              fontFamily: "NotoSansJP_regular",
            }}
          >
            ※遠隔服薬指導時にはLINEを利用致します。
          </p>
          <p
            style={{
              paddingTop: "-12px",
              paddingLeft: "12px",
              paddingRight: "4px",
              font: "11px",
              fontFamily: "NotoSansJP_regular",
            }}
          >
            薬局とLINEIDの交換を事前にメッセージでお済ませください。
          </p>
        </div>
        {/* <MemoMessage title="予約申請返信メッセージ" message={message} /> */}

        <MemoMessage
          title="これまでのメッセージ"
          message={data?.reservation_messages.filter(
            (d) => d.is_seen_patient != true
          )}
        />

        {(reservationData || remortData) && (
          <div className="p-reserve__new__container">
            <form onSubmit={handleSubmit}>
              {reservationData && (
                <div className="l-formInput__radio">
                  <p>
                    処方受け取り予約日について
                    <span className="l-formInput__required">必須</span>
                  </p>
                  {reserveOptions.map((option, i) => {
                    return (
                      <label key={i}>
                        <input
                          type="radio"
                          name="is_accepted"
                          value={option.value}
                          onChange={handleChange}
                          checked={
                            reserveValue === option.value ? "checked" : ""
                          }
                        />
                        <span className="l-formInput__radio__text">
                          {option.label}
                        </span>
                      </label>
                    );
                  })}
                </div>
              )}
              {reserveValue === "承諾しない" && (
                <FormDateTimeInput
                  label="予約申請日時"
                  required={true}
                  errorMessage={
                    errorMessages.reservationDateTime
                      ? errorMessages.reservationDateTime
                      : ""
                  }
                  initialValue={initialValue}
                  dateName="reservationDate"
                  timeName="reservationTime"
                />
              )}
              {remortData && (
                <div className="l-formInput__radio">
                  <p>
                    リモート服薬指導予約日について
                    <span className="l-formInput__required">必須</span>
                  </p>
                  {reserveOptions.map((option, i) => {
                    return (
                      <label key={i}>
                        <input
                          type="radio"
                          name="medication_guidence"
                          value={option.value}
                          onChange={handleTeachChange}
                          checked={teachValue === option.value ? "checked" : ""}
                        />
                        <span className="l-formInput__radio__text">
                          {option.label}
                        </span>
                      </label>
                    );
                  })}
                </div>
              )}
              {teachValue === "承諾しない" && (
                <FormDateTimeInput
                  label="予約申請日時"
                  required={true}
                  errorMessage={
                    errorMessages.teachDateTime
                      ? errorMessages.teachDateTime
                      : ""
                  }
                  initialValue={initialValue}
                  dateName="teachDate"
                  timeName="teachTime"
                />
              )}
              <div className="l-formInput__form border">
                <label>
                  予約申請メッセージ
                  <span className="l-formInput__any">任意</span>
                </label>
                <textarea
                  name="message"
                  placeholder="予約申請メッセージの詳細を入力してください"
                  className="c-textarea__border"
                />
              </div>

              <MainButton
                text="この内容で処方箋予約を申請する"
                color="blue min"
              />
            </form>
          </div>
        )}

        <MemoMessage
          title="これまでのメッセージ"
          message={data?.reservation_messages.filter(
            (d) => d.message != "" && d.is_seen_patient == true
          )}
        />

        <LinkText
          text="処方受け取り予約一覧に戻る"
          url="/mypage/reserve"
          className="underline"
        />
      </section>
    </main>
  );
}

/* {data.map((value, i) => {
            return <ItemsList key={i} item={value} />;
          })} */

//   {
//     "id": 3,
//     "reservation_messages": [
//         {
//             "id": 61,
//             "sender": {
//                 "id": 5,
//                 "email": "test3@anandan.com"
//             },
//             "message": "cccc",
//             "created_at": "2022-06-16T04:45:31.824879Z",
//             "updated_at": "2022-06-16T04:45:31.824900Z"
//         },
//         {
//             "id": 60,
//             "sender": {
//                 "id": 5,
//                 "email": "test3@anandan.com"
//             },
//             "message": "cccc",
//             "created_at": "2022-06-16T04:45:02.232012Z",
//             "updated_at": "2022-06-16T04:45:02.232034Z"
//         },
//         {
//             "id": 59,
//             "sender": {
//                 "id": 5,
//                 "email": "test3@anandan.com"
//             },
//             "message": "cccc",
//             "created_at": "2022-06-16T04:44:41.395476Z",
//             "updated_at": "2022-06-16T04:44:41.395499Z"
//         },
//         {
//             "id": 58,
//             "sender": {
//                 "id": 5,
//                 "email": "test3@anandan.com"
//             },
//             "message": "cccc",
//             "created_at": "2022-06-16T04:44:38.025348Z",
//             "updated_at": "2022-06-16T04:44:38.025370Z"
//         },
//         {
//             "id": 56,
//             "sender": {
//                 "id": 5,
//                 "email": "test3@anandan.com"
//             },
//             "message": "ddd",
//             "created_at": "2022-06-14T11:51:35.250977Z",
//             "updated_at": "2022-06-14T11:51:35.251002Z"
//         }
//     ],
//     "patient": {
//         "id": 4441,
//         "address": {
//             "id": 7,
//             "address": "Address 1",
//             "city": "City 1",
//             "prefecture": "Prefecture 1",
//             "telephone": "852741963",
//             "patient": 4441
//         },
//         "patient_insurance": [
//             {
//                 "id": 13,
//                 "card_choices": 1,
//                 "card_image": "http://dev-pharmacy.eoraa.com/media/insurance/profilePic_5oecLHX.jpg",
//                 "expiration_date": null,
//                 "date_of_issuance": null,
//                 "symbol": "@1111",
//                 "date_of_qualification": null,
//                 "name_of_head_of_household": null,
//                 "office_name": null,
//                 "insurance_number": "98989898989",
//                 "recipient_name": null,
//                 "insurer_name": null,
//                 "insurer_location": null,
//                 "underlying_disease": "",
//                 "family_medical_institution": null,
//                 "family_dispensing_pharmacy": null,
//                 "pharmacist_in_charge": null,
//                 "patient": 4441
//             },
//             {
//                 "id": 10,
//                 "card_choices": 1,
//                 "card_image": "http://dev-pharmacy.eoraa.com/media/insurance/profilePic_rxkXIl2.jpg",
//                 "expiration_date": "2022-05-25",
//                 "date_of_issuance": "2022-05-11",
//                 "symbol": "hhhh hhhh",
//                 "date_of_qualification": "2022-05-19",
//                 "name_of_head_of_household": null,
//                 "office_name": null,
//                 "insurance_number": "878898889",
//                 "recipient_name": null,
//                 "insurer_name": null,
//                 "insurer_location": null,
//                 "underlying_disease": "",
//                 "family_medical_institution": null,
//                 "family_dispensing_pharmacy": null,
//                 "pharmacist_in_charge": null,
//                 "patient": 4441
//             },
//             {
//                 "id": 9,
//                 "card_choices": 1,
//                 "card_image": "http://dev-pharmacy.eoraa.com/media/insurance/profilePic_0d5TWzz.jpg",
//                 "expiration_date": "2022-05-25",
//                 "date_of_issuance": "2022-05-11",
//                 "symbol": "hhhh hhhh",
//                 "date_of_qualification": "2022-05-19",
//                 "name_of_head_of_household": null,
//                 "office_name": null,
//                 "insurance_number": "878898889",
//                 "recipient_name": null,
//                 "insurer_name": null,
//                 "insurer_location": null,
//                 "underlying_disease": "",
//                 "family_medical_institution": null,
//                 "family_dispensing_pharmacy": null,
//                 "pharmacist_in_charge": null,
//                 "patient": 4441
//             }
//         ],
//         "documents": [],
//         "name": "Ananda",
//         "nickname": "anand",
//         "dob": "2000-05-05",
//         "phone_no": "8247788956",
//         "email": "test3@anandan.com",
//         "gender": "male",
//         "allergy_info": "aafafk",
//         "allergy_reactive": "alkdmafka",
//         "type": 1,
//         "comment": "",
//         "line_id": null,
//         "is_active": "in_use",
//         "is_linked": true,
//         "created_at": "2022-06-15T00:00:00Z",
//         "user": 5
//     },
//     "prescription": [
//         {
//             "id": 13,
//             "prescription_date": "2022-06-06",
//             "time": "11:34:17",
//             "isRed": false,
//             "insurer_number": "Ins_2212",
//             "name": "Mr X",
//             "birthday": "2022-06-06",
//             "gender": "male",
//             "medical_institution_name": "Mr Y",
//             "contact": "9999999999",
//             "prescribing_physician": "Mr Z",
//             "prescription_details": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//             "change_to_generic_drugs": "possible",
//             "number_of_split_dispensing": 1,
//             "prescription_image": "http://dev-pharmacy.eoraa.com/media/patient/prescriptions/images/Screenshot_1.png",
//             "created_at": "2022-06-26T12:32:30.005762Z",
//             "updated_at": "2022-06-26T12:32:30.022888Z",
//             "patient": 3970
//         }
//     ],
//     "pharmacy": {
//         "id": 12,
//         "prefectures": {
//             "id": 1,
//             "prefecture_name_en": "Hokkaido",
//             "prefecture_name_jp": "北海道"
//         },
//         "user": {
//             "id": 21,
//             "email": "testing123@gmail.com"
//         },
//         "representative": {
//             "id": 8,
//             "email": "test6@anandan.com"
//         },
//         "drugstore_name": "edfrgthjk",
//         "handling_classification": "{test}",
//         "address": "rfedsw,Test Address,11,1111",
//         "postal_code": null,
//         "municipalities": null,
//         "fax": "",
//         "email": "jstestacc527@gmail.com",
//         "is_linked": false,
//         "business_hours": "test",
//         "established": "2022-05-04T10:21:46Z",
//         "time1_start": "10:00:00",
//         "time1_end": "18:00:00",
//         "time2_start": "09:00:00",
//         "time2_end": "22:00:00",
//         "telephone_number": "01234567890",
//         "no_of_employee": 50,
//         "line_id": null,
//         "created_at": "2022-05-21T08:19:28.994878Z",
//         "updated_at": "2022-06-08T08:25:45.796471Z"
//     },
//     "application_message": "",
//     "is_visted": true,
//     "is_accepted": true,
//     "is_remote_accepted": true,
//     "medication_guidence": true,
//     "remote_medication": null,
//     "message": "",
//     "reservation_scheduled_time": "2022-06-08T07:17:08Z",
//     "guidance_scheduled_time": "2022-06-08T07:17:10Z",
//     "created_at": "2022-06-07T05:55:45.041497Z",
//     "updated_at": "2022-06-16T04:45:31.839249Z",
//     "scheduled_by": 5,
//     "receive_prescription_by": 1
// }
