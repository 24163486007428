import { LinkButton, SignUpLink } from '../components/projects'
import { SearchStore } from '../components/ui'
import { useEffect } from 'react'
import noticeAPI from '../../apis/notice'
import { useState } from 'react'
import medicineTips from '../../apis/medicinetips'
import MedicinePageList from '../components/projects/MedicinePageList'
import Loader from "../components/ui/loader";
import { getAuthToken } from '../../helpers/storage'
import { Pagination } from 'antd'

// お知らせダミーデータ
const newsItems = [
  {
    date: '2021/12/21',
    time: '13:22',
    text: 'お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。',
    id: 1,
  },
  {
    date: '2021/12/21',
    time: '13:22',
    text: 'お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。',
    id: 2,
  },
  {
    date: '2021/12/21',
    time: '13:22',
    text: 'お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。',
    id: 3,
  },
  {
    date: '2021/12/21',
    time: '13:22',
    text: 'お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。',
    id: 4,
  },
  {
    date: '2021/12/21',
    time: '13:22',
    text: 'お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。',
    id: 5,
  },
]

export default function MedicineNewsList() {
  const token = getAuthToken();
  const [isLoading, setIsLoading] = useState(true)
  const [medicinePage, setMedicinePage] = useState({ pages: 1 });
  const [medicine, setMedicine] = useState([]);
  const [medicineCount, setMedicineCount] = useState(null);

  const onMedicine = (page) => {
    setMedicinePage({ pages: page });
  };

  const data = async () => {
    try {
      const response = await medicineTips.getData(medicinePage.pages);
      setMedicine(response.data.results)
      setMedicineCount(response.data.count);
    } catch (e) {
      console.log('error', e)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    data();
  }, [medicinePage]);

  if (isLoading) return <Loader />;

  return (
    <main className='p-newsList'>
      {!token && <SignUpLink />}

      <section>
        <h2 className='c-sectionTitle'>じぶん薬局からのお知らせ一覧</h2>
          {medicine.length ? medicine.map((item, i) => {
            return <dl><MedicinePageList key={i} to={`/mymedicine/${item.id}`} item={item} /></dl>;
          }) : <div style={{padding: "15px 30px", color: "#FAA04D"}}>データがありません</div>}
      </section>
      <center>
        <Pagination
          total={medicineCount != null ? medicineCount : ""}
          onChange={onMedicine}
          pageSize={10}
          showSizeChanger={false}
          size="medium"
        />
      </center>
      <LinkButton
        text='じぶん薬局へのお問い合わせ'
        url='/contact'
        color='blue'
      />

      <SearchStore />
    </main>
  )
}
