import { memo, useState } from "react";

const FormDateTimeInput = memo((props) => {
  const timeItems = [];
  for (let i = 36; i < 84; i++) {
    const math = (number) => {
      return ("0" + number).slice(-2);
    };
    let hour = math(Math.floor(i / 4));
    let minute = "00";
    switch (i - Math.floor(i / 4) * 4) {
      case 0:
        minute = "00";
        break;
      case 1:
        minute = "15";
        break;
      case 2:
        minute = "30";
        break;
      case 3:
        minute = "45";
        break;
      default:
    }
    timeItems.push(`${hour}:${minute}`);
  }

  const { label, required, errorMessage, initialValue, dateName, timeName } =
    props;
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="l-formInput__date">
      <label>
        {label}
        {required && <span className="l-formInput__required">必須</span>}
      </label>
      <input
        type="date"
        name={dateName}
        min={props.minDate || new Date().toISOString().split("T")[0]}
        className={errorMessage ? "c-inputBorder error" : "c-inputBorder"}
        value={value}
        onChange={handleChange}
      />
      <div className="c-selectBox black">
        <div className="c-selectBox__container">
          <select name={timeName} className={errorMessage ? "error" : ""}>
            <option hidden value="">
              時間
            </option>
            {timeItems.map((time, i) => {
              return (
                <option value={time} key={i}>
                  {time}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      {errorMessage && (
        <span className="l-formInput__errorMessage">{errorMessage}</span>
      )}
    </div>
  );
});

export default FormDateTimeInput;
