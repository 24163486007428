// 新規登録ページ
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { isEmail, isPassword } from "../../utils/validation";
import { HowTo } from "../components/ui";
import { FormInput, MainButton } from "../components/projects";
import signUpApi from "../../apis/signup";

import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

export default function SignUp() {
  // 入力バリデーション
  const [errorMessages, setErrorMessages] = useState({});
  const [lineLoginEmail, setlineLoginEmail] = useState(false);
  const [lineerrorMessages, setlineerrorMessages] = useState("");
  const [signUpError, setSignUpError] = useState("");

  const [linecounter, setlinecounter] = useState(0);

  const lineLogin = () => {
    setlineLoginEmail(true);
    return null;
  };

  const isValidate = (values) => {
    let messages = {};

    messages.email = isEmail(values.email);
    messages.password = isPassword(values.password, "パスワード");

    setErrorMessages({ ...messages });
    return Object.values(messages).every((message) => message === "");
  };

  const handleLineform = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const values = Object.fromEntries(data.entries());
    if (!isEmail(values.email)) {
      window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1660823504&redirect_uri=${window.location.origin}&state=${values.email}&scope=openid%20profile`;
    } else {
      if (lineLoginEmail && linecounter > 1) {
        setlineerrorMessages(isEmail(values.email));
      }
    }
  };

  // 新規登録
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    console.log("HOST",window.location.hostname);
    const host = window.location.hostname;
    e.preventDefault();
    const data = new FormData(e.target);
    const values = Object.fromEntries(data.entries());

    if (isValidate(values)) {
      try {
        setSignUpError("");
        await signUpApi.signUp(values,host);
        toast.success('認証メールが送信されました。', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        // window.location.reload();
      } catch (e) {
        setSignUpError(e.response.data.email);
        toast.error(e.response.data.email, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
    }
  };

  return (
    <main className="p-signUp">
      <ToastContainer />
      <HowTo />

      <article className="c-formMessage">
        <p className="c-formMessage__bold">
          じぶんクリニックをご利用に当たっては、
          <br />
          まず会員登録をお願い致します。
          <br />
          ご登録IDとパスワードをご入力いただき、
          <br />「<span>新規会員登録</span>」をタップしてください。
        </p>
        <p>
          その後、認証メールが届きますので、認証メール内の
          <br />
          URLをタップして会員登録を完了してください。
        </p>
      </article>

      <article className="p-signUp__form">
        <form onSubmit={handleSubmit}>
          <FormInput
            name="email"
            label="ご登録ID（メールアドレス）"
            placeholder="ご登録ID（メールアドレス）を入力してください"
            required="true"
            type="email"
            errorMessage={errorMessages.email ? errorMessages.email : ""}
            autocomplete="email"
          />
          <FormInput
            name="password"
            label="ご登録パスワード"
            placeholder="ご登録パスワードを入力してください"
            required="true"
            type="password"
            errorMessage={errorMessages.password ? errorMessages.password : ""}
            autocomplete="off"
          />
          <p
            style={{
              fontFamily: "NotoSansJP_bold",
              color: "#faa04d",
              fontSize: "10px",
            }}
          >
            {signUpError}
          </p>
          <MainButton text="新規会員登録" color="blue" />
        </form>

        <form className="l-formInput" onSubmit={handleLineform}>
          {lineLoginEmail && (
            <div className="l-formInput">
              <FormInput
                name="email"
                label="Line Email id"
                placeholder="ログインID（メールアドレス）を入力してください"
                type="email"
                autocomplete="email"
                errorMessage={lineerrorMessages ? lineerrorMessages : ""}
                required="true"
              />
            </div>
          )}
          <MainButton
            text="LINEアカウントを連携してログインする"
            color="green"
            onClick={() => {
              setlinecounter(linecounter + 1);
              lineLogin();
            }}
          />
        </form>
      </article>
    </main>
  );
}

function randomString(length, chars) {
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}
