// インプットフォーム
import { memo } from "react";

const FormInput = memo((props) => {
  const {
    name,
    placeholder,
    label,
    required,
    type,
    errorMessage,
    autocomplete,
    data,
    disabled,
  } = props;

  return (
    <div className="l-formInput__form">
      <label>
        {label}
        {required && <span className="l-formInput__required">必須</span>}
      </label>
      <input
        name={name}
        placeholder={placeholder}
        type={type}
        className={errorMessage ? "c-input error" : "c-input"}
        autoComplete={autocomplete}
        defaultValue={data}
        disabled={disabled}
      />
      {errorMessage && (
        <span className="l-formInput__errorMessage">{errorMessage}</span>
      )}
    </div>
  );
});

export default FormInput;
