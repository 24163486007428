import { memo, useState ,useEffect} from "react";
import { isEntered } from "../../../utils/validation";
import { FormBorderInput, MainButton } from ".";
import userMemo from "../../../apis/usermemo";
import {getFromLocalStorage } from "../../../helpers/storage";
import { getFavorite } from "../../../apis/favorities";
import { useNavigate } from "react-router-dom";


const FormMemoInput = memo(({ onSubmit, item }) => {
  // 入力バリデーション
  const [errorMessages, setErrorMessages] = useState({});

  const [favorites, setFavorites] = useState([]);
  const navigate = useNavigate();

  const token = "token" + " " + getFromLocalStorage("token");

  const headers = { Authorization: token };
  const userId=window.localStorage.getItem("id") ? window.localStorage.getItem("id") : null;

  const getData = async () => {
    const response = await getFavorite(userId);
    setFavorites(response);
    console.log("response", response);
  };

  useEffect(() => {
    getData();
  }, []);

  const isValidate = (values) => {
    let messages = {};
    messages.store = isEntered(
      "かかりつけ薬局・薬店",
      values.pharmacy_name,
      true
    );
    messages.personName = isEntered("担当薬剤師", values.pharmacist, false);
    messages.title = isEntered("服薬指導メモタイトル", values.title, false);
    setErrorMessages({ ...messages });
    return Object.values(messages).every((message) => message === "");
  };

  // 新規メモ登録
  // const navigate = useNavigate()
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const values = Object.fromEntries(data.entries());

    if (isValidate(values)) {
      // 新規登録処理
      await userMemo.userMemoAPi(values, headers);
      navigate('/mypage/memo');
    }
  };

  return (
    <div className="p-memo__new__container">
      <form onSubmit={handleSubmit}>
        <div className="l-formInput__form border">
          <label>  かかりつけ薬局・薬店 </label>
          <div className="c-selectBox__container black" style={{marginTop: '10px'}} >
            <select
              name="pharmacy_name"
            // onChange={(e) => handleChangeSelect(e)}
            >
              <option value="">都道府県</option>
              {favorites.map((item, i) => {
                return (
                  <option value={item.drugstore} key={i}>
                    {item.drugstore_name}
                  </option>
                );
              })}
            </select>
          </div>
          {errorMessages.store && (
            <span className="l-formInput__errorMessage">{errorMessages.store}</span>
          )}
        </div>
        <FormBorderInput
          name="pharmacist"
          label="担当薬剤師"
          placeholder="担当薬剤師名を入力してください"
          required="true"
          type="text"
          errorMessage={
            errorMessages.personName ? errorMessages.personName : ""
          }
        />
        <FormBorderInput
          name="title"
          label="タイトル"
          placeholder="服薬指導メモタイトルを入力してください"
          required="true"
          type="text"
          errorMessage={errorMessages.title ? errorMessages.title : ""}
        />
        <div className="l-formInput__form border">
          <label>
            服薬指導メモ詳細
            <span className="l-formInput__any">任意</span>
          </label>
          <textarea
            name="description"
            placeholder="服薬指導メモの詳細を入力してください"
            className="c-textarea__border"
          />
        </div>
        <MainButton text="この内容で服薬指導メモを登録する" color="blue min" />
      </form>
    </div>
  );
});

export default FormMemoInput;
