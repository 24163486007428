import axios from "axios";

const storeSearch = async (values, prefecture) => {
  // console.log(pref)
  // console.log(mnclp)
  // console.log(drgstr);
  console.log(values);

  var res;

  res = await axios.get(
    `https://dev-api-pharmacy.automagicjapan.tech/api/drugstore/?prefectures=${prefecture}&municipalities=${values.city}&drugstore_name=${values.drugstore_name}`
  );

  return res;
};

const storeDetail = (id) =>
  axios.get(`https://dev-api-pharmacy.automagicjapan.tech/api/drugstore/${id}/`);

const getStore = () =>
  axios.get("https://dev-api-pharmacy.automagicjapan.tech/api/drugstore/");

const drugStoreAPi = {
  storeSearch,
  storeDetail,
  getStore,
};

export default drugStoreAPi;
