import axios from "axios";

const createPost = (headers, data) =>
  axios.post("https://dev-api-pharmacy.automagicjapan.tech/api/for_reservation/", data, {
    headers,
  });

const reserveGet = async (headers, page) => {
  var response;
  response = await axios.get(
    `https://dev-api-pharmacy.automagicjapan.tech/api/prescription_api/?page=${page}`,
    {
      headers,
    }
  );
  return response;
};

const dropdownGet = async (headers, page) => {
  var response;
  response = await axios.get(
    `https://dev-api-pharmacy.automagicjapan.tech/api/drugstore/?page=${page}`,
    {
      headers,
    }
  );
  return response;
};

const receivePrescriptionGet = async (headers) => {
  var response;
  response = await axios.get(
    "https://dev-api-pharmacy.automagicjapan.tech/api/recieve_choice/",
    {
      headers,
    }
  );
  return response;
};

const reserveAPI = {
  createPost,
  reserveGet,
  dropdownGet,
  receivePrescriptionGet,
};

export default reserveAPI;
