import React, { useState, createContext } from "react";

export const Contextdata = createContext();

export const UserContext = (props) => {
  var [userData1, saveUserData1] = useState([]);
  var [userData2, saveUserData2] = useState([{ name: "sachi" }]);
  const [receiptData, setReceiptData] = useState([]);
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [drugStoreData, setDrugStoreData] = useState([]);
  const [storeName, setStoreName] = useState("");
  const [otc, setOtc] = useState();
  const [otcPageNo, setPageNo] = useState(1);
  const [otcPagination, setOtcPagination] = useState();
  const [selectedCalendarDate, setSelectedCalendarDate] = useState();

  return (
    <>
      <Contextdata.Provider
        value={{
          receiptData,
          setReceiptData,
          userData1,
          saveUserData1,
          userData2,
          saveUserData2,
          prescriptionData,
          setPrescriptionData,
          drugStoreData,
          setDrugStoreData,
          otc,
          setOtc,
          storeName,
          setStoreName,
          otcPageNo,
          setPageNo,
          otcPagination,
          setOtcPagination,
          selectedCalendarDate,
          setSelectedCalendarDate,
        }}
      >
        {props.children}
      </Contextdata.Provider>
    </>
  );
};
