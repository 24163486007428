import axios from "axios";
import { API_ENDPOINT } from '../utils/constants/apiConstants';

export const signUp = async (data,host) => {
  data["host"]= host;
  const url = `${API_ENDPOINT}patient/signup/`;
  const response = await axios.post(url, data);
  return response.data;
}


const signUpApi = {
  signUp,
};

export default signUpApi;
