import React from "react";
import { Rings } from "react-loader-spinner";
const loader = () => {
  return (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
  <Rings
  height="80"
  width="80"
  color="#44b49c"
  radius="6"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  ariaLabel="rings-loading"
/>
    </div>
  );
};

export default loader;
