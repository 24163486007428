import {
  useState,
  memo,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

import { FormBorderInput, FormSelect, MainButton } from "./";
import { isChecked } from "../../../utils/validation";
import { pharmaotcinforesponse } from "../../../apis/PharmaOtcInfo";
import { Contextdata } from "../../../UserContext";
import otc from "../../../apis/otc";
import { getAuthToken } from "../../../helpers/storage";
import otcAPI from "../../../apis/otc";

const pharmaOtcResArr = [];

const MedicineInputForm = memo(() => {
  const { setOtc, setOtcPagination, otcPageNo } = useContext(Contextdata);
  const [wentWrong, setWentWrong] = useState(false);
  const [isOcrLoading, setIsOcrLoading] = useState(false);
  const [values, setValues] = useState({
    effectOption: "AND",
    warningOption: "AND",
    prohibitionOption: "AND",
    jointlyOption: "AND",
  });

  const ocrInputRef = useRef(null);


  // const { userData1, saveUserData1 } = useContext(Contextdata);
  // チェックボックス
  const categoryItems = [
    { label: "OTC医薬品", value: 1 },
    { label: "医療用医薬品", value: 2 },
  ];


  // ラジオボックス
  const radioOptions = [
    {
      label: "AND",
      value: "AND",
      initial: true,
    },
    {
      label: "OR",
      value: "OR",
      initial: false,
    },
    {
      label: "NOT",
      value: "NOT",
      initial: false,
    },
  ];

  // 処方箋チェックボックス
  // const [categoryType, setCategoryType] = useState(null);
  // const handleCatChange = useCallback(e => {
  //   setCategoryType(+e.target.value)
  // }, [])

  // ラジオボックス
  // const [value, setValue] = useState({
  //   effectOption: "AND",
  //   warningOption: "AND",
  //   prohibitionOption: "AND",
  //   jointlyOption: "AND",
  // });
  const handleFormValuesChange = (e) => {
    // console.log(e.target.name, e.target.value);
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  // バリデーション
  const [errorMessages, setErrorMessages] = useState({});
  const isValidate = (values) => {
    let messages = {};

    messages.type = isChecked(values, "type")
      ? ""
      : "検索分類は必須選択項目です。";

    setErrorMessages({ ...messages });
    return Object.values(messages).every((message) => message === "");
  };

  // 検索処理
  const navigate = useNavigate();

  const handleRadioChange = async () => {
    console.log('handleRadioChange');
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const data = new FormData(e.target);
    // const values = Object.fromEntries(data.entries());

    // チェックボックス の値取得
    // if (values.type) {
    //   delete values.type;

    //   const checkedValue = Object.entries(checkedItems).reduce(
    //     (pre, [key, value]) => {
    //       value && pre.push(key);
    //       return pre;
    //     },
    //     []
    //   );

    // values.type = categoryType;
    // }

    if (isValidate(values)) {
      // var response;
      // values.type.map(([item]) => {
      //   item === "OTC医薬品" ? item.pop && item.push(1) : item.push(2);
      // });
      // response = pharmaotcinforesponse.pharmaotcinfoapi(values);
      // response.then((res) => {
      //   var otcSearchInfoArr = [];
      //   for (var i = 0; i < res.data.results.length; i++) {
      //     const otcSearchInfoObj = {
      //       searchType: "検索分類",
      //       medicineType: "医薬品分類",
      //       effectType: "薬効分類",
      //       inquiry_company_name: "",
      //       generic_name: "",
      //       ...res.data.results[i],
      //     };
      //     otcSearchInfoArr.push(otcSearchInfoObj);
      //     saveUserData1(otcSearchInfoArr);
      //   }
      // });
      // setTimeout(() => {
      //   navigate("/mypage/medicine/list");
      // }, 3000);
    }
    console.log(values);
    localStorage.setItem("drugstore", values.medicineName);

    const params = {
      search_classification: values.type,
      end_updated_date: values.updateEndDate,
      contact_category: values.category,
      start_update_date: values.updateStartDate,
      effects: values.effect,
      warning: values.warning,
      contraindications: values.prohibition,
      concomitant: values.jointly,
      generic_name: values.medicineName,
      drug_efficasy_classification: values.category,
      inquiry_company_name: values.companyName,
    };

    const token = "token " + getAuthToken();
    const headers = { Authorization: token };

    try {
      const response1 = await otcAPI.search({ headers, params });
      console.log(response1);
      setOtc(response1.data);
      navigate("/mypage/medicine/list");
    } catch (e) {
      setWentWrong(true);
    }
  };

  const onOcrUpload = useCallback(async (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const fd = new FormData();
      fd.append("file", file);
      ocrInputRef.current.value = "";
      try {
        setIsOcrLoading(true);
        const res = await otcAPI.searchOCR(fd);
        const drugs = res.data;
        if (drugs && Array.isArray(drugs) && drugs.length) {
          setOtc(drugs);
          navigate("/mypage/medicine/list?scroll=true");
          // const drug = drugs[0];
          // if (drug) setValues((v) => ({ ...v, medicineName: drug.drug_name }))
        } else alert("結果が見つかりません");
      } catch (err) {
        console.log(err);
      } finally {
        setIsOcrLoading(false);
      }
    }
  }, []);

  return (
    <div>
      <div className="c-linkButton allBlue">
        {isOcrLoading && (
          <div className="c-linkButton__loading">
            <CircularProgress size={20} style={{ color: "var(--c-blue)" }} />
          </div>
        )}
        <button
          disabled={isOcrLoading}
          onClick={() => ocrInputRef.current.click()}
        >
          <span>医薬品を撮影して検索</span>
        </button>
        <input
          hidden
          ref={ocrInputRef}
          type="file"
          accept="image/*"
          onChange={onOcrUpload}
        />
      </div>
      <div className="p-searchMedicine__input__container">
        <form onSubmit={handleSubmit} onChange={handleFormValuesChange}>
          <div className="p-searchMedicine__input__checkbox l-formInput__checkbox">
            <p className="l-formInput__checkbox__title">
              検索分類
              <span className="l-formInput__required">必須</span>
            </p>
            <div>
              {categoryItems.map((cat, i) => {
                return (
                  <label key={i}>
                    <input
                      type="radio"
                      name="type"
                      value={cat.value}
                      onChange={handleRadioChange}
                      checked={Number(values.type) === Number(cat.value)}
                      className={errorMessages.type ? "error" : ""}
                    />
                    <div className="l-formInput__checkbox__text">
                      {cat.label}
                    </div>
                  </label>
                );
              })}
            </div>
            {errorMessages.type && (
              <span className="l-formInput__errorMessage">
                {errorMessages.type}
              </span>
            )}
          </div>
          <FormBorderInput
            isControlled
            value={values.medicineName}
            name="medicineName"
            label="一般名・販売名（医薬品の名称）"
            placeholder="医薬品名を入力してください"
            required={false}
            type="text"
            errorMessage={
              errorMessages.medicineName ? errorMessages.medicineName : ""
            }
          />


          <FormBorderInput
            isControlled
            value={values.category}
            name="category"
            label="薬効分類(医薬品の種類)"
            placeholder="薬効分類（医薬品の種類）を選択してください"
            required={false}
            type="text"
            errorMessage={
              errorMessages.medicineName ? errorMessages.medicineName : ""
            }
          />

          <FormBorderInput
            isControlled
            value={values.effect}
            name="effect"
            label="効能・効果（医薬品の効果が承認された疾病等）"
            placeholder="効能・効果を入力してください"
            required={false}
            type="text"
          />
          <div className="l-formInput__radio">
            {radioOptions.map((option, i) => {
              return (
                <label key={i}>
                  <input
                    type="radio"
                    name="effectOption"
                    value={option.value}
                    onChange={handleRadioChange}
                    checked={
                      values.effectOption === option.value ? "checked" : ""
                    }
                  />
                  <span className="l-formInput__radio__text">
                    {option.label}
                  </span>
                </label>
              );
            })}
          </div>
          <FormBorderInput
            isControlled
            value={values.warning}
            name="warning"
            label="警告（使用にあたって特に注意すべき事項等）"
            placeholder="警告を入力してください"
            required={false}
            type="text"
          />
          <div className="l-formInput__radio">
            {radioOptions.map((option, i) => {
              return (
                <label key={i}>
                  <input
                    type="radio"
                    name="warningOption"
                    value={option.value}
                    onChange={handleRadioChange}
                    checked={
                      values.warningOption === option.value ? "checked" : ""
                    }
                  />
                  <span className="l-formInput__radio__text">
                    {option.label}
                  </span>
                </label>
              );
            })}
          </div>
          <FormBorderInput
            isControlled
            value={values.prohibition}
            name="prohibition"
            label="禁忌（使ってはいけない状態等）"
            placeholder="禁忌を入力してください"
            required={false}
            type="text"
          />
          <div className="l-formInput__radio">
            {radioOptions.map((option, i) => {
              return (
                <label key={i}>
                  <input
                    type="radio"
                    name="prohibitionOption"
                    value={option.value}
                    onChange={handleRadioChange}
                    checked={
                      values.prohibitionOption === option.value ? "checked" : ""
                    }
                  />
                  <span className="l-formInput__radio__text">
                    {option.label}
                  </span>
                </label>
              );
            })}
          </div>
          <FormBorderInput
            isControlled
            value={values.jointly}
            name="jointly"
            label="併用禁忌・併用注意（同時に使ってはいけないくすり等）"
            placeholder="併用禁忌・併用注意を入力してください"
            required={false}
            type="text"
          />
          <div className="l-formInput__radio">
            {radioOptions.map((option, i) => {
              return (
                <label key={i}>
                  <input
                    type="radio"
                    name="jointlyOption"
                    value={option.value}
                    onChange={handleRadioChange}
                    checked={
                      values.jointlyOption === option.value ? "checked" : ""
                    }
                  />
                  <span className="l-formInput__radio__text">
                    {option.label}
                  </span>
                </label>
              );
            })}
          </div>
          <div className="l-formInput__date">
            <label>
              更新年月日
              <span className="l-formInput__any">任意</span>
            </label>
            <div>
              <input
                type="date"
                name="updateStartDate"
                className="c-inputBorder"
              />
              <input
                type="date"
                name="updateEndDate"
                className="c-inputBorder"
              />
            </div>
          </div>
          <FormBorderInput
            isControlled
            value={values.companyName}
            name="companyName"
            label="問い合わせ企業名"
            placeholder="問い合わせ企業名"
            required={false}
            type="text"
          />
          <MainButton text="この内容で検索する" color="blue min" />
        </form>
      </div>
    </div>
  );
});

export default MedicineInputForm;

//new comment
