import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  LinkButton,
  SignUpLink,
  TipsCategoryList,
  TipsPager,
} from "../components/projects";
import { SearchStore } from "../components/ui";
import { URL } from "../../utils/data";
import { getAuthToken } from "../../helpers/storage";

export default function TipsCategory() {
  const { id } = useParams();
  const [name, setName] = useState("");
  const token = getAuthToken();
  useEffect(() => {
    const endpoint = `categories/${id}?_embed`;
    const dataURL = URL + "/wp-json/wp/v2/" + endpoint;

    fetch(dataURL, { method: "GET" })
      .then((res) => res.json())
      .then((data) => {
        setName(data.name);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [id]);

  return (
    <main className="p-tipsList">
      <section>
        <h2 className="c-sectionTitle">おくすりTipsカテゴリ一覧</h2>
        <p className="p-tipsList__categoryTitle">{name}</p>
        <TipsPager id={id} type="category" />
      </section>

      <TipsCategoryList />

      {!token && <SignUpLink />}
      <LinkButton
        text="じぶん薬局へのお問い合わせ"
        url="/contact"
        color="blue"
      />

      <SearchStore />
    </main>
  );
}
