import { MypageTopMenu } from "../components/ui";
import { MedicineInputForm } from "../components/projects";
import { pharmaotcinforesponse } from "../../apis/PharmaOtcInfo";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "../../helpers/storage";

export default function SearchMedicineInput() {
  const navigate = useNavigate();
  useEffect(() => {
    if (getAuthToken() === null) {
      navigate("/");
    }
  }, []);

  return (
    <main className="p-searchMedicine">
      <MypageTopMenu />

      <section className="p-searchMedicine__input">
        <h2 className="c-sectionTitle c-mypageHeading">
          OTC医薬品・医療用医薬品検索
        </h2>
        {/* <div className="c-linkButton allBlue">
          <button>
            <span>医薬品を撮影して検索</span>
          </button>
        </div> */}

        <MedicineInputForm />
      </section>
    </main>
  );
}
