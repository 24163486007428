// ナビゲーション部分
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./../../../assets/topMenu.css";
import { getAuthToken } from "../../../helpers/storage";

// 未ログイン時のナビゲーションリスト
const signOutLists = [
  {
    text: "マイページログイン",
    url: "/",
  },
  {
    text: "新規利用登録",
    url: "/signUp",
  },
  {
    text: "お知らせ",
    url: "/news",
  },
  {
    text: "おくすりTips",
    url: "/tips",
  },
  {
    text: "お問い合わせ",
    url: "/contactout",
  },
];

// ログイン時のナビゲーションリスト
const signInLists = [
  {
    text: "マイページトップ",
    url: "/mypage",
  },
  {
    text: "おくすり手帳",
    url: "/mypage/prescription",
  },
  {
    text: "遠隔服薬指導",
    url: "/mypage/memo",
  },
  {
    text: "OTC（薬局で購入できる医薬品）/医療用医薬品検索",
    url: "/mypage/medicine",
  },
  {
    text: "ユーザー情報の更新・編集",
    url: "/mypage/user",
  },
  {
    text: "お知らせ",
    url: "/mypage/news",
  },
  {
    text: "おくすりTips",
    url: "/tips",
  },
  {
    text: "お問い合わせ",
    url: "/contact",
  },
  {
    text: "ログアウト",
    url: "/",
    click: () => localStorage.removeItem("token"),
  },
];

export default function HeaderNav() {
  const location = useLocation();

  // メニューの開閉
  const [openMenu, setOpenMenu] = useState(false);
  const handleClickBtn = () => {
    setOpenMenu((prevState) => !prevState);
  };

  return (
    <div className="l-headerNav">
      <div>
        <button className="l-headerNav__openBtn" onClick={handleClickBtn}>
          <span>MENU</span>
        </button>
      </div>

      <nav className={openMenu ? "l-headerNav__open" : ""}>
        <p>MENU</p>
        <button
          className="l-headerNav__closeBtn"
          onClick={handleClickBtn}
        ></button>
        <ul className="l-headerNav__list">
          {getAuthToken()
            ? signInLists.map((list, i) => {
                return (
                  <li key={i} onClick={handleClickBtn}>
                    <Link to={list.url} className="in" onClick={list?.click}>
                      <span>{list.text}</span>
                    </Link>
                  </li>
                );
              })
            : signOutLists.map((list, i) => {
                return (
                  <li key={i} onClick={handleClickBtn}>
                    <Link to={list.url} className="out">
                      <span>{list.text}</span>
                    </Link>
                  </li>
                );
              })}
        </ul>
      </nav>
    </div>
  );
}
