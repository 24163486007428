import axios from "axios";

const getCalendar = (headers) =>
  axios.get("https://dev-api-pharmacy.automagicjapan.tech/api/calendar/", {
    headers: headers,
  });

const calendar = {
  getCalendar,
};

export default calendar;
