import { Link, useNavigate } from "react-router-dom";

import { MypageTopMenu } from "../components/ui";
import { MedicineInputForm } from "../components/projects";

import { useEffect, useState, useContext, useMemo } from "react";
import { Contextdata } from "../../UserContext";
import { getAuthToken, getFromLocalStorage } from "../../helpers/storage";
import otcdetails from "../../apis/otcinfo";
import { Pagination } from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import Loader from "../components/ui/loader";


export default function Medicine() {
  const { otc, setOtc } = useContext(Contextdata);

  const [enable, setEnable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [drug, setDrug] = useState();

  const token = "token " + getAuthToken();
  const headers = { Authorization: token };

  const navigate = useNavigate();
  // const [searchParams] = useSearchParams()

  const getData = async () => {
    const response = await otcdetails.drugClassification(headers);

    setDrug(response.data.results);
    setIsLoading(false);
  };
  useEffect(() => {
    getAuthToken() === null ? navigate("/") : setEnable(true);
  }, []);
  useEffect(() => getData(), [enable]);

  console.log("userData", otc);


  console.log("otc", otc);

  const onChange = async (page) => {
    if (otc?.next != null) {
      var url = otc.next.replace("http", "https");
      url = url.replace("page=2", `page=${page}`);
      try {
        var response = await axios.get(url);
      } catch (e) {
        console.log(e);
      }

      console.log("response", response);
      console.log("medicinelist", medicineList);
      setOtc({ ...response.data });
    }
  };

  const medicineList = useMemo(() => otc && (otc.results ?? otc), [otc]);
  console.log(medicineList);

  if (isLoading) return <Loader />;
  return (
    <>
      {true && (
        <main>
          <MypageTopMenu />

          <section className="p-searchMedicine__list">
            <h2 className="c-sectionTitle c-mypageHeading">
              OTC医薬品・医療用医薬品検索
            </h2>
            <p className="p-searchMedicine__result">
              「<span>{getFromLocalStorage("drugname") || " "}</span>」<br />
              での検索結果一覧
            </p>
            <ul>
              {medicineList != undefined ? (
                <>
                  {!!medicineList.length ? (
                    <>
                      {medicineList.map((item, i) => {
                        return (
                          <li key={i}>
                            <Link to={`/mypage/medicine/${item.id}`}>
                              <p>
                                <span>
                                  {
                                    item.drug_classification
                                      ?.drug_classification_jp
                                  }
                                </span>
                                <span>{item.efficacy}</span>
                                <span>Search Classification</span>
                              </p>
                              <dl>
                                <dt>販売名 &#058;</dt>
                                <dd>&nbsp;{item.brand_name}</dd>
                              </dl>
                              <dl>
                                <dt>基準名 &#058;</dt>
                                <dd>&nbsp;{item.drug_name}</dd>
                              </dl>
                            </Link>
                          </li>
                        );
                      })}
                    </>
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        color: "red",
                        fontSize: "1rem",
                      }}
                    >
                      検索結果がありません。条件を変えてもう一度お試しください
                    </p>
                  )}
                </>
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    color: "red",
                    fontSize: "1rem",
                  }}
                >
                  検索結果がありません。条件を変えてもう一度お試しください
                </p>
              )}
            </ul>

            {/* <div className="c-linkButton allBlue">
              <button>
                <span>医薬品を撮影して検索</span>
              </button>
            </div> */}
          </section>
          <div>
            <Pagination
              defaultCurrent={1}
              total={otc && otc?.count && otc.count}
              onChange={onChange}
              //current={pageNumber}
              pageSize={10}
              showSizeChanger={false}
              size="small"
            />
          </div>
          <MedicineInputForm />
        </main>
      )}
    </>
  );
}

// companyname
// generic_name

// return (
//   <main>
//     <MypageTopMenu />

//     <section className="p-searchMedicine__list">
//       <h2 className="c-sectionTitle c-mypageHeading">OTC医薬品・医療用医薬品検索</h2>
//       <p className="p-searchMedicine__result">
//         「
//           {searchItems.map((item, i) => {
//             return (
//               <span key={i}>{item}</span>
//             )
//           })}
//         」<br />
//         での検索結果一覧
//       </p>
//       <ul>
//         {medicineItems.map((item, i) => {
//           return (
//             <li key={i}>
//               <Link to={`/mypage/medicine/${item.id}`}>
//                 <p>
//                   <span>{item.searchType}</span>
//                   <span>{item.medicineType}</span>
//                   <span>{item.effectType}</span>
//                 </p>
//                 <dl>
//                   <dt>販売名 &#058;</dt>
//                   <dd>&nbsp;{item.name1}</dd>
//                 </dl>
//                 <dl>
//                   <dt>一般名 &#058;</dt>
//                   <dd>&nbsp;{item.name2}</dd>
//                 </dl>
//               </Link>
//             </li>
//           )
//         })}
//       </ul>

//       <div className="c-linkButton allBlue">
//         <button>
//           <span>医薬品を撮影して検索</span>
//         </button>
//       </div>

//       <MedicineInputForm />
//     </section>
//   </main>
// )
