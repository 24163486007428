const getFromLocalStorage = (key) => {
  let storedValue = null;
  try {
    storedValue = localStorage.getItem(key);
  } catch (e) {
    console.log("error", e);
  }
  return storedValue;
};

const setID = (id) => {
  localStorage.setItem("id", JSON.stringify(id));
};

const getID = (id) => {
  let storedValue = null;
  try {
    storedValue = JSON.parse(localStorage.getItem(id));
  } catch (e) {
    console.log("error", e);
  }
  return storedValue;
};

const setLocalStorage = ({ token }) => {
  localStorage.setItem("token", token);
};

const setLocaldrop = ({ drop }) => {
  localStorage.setItem("dop", JSON.stringify(drop));
};

const getLocaldrop = (drop) => {
  let storedValue = null;
  try {
    storedValue = JSON.parse(localStorage.getItem(drop));
  } catch (e) {
    console.log("error", e);
  }
  return storedValue;
};

const getAuthToken = () => {
  const token = window.localStorage.getItem("token");
  return token;
};

export {
  getFromLocalStorage,
  setLocalStorage,
  getAuthToken,
  setID,
  getID,
  getLocaldrop,
  setLocaldrop,
};
