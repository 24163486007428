import { MypageTopMenu } from "../components/ui";
import { LinkButton, MemoList } from "../components/projects";
import userMemo from "../../apis/usermemo";
import { memo, useEffect, useState } from "react";
import { getAuthToken, getFromLocalStorage } from "../../helpers/storage";
import { Pagination } from "antd";
import { Navigate, useNavigate } from "react-router-dom";
import { data } from "jquery";
import Loader from "../components/ui/loader";
import { getFavorite } from "../../apis/favorities";

// ダミーデータ
const items = [
  {
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "担当薬剤師名",
    text: "指導メモタイトル。指導メモタイトル。指導メモタイトル。指導メモタイトル。指導メモタイトル。",
    id: 1,
  },
  {
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "担当薬剤師名",
    text: "指導メモタイトル。指導メモタイトル。指導メモタイトル。指導メモタイトル。指導メモタイトル。",
    id: 2,
  },
  {
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "担当薬剤師名",
    text: "指導メモタイトル。指導メモタイトル。指導メモタイトル。指導メモタイトル。指導メモタイトル。",
    id: 3,
  },
  {
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "担当薬剤師名",
    text: "指導メモタイトル。指導メモタイトル。指導メモタイトル。指導メモタイトル。指導メモタイトル。",
    id: 4,
  },
  {
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "担当薬剤師名",
    text: "指導メモタイトル。指導メモタイトル。指導メモタイトル。指導メモタイトル。指導メモタイトル。",
    id: 5,
  },
  {
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "担当薬剤師名",
    text: "指導メモタイトル。指導メモタイトル。指導メモタイトル。指導メモタイトル。指導メモタイトル。",
    id: 6,
  },
  {
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "担当薬剤師名",
    text: "指導メモタイトル。指導メモタイトル。指導メモタイトル。指導メモタイトル。指導メモタイトル。",
    id: 7,
  },
  {
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "担当薬剤師名",
    text: "指導メモタイトル。指導メモタイトル。指導メモタイトル。指導メモタイトル。指導メモタイトル。",
    id: 8,
  },
  {
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "担当薬剤師名",
    text: "指導メモタイトル。指導メモタイトル。指導メモタイトル。指導メモタイトル。指導メモタイトル。",
    id: 9,
  },
  {
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "担当薬剤師名",
    text: "指導メモタイトル。指導メモタイトル。指導メモタイトル。指導メモタイトル。指導メモタイトル。",
    id: 10,
  },
];

export default function Memo() {
  const token = "token" + " " + localStorage.getItem("token");
  const headers = { Authorization: token };

  console.log("token", token);

  const [isLoading, setIsLoading] = useState(true);
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);
  const [memoData, setMemoData] = useState([]);
  const [enable, setEnable] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const [pagination, setPagination] = useState();

  const userId=window.localStorage.getItem("id") ? window.localStorage.getItem("id") : null;
  const [favorites, setFavorites] = useState([]);

  const navigate = useNavigate();

  const getData = async () => {
    try {
      const response = await userMemo.getMemoApi(headers, pageNumber);
      setMemoData(response);
      setPagination({
        count: response.data.count,
        previous: response.data.previous,
        next: response.data.next,
      });
      console.log("response", response);
    } catch (e) {
      console.log(e);
      setSomethingWentWrong(true);
    }
    setIsLoading(false);
  };

  const getDataFavorites = async () => {
    const response = await getFavorite(userId);
    setFavorites(response);
  };

  const onChange = (page) => {
    setPageNumber(page);
    console.log("page", page);
  };

  useEffect(() => {
    getAuthToken() === null ? navigate("/") : setEnable(true);
  }, []);

  useEffect(() => {
    getData();
    getDataFavorites();
  }, [pageNumber]);

  if (isLoading) return <Loader />;

  if (somethingWentWrong) {
    return <div>SOMETHING WENT WRONG PLEASE TRY AGAIN</div>;
  }

  return (
    <main className="p-memo">
      <MypageTopMenu />

      <section className="p-memo__list">
        <h2 className="c-sectionTitle c-mypageHeading">服薬指導メモ</h2>
        <LinkButton
          text="服薬指導メモの新規登録"
          url="/mypage/memo/new"
          color="allBlue"
        />
        <ul>
          {memoData.data.results.map((item, i) => {
            return <MemoList key={i} item={item} favorites={favorites} />;
          })}
          <center>
            <Pagination
              // defaultCurrent={1}
              total={pagination.count}
              onChange={onChange}
              //current={pageNumber}
              pageSize={10}
              showSizeChanger={false}
              size="medium"
            />
          </center>
        </ul>
      </section>
    </main>
  );
}
