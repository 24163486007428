import { memo, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { getDateFromWPValue, getTimeFromWPValue } from "../../../utils/hooks";
import { URL } from "../../../utils/data";

const TipsList = memo(({ number }) => {
  const [data, setData] = useState({
    isLoaded: false,
    items: "",
    error: false,
  });

  // useEffect(() => {
  //   const url = URL;
  //   const endpoint = `posts?per_page=${number}&_embed`;
  //   const dataURL = url + "/wp-json/wp/v2/" + endpoint;

  //   fetch(dataURL, { method: "GET" })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setData({
  //         isLoaded: true,
  //         items: data,
  //         error: false,
  //       });
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //       setData({
  //         isLoaded: true,
  //         items: "",
  //         error: true,
  //       });
  //     });
  // }, [number]);

  return (
    <dl>
      {data.isLoaded &&
        !data.error &&
        data.items.map((item, i) => {
          return (
            <div key={i}>
              <Link to={`/tips/${item.id}`} className="p-articleList">
                <dt>
                  <span className="p-articleList__date">
                    {getDateFromWPValue(item.date)}
                  </span>
                  <span className="p-articleList__date">
                    {getTimeFromWPValue(item.date)}
                  </span>
                  <span className="p-articleList__category">
                    {item.category_name}
                  </span>
                </dt>
                <dd>{item.title.rendered}</dd>
              </Link>
            </div>
          );
        })}
      {data.isLoaded && data.error && (
        <div className="p-articleList__error">
          {/* <dt>データの取得に失敗しました。</dt>
          <dd>運営までお問い合わせください。</dd> */}
        </div>
      )}
    </dl>
  );
});

export default TipsList;
