/* eslint-disable no-mixed-operators */
import { Link, useNavigate } from "react-router-dom";
import { userDataObj } from "./UserInfoUpdate";
import { MypageTopMenu } from "../components/ui";
import { LinkText } from "../components/projects";
import {
  getDateFromValue,
  getAgeFromValue,
  getJpnDateFromValue,
} from "../../utils/hooks";
import { useContext, memo, useEffect, useState } from "react";

import { render } from "@testing-library/react";
import { getFromLocalStorage } from "../../helpers/storage";

import axios from "axios";
import { userEdit } from "../../apis/userEdit";
import { unstable_composeClasses } from "@mui/material";
import { getAuthToken, getID } from "../../helpers/storage";
import { Navigate } from "react-big-calendar";
import moment from "moment";
import { prefectures } from "../../utils/data";
import Loader from "../components/ui/loader";

// ダミーデータ

// var item = {
//   userName: "XXXX XXXX",
//   nickname: "ニックネーム",
//   birthday: "1970/01/10",
//   gender: "女",
//   postNumber: "123-4567",
//   address: "東京都 大田区 南久が原 1−2−3",
//   phoneNumber: "080-1234-5678",
//   email: "XXXXXXX@XXX.com",
//   allergy:
//     "アレルギー情報。アレルギー情報。アレルギー情報。アレルギー情報。アレルギー情報。アレルギー情報。",
//   drugAllergy:
//     "アレルギー情報。アレルギー情報。アレルギー情報。アレルギー情報。アレルギー情報。アレルギー情報。",
//   insuranceLimit: "2022/12/12",
//   insuranceDelivery: "2019/03/31",
//   insuranceCode: "12345678 番号 1",
//   insuranceGet: "2017/03/31",
//   householdName: "山田 太郎",
//   insuranceCompany: "株式会社オルトロス",
//   insuranceNumber: "12345678",
//   issurName: "大和市",
//   insurer: "全国健康保険協会 東京支部",
//   location: "東京都中野区中野4−10−2",
//   disease:
//     "基礎疾患内容の記載。基礎疾患内容の記載。基礎疾患内容の記載。基礎疾患内容の記載。基礎疾患内容の記載。",
//   hospital: "医療機関名。医療機関名。医療機関名。医療機関名。",
//   store: "調剤薬局名。調剤薬局名。調剤薬局名。調剤薬局名。",
//   pharmacistName: "薬剤師氏名。薬剤師氏名。薬剤師氏名。薬剤師氏名。",
// };

export default function UserInfo() {
  var renderData = {};
  var id = window.localStorage.getItem("id");
  const token = "token" + " " + getFromLocalStorage("token");
  const headers = { Authorization: token };

  console.log(headers);

  const [isLoading, setIsLoading] = useState(true);
  const [ogData, setOgData] = useState();
  const [enable, setEnable] = useState(false);
  const [insuranceData, setInsuranceData] = useState([]);
  // const [res1, setResponse1] = useState({
  //   name: "",
  //   nickname: "",
  //   phone_no: "",
  //   dob: "1994-11-02",
  //   allergy_info: "",
  //   allergy_reactive: "",
  //   email: "",
  //   gender: "",
  //   id: "",
  // });
  // const [res2, setResponse2] = useState({
  //   card_image: "",
  //   date_of_issuance: "1997-01-01",
  //   date_of_qualification: "",
  //   expiration_date: "1999-01-01",
  //   family_dispensing_pharmacy: "",
  //   family_medical_institution: "",
  //   id: "",
  //   insurance_number: "",
  //   insurer_location: "",
  //   insurer_name: "",
  //   name_of_head_of_household: "",
  //   office_name: "",
  //   patient: "",
  //   pharmacist_in_charge: "",
  //   recipient_name: "",
  //   symbol: "",
  //   underlying_disease: "",
  // });
  // const [res3, setResponse3] = useState({
  //   address: "",
  //   city: "",
  //   id: "",
  //   patient: "",
  //   prefecture: "",
  //   telephone: "",
  // });

  const getData = async () => {
    // var response1;

    // var response;
    // response = userEdit.fetchpatientApi(headers, id);
    // response.then((res) => {
    //   setResponse1(res.data);
    // });

    // response = userEdit.fetchpatientInsApi(headers, id);
    // response.then((res) => {
    //   const userRes2Obj = {
    //     ...res.data,
    //     expiration_date:
    //       res.data.expiration_date != null ? res.data.expiration_date : "",
    //   };
    //   setResponse2(userRes2Obj);
    // });

    // response = userEdit.fetchpatientAddressApi(headers, id);
    // response.then((res) => {
    //   setResponse3(res.data);
    // });

    const response1 = await userEdit.userDetails(headers, id);
    console.log("response", response1);
    setOgData(response1.data);
    setInsuranceData([response1.data.patient_insurance[0]]);

    setIsLoading(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    getAuthToken() === null ? navigate("/") : setEnable(true);
  });

  useEffect(() => {
    getData();
  }, [enable]);

  if (isLoading) return <Loader />;

  const renderUserInfoAddress = (item) => {
    const codePrefecture = item?.address?.prefecture;
    const resultPrefecture = prefectures.find((d) => d.code === codePrefecture);
    return (
      <div>
        {item?.address?.postal_code && (
          <div>〒{item?.address?.postal_code}</div>
        )}
        <div>
          {(resultPrefecture && resultPrefecture.name) || ""}{" "}
          {item?.address?.city || ""} {item?.address?.address || ""}
        </div>
      </div>
    );
  };
  return (
    <>
      <main>
        <MypageTopMenu />

        <section>
          <h2 className="c-sectionTitle c-mypageHeading">
            {ogData?.name}様のユーザー情報
          </h2>

          <dl>
            <div className="c-itemsList">
              <dt>お名前（ニックネーム）</dt>
              <dd className="c-itemsList__text">
                {<>{ogData?.name}</>} ({ogData?.nickname})
              </dd>
            </div>
            <div className="c-itemsList">
              <dt>生年月日</dt>
              <dd className="c-itemsList__text">
                {/* {getDateFromValue(res1.dob)}（{getAgeFromValue(res1.dob)}歳） */}
                {ogData?.dob == null
                  ? " "
                  : moment(ogData?.dob).format("YYYY年MM月DD日")}{" "}
                {/* ({ogData && getAgeFromValue(ogData?.dob)}歳） */}
                {ogData.dob && "(" + getAgeFromValue(ogData?.dob) + "歳" + ")"}
              </dd>
            </div>
            <div className="c-itemsList">
              <dt>性別</dt>
              <dd className="c-itemsList__text">
                {ogData?.gender == "male" ? "男" : (ogData?.gender == "female") ? "女性" : ""}
              </dd>
            </div>
            <div className="c-itemsList">
              <dt>住所</dt>

              <dd className="c-itemsList__text">
                {renderUserInfoAddress(ogData)}
              </dd>
            </div>
            <div className="c-itemsList">
              <dt>電話番号</dt>
              <dd className="c-itemsList__text">{ogData?.phone_no}</dd>
            </div>
            <div className="c-itemsList">
              <dt>メールアドレス</dt>
              <dd className="c-itemsList__text">{ogData?.email}</dd>
            </div>
            <div className="c-itemsList">
              <dt>パスワード</dt>
              <dd className="c-itemsList__text">
                セキュリティのため表示できません。
              </dd>
            </div>
            <div className="c-itemsList">
              <dt>アレルギー情報</dt>
              <dd className="c-itemsList__text wrap">{ogData?.allergy_info}</dd>
            </div>
            <div className="c-itemsList">
              <dt>アレルギー反応薬剤</dt>
              <dd className="c-itemsList__text wrap">
                {ogData?.allergy_reactive}
              </dd>
            </div>
            <div className="c-itemsList">
              <dt>保険証情報</dt>

              {insuranceData.map((item) => {
                return (
                  <div>
                    <dd className="c-itemsList__text">
                      {item?.card_choices === 1 ? (
                          <>
                            <span>
                              交付年月日 &#058; {item?.date_of_issuance}交付
                            </span>
                            <span>記号 &#058; {item?.symbol}</span>
                            <span>
                              資格取得年月日 &#058; {item?.date_of_qualification}交付
                            </span>
                            <span>事業所名称 &#058; {item?.office_name}</span>
                            <span>保険者番号 &#058; {item?.insurance_number}</span>
                            <span>保険者名称 &#058; {item?.insurer_name}</span>
                            <span>保険者所在地 &#058; {item?.insurer_location}</span>
                          </>
                        ): (
                          <>
                            <span>有効期限 &#058; {item?.expiration_date}</span>
                            <span>記号 &#058; {item?.symbol}</span>
                            <span>
                              適用開始年月日 &#058; {item?.date_of_qualification}交付
                            </span>
                            <span>
                              交付年月日 &#058; {item?.date_of_issuance}交付
                            </span>
                            <span>
                              世帯主氏名 &#058; {item?.name_of_head_of_household}
                            </span>
                            <span>保険者番号 &#058; {item?.insurance_number}</span>
                            <span>交付者名 &#058; {item?.recipient_name}</span>
                          </>
                        )}
                    </dd>
                  </div>
                );
              })}
            </div>
            <div className="c-itemsList">
              <dt>過去の処方履歴</dt>
              <dd className="c-itemsList__text">
                <LinkText text="処方箋履歴をみる" url="/mypage/prescription" />
              </dd>
            </div>
            <div className="c-itemsList">
              <dt>基礎疾患</dt>
              {insuranceData.map((item) => {
                return (
                  <div>
                    <dd className="c-itemsList__text wrap">
                      {item?.underlying_disease}
                    </dd>
                  </div>
                );
              })}
            </div>
            <div className="c-itemsList">
              <dt>かかりつけ医療機関</dt>
              {insuranceData.map((item) => {
                return (
                  <dd className="c-itemsList__text">
                    {item?.family_medical_institution}
                  </dd>
                );
              })}
            </div>
            <div className="c-itemsList">
              <dt>かかりつけ調剤薬局</dt>
              {insuranceData?.map((item) => {
                return (
                  <dd className="c-itemsList__text">
                    {item?.family_dispensing_pharmacy}
                  </dd>
                );
              })}
            </div>
            <div className="c-itemsList">
              <dt>担当薬剤師</dt>
              {insuranceData.map((item) => {
                return (
                  <dd className="c-itemsList__text">
                    {item?.pharmacist_in_charge}
                  </dd>
                );
              })}
            </div>
          </dl>

          <div>
            <Link
              to="/mypage/user/update"
              className="c-mainButton link"
              state={{ patientId: ogData.id }}
            >
              ユーザー情報を編集する
            </Link>
          </div>
        </section>
      </main>
    </>
  );
}
