import axios from "axios";

const frgtPassword = (data) =>
  axios.post("https://dev-api-pharmacy.automagicjapan.tech/api/forgot_password/", data);

const newPassword = (data) =>
  axios.post("https://dev-api-pharmacy.automagicjapan.tech/api/reset_password/", data);

const auth = {
  frgtPassword,
  newPassword,
};

export default auth;
