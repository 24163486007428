const regex = /([0-9].*[a-zA-Z]|[a-zA-Z].*[0-9])/;
const cregex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

export const isPassword = (value, name) => {
  if (value.includes(" ")) {
    return "空白（スペース）は利用できません。";
  } else if (!value) {
    return `${name}は必須入力項目です。`;
  } else if (!regex.test(value)) {
    return `${name}は半角英数でご入力ください。`;
  } else if (!cregex.test(value)) {
    return `パスワードに特殊文字(*@#%&!)を1文字含めてください。`;
  } else {
    return "";
  }
};
