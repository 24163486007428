// 日付変換（2021/01/01 → 2021年1月1日）
export const getDateFromValue = (value) => {
  const d = new Date(value)
  const year = d.getFullYear()
  const month = d.getMonth() + 1
  const date = d.getDate()

  return year + '年' + month + '月' + date + '日'
}

// 日付変換（2021/01/01 → 令和3年1月1日）
export const getJpnDateFromValue = (value) => {
  if(value=="")
  return "";
  else{
  const d = new Date(value)
  const options = {era: 'long', year:'numeric', month:'long', day:'numeric'};
  
  return new Intl.DateTimeFormat('ja-JP-u-ca-japanese', options).format(d)
  }
}

// 日付変換（2022-03-15T23:10:45 → 2022/03/15）
export const getDateFromWPValue = (value) => {
  const d = new Date(value)
  const year = d.getFullYear()
  const month = ("0" + (d.getMonth() + 1)).slice(-2)
  const day = ("0" + d.getDate()).slice(-2)
  const date = `${year}/${month}/${day}`

  return date
}

// 日付変換（2022-03-15T23:10:45 → 23:00）
export const getTimeFromWPValue = (value) => {
  const d = new Date(value)
  const hours = ("0" + d.getHours()).slice(-2)
  const minutes = ("0" + d.getMinutes()).slice(-2)
  const time = `${hours}:${minutes}`

  return time
}