import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { LinkButton, SignUpLink } from "../components/projects";
import { SearchStore } from "../components/ui";
import { useState } from "react";
import noticeAPI from "../../apis/notice";
import moment from "moment";
import Loader from "../components/ui/loader";
import { getAuthToken } from "../../helpers/storage";

// ダミーデータ
const item = {
  date: "2021/12/21",
  time: "13:22",
  title:
    "お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。",
  img: "/assets/img/news-dammy.jpg",
  text: "お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。",
};

export default function News() {
  const token = getAuthToken();
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [date, setDate] = useState("");

  const idparam = useParams();
  const iddata = idparam.id;

  const getData = async (iddata) => {
    const response = await noticeAPI.noticeid(iddata);
    setNews(response);
    var createdDate = response.data.created_at.substring(11, 16);
    console.log(response);

    setDate(createdDate);
    setIsLoading(false);
  };

  useEffect(() => getData(iddata), []);

  if (isLoading) return <Loader />;

  return (
    <main className="p-news">
      <article className="p-news__article">
        <p className="p-news__date">
          <span>{moment(news?.data.created_at).format("YYYY-MM-DD")}</span>
          {/* <span>{moment(news?.data.created_at).format("YYYY-MM-DD")}</span> */}
          <span>{date}</span>
        </p>
        <h2>{news.data.title}</h2>
        <div>
          <div className="p-news__img">
            <img src={news?.data.image_registration} alt={news.data.title} />
          </div>
          <p className="p-news__text">{news?.data.text}</p>

          <div className="c-linkText p-news__link">
            <Link to="/news">お知らせ一覧に戻る</Link>
          </div>
        </div>
      </article>
      ;
      {!token && <SignUpLink />}
      <LinkButton
        text="じぶん薬局へのお問い合わせ"
        url="/contact"
        color="blue"
      />
      <SearchStore />
    </main>
  );
  //});
}
//}
