import { memo, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./../../../assets/topMenu.css"


const items1 = [
  {
    name: "処方箋履歴",
    url: "/mypage/prescription",
    class: "history",
  },
  {
    name: "通院カレンダー",
    url: "/mypage/calendar",
    class: "carendar",
  },
  {
    name: "服薬指導メモ",
    url: "/mypage/memo",
    class: "memo",
  },
  {
    name: "処方受取予約",
    url: "/mypage/reserve",
    class: "reserve",
  },
];
const items2 = [
  {
    name: "遠隔服薬指導",
    url: "/mypage/reserve",
    class: "teach",
  },
  {
    name: "医薬品検索",
    url: "/mypage/medicine",
    class: "search",
  },
  {
    name: "ユーザー情報",
    url: "/mypage/user",
    class: "user",
  },
];


const MypageTopMenu = memo(() => {
  const location = useLocation();

 
  
  return (
   
    <nav className="l-topMenu" >
      <h2>
        <span style={{color:"#faa04d"}}>おくすり手帳</span>
      </h2>
      
      <ul className="l-topMenu__list1"  >
        {items1.map((item, i) => {
          return (
            <li key={i}>
              <Link to={item.url} style={{borderColor: "#faa04d"}}>
                <span className={item.class} style={{color: "#faa04d"}}>{item.name}</span>
              </Link>
            </li>
          );
        })}
      </ul>
      {location.pathname === "/mypage" && (
        <ul className="l-topMenu__list2">
          {items2.map((item, i) => {
            return (
              <li key={i} style={{borderColor: "#faa04d"}}>
                <Link to={item.url}  style={{borderColor: "#faa04d" ,background: "#faa04d"}}>
                  <span className={item.class}>{item.name}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </nav>
    
  );
});

export default MypageTopMenu;
