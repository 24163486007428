import { ScrollToTop } from "./utils/hooks";
import { Header, HeaderNav, Footer } from "./views/components/ui";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Home,
  SignUp,
  SignUpComp,
  NewsList,
  News,
  Contact,
  Thanks,
  TipsList,
  Tips,
  TipsCategory,
  SearchStores,
  Store,
  Password,
  NewPassword,
  Mypage,
  PrescriptionHistory,
  Prescription,
  PrescriptionInput,
  Calendar,
  Memo,
  MemoInput,
  MemoUpdate,
  ReserveList,
  ReserveInput,
  Reserve,
  SearchMedicineInput,
  MedicineList,
  Medicine,
  MyNewsList,
  MyNews,
  UserInfo,
  UserInfoUpdate,
  PrivacyPolicy,
} from "./views/pages";
import MyMedicine from "./views/pages/MyMedicine";
import { UserContext } from "./UserContext.js";
import MedicineNewsList from "./views/pages/MedicineNewsList";
import Evaluation from "./views/pages/Evalution";
import Contactout from "./views/pages/SingInContact";

function App() {
  return (
    <>
      {/* <ToastContainer /> */}
      <BrowserRouter>
        <UserContext>
          <ScrollToTop>
            <Header />
            <HeaderNav />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/signUp" element={<SignUp />} />
              <Route path="/signUpComp" element={<SignUpComp />} />
              <Route path="/news" element={<NewsList />} />
              <Route path="/news/:id" element={<News />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/thanks" element={<Thanks />} />
              <Route path="/contactout" element={<Contactout />} />
              <Route path="/tips" element={<MedicineNewsList />} />
              {/* <TipsList /> */}
              <Route path="/tips/:id" element={<Tips />} />
              <Route path="/tips/category/:id" element={<TipsCategory />} />
              <Route path="/stores" element={<SearchStores />} />
              <Route path="/stores/:id" element={<Store />} />
              <Route path="/password" element={<Password />} />
              <Route path="/newPassword" element={<NewPassword />} />
              <Route path="/mypage" element={<Mypage />} />
              <Route
                path="/mypage/prescription"
                element={<PrescriptionHistory />}
              />
              <Route
                path="/mypage/prescription/:id"
                element={<Prescription />}
              />
              <Route
                path="/mypage/prescription/new"
                element={<PrescriptionInput />}
              />
              <Route
                path="/mypage/prescription/:id/update"
                element={<PrescriptionInput />}
              />
              <Route path="/mypage/calendar" element={<Calendar />} />
              <Route path="/mypage/memo" element={<Memo />} />
              <Route path="/mypage/memo/new" element={<MemoInput />} />
              <Route path="/mypage/memo/:id" element={<MemoUpdate />} />
              <Route path="/mypage/reserve" element={<ReserveList />} />
              <Route path="/mypage/reserve/new" element={<ReserveInput />} />
              <Route path="/mypage/reserve/:id" element={<Reserve />} />
              <Route
                path="/mypage/medicine"
                element={<SearchMedicineInput />}
              />

              <Route path="/evaluationfail" element={<Evaluation />} />

              <Route path="/mypage/medicine/list" element={<MedicineList />} />
              <Route path="/mypage/medicine/:id" element={<Medicine />} />
              <Route path="/mypage/news" element={<MyNewsList />} />
              <Route path="/mypage/news/:id" element={<MyNews />} />
              <Route path="/mymedicine/:id" element={<MyMedicine />} />
              <Route path="/mypage/user" element={<UserInfo />} />
              <Route path="/mypage/user/update" element={<UserInfoUpdate />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
            <Footer />
          </ScrollToTop>
        </UserContext>
      </BrowserRouter>
    </>
  );
}

export default App;
