import axios from "axios";

const getData = (pageNo) =>
  axios.get(`https://dev-api-pharmacy.automagicjapan.tech/api/medicine_tips/?page=${pageNo}`);
const detailedData = (id) =>
  axios.get(`https://dev-api-pharmacy.automagicjapan.tech/api/medicine_tips/${id}/`);

const medicineTips = {
  getData,
  detailedData,
};

export default medicineTips;
