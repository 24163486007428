import { memo, useState } from "react";

const FormSelect = memo((props) => {
  var { name, errorMessage, initialValue, options, border } = props;

  // セレクトボックスのcss変更
  const [selectChange, setSelectChange] = useState(false);
  const handleSelectChange = () => {
    setSelectChange(true);
  };
var data="";



 if(props.hasOwnProperty("item"))
  data=props.item.number_of_split_dispensing;
  else if(props.hasOwnProperty("data"))
  {
    var tempArr=[]
    for(var i=0;i<props.data.length;i++)
    tempArr.push(props.data[i].drug_classification_jp)

    options=tempArr;
  }


   

  return (
    <div className={`c-selectBox ${border && "border"}`}>
      <div className="c-selectBox__container">
        <select
          name={name}
          className={`${errorMessage ? "error" : ""} ${
            selectChange ? "selected" : ""
          }`}
          onChange={handleSelectChange}
        >
          <option hidden value="">
            {initialValue}
          </option>
          {options.map((item, i) => {
            return (
              <option value={item} selected={item==data?true: ""}key={i}>
                {item}
              </option>
            );
          })}
        </select>
      </div>
      {errorMessage && (
        <span className="l-formInput__errorMessage">{errorMessage}</span>
      )}
    </div>
  );
});

export default FormSelect;

// new comment
