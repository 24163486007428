import { memo, useState, useEffect } from "react"
import { Link } from "react-router-dom"

import { URL } from "../../../utils/data"

const TipsCategoryList = memo(() => {

  const [ category, setCategory ] = useState({isLoaded: false, items: '', error: false})
  useEffect(() => {
    const url = URL

    // カテゴリー取得
    const categoryURL = url + "/wp-json/wp/v2/categories"
    fetch(categoryURL, {method: "GET"})
    .then(res => res.json())
    .then(data => {
      setCategory({
        isLoaded: true,
        items: data,
        error: false
      })
    })
    .catch(error => {
      console.log('error', error)
      setCategory({
        isLoaded: true,
        items: '',
        error: true
      })
    })
  }, [])

  return (
    <section className="p-tips__category">
      <h3>投稿カテゴリ一覧</h3>
      <ul>
        {category.isLoaded && !category.error &&
          category.items.map((item, i) => {
          return(
            <li key={i}>
              <Link to={`/tips/category/${item.id}`}>
                {item.name}
              </Link>
            </li>
          )
        })}
      {category.isLoaded && category.error &&
        <li>
          <p className="p-articleList__error">
            データの取得に失敗しました。
            運営までお問い合わせください。
          </p>
        </li>
      }
      </ul>
    </section>
  )
})

export default TipsCategoryList