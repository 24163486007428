import { memo } from "react";

const FormCheckBox = memo((props) => {

  const { title, required, name, value, label, errorMessage, handleChecked } = props

  return (
    <div className="l-formInput__checkbox">
      <p className="l-formInput__checkbox__title">
        {title}
        {required ? (
          <span className="l-formInput__required">必須</span>
        ) : (
          <span className="l-formInput__any">任意</span>
        )}
      </p>
      <label>
        <input type="checkbox" name={name} value={value} onClick={handleChecked} />
        <div className="l-formInput__checkbox__text">
          {label}
        </div>
      </label>
      {errorMessage && <span className="l-formInput__errorMessage">{errorMessage}</span>}
    </div>
  )
})

export default FormCheckBox
