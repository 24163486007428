import axios from "axios";
import { getAuthToken } from "../helpers/storage";

const getReceiptData = (headers, page) =>
  axios.get(
    `https://dev-api-pharmacy.automagicjapan.tech/api/for_reservation/?page=${page}`,

    {
      headers: headers,
    }
  );

const getReceiptDataID = (id, headers) =>
  axios.get(`https://dev-api-pharmacy.automagicjapan.tech/api/for_reservation/${id}`, {
    headers: headers,
  });

const getMessage = (headers, id) =>
  axios.get(
    `https://dev-api-pharmacy.automagicjapan.tech/api/for_reservation/?reservation_id=${id}`,
    {
      headers: headers,
    }
  );

const sendData = async (data, headers) => {
  var res = await axios.post(
    "https://dev-api-pharmacy.automagicjapan.tech/api/for_reservation/",
    data,
    {
      headers: headers,
    }
  );
  return res;
};

const putData = (data, headers, id) =>
  axios.put(`https://dev-api-pharmacy.automagicjapan.tech/api/for_reservation/${id}/`, data, {
    headers: headers,
  });

const messageSeen = (id) => {
  const token = "token" + " " + getAuthToken();
  const config = {
    method: "put",
    url: `https://dev-api-pharmacy.automagicjapan.tech/api/for_reservation/${id}/`,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      is_seen_patient: true,
    }),
  };
  axios(config);
};

const getReceipt = {
  getReceiptData,
  getReceiptDataID,
  getMessage,
  sendData,
  putData,
  messageSeen,
};

export default getReceipt;
