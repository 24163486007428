import axios from "axios";
import { getAuthToken } from "../helpers/storage";

const getAuthHeader = () => {
  const token = getAuthToken();
  return {
    Authorization: `token ${token}`,
  };
};

const sendPrescription = async (data, headers, id,checkDefaultImg) => {
  console.log(data);
  var DataForm = new FormData();
  DataForm.append("prescription_date", data.prescription_date);
  DataForm.append("time", "");
  DataForm.append("isRed", false);
  DataForm.append("gender",data.gender)
  DataForm.append("change_to_generic_drugs",data.change_to_generic_drugs);
  DataForm.append("insurer_number", data.insurer_number);
  DataForm.append("name", data.name);
  DataForm.append("birthday", data.birthday);
  DataForm.append("medical_institution_name", data.medical_institution_name);
  DataForm.append("contact", data.contact);
  DataForm.append("prescribing_physician", data.prescribing_physician);
  DataForm.append("prescription_details", data.prescription_details);
  DataForm.append("number_of_split_dispensing",data.number_of_split_dispensing)

  if(checkDefaultImg)
  DataForm.append("prescription_image", data.prescription_image);
 

  const config = {
    method: "put",
    url: `https://dev-api-pharmacy.automagicjapan.tech/api/prescription_api/${id}/`,
    headers: {
      "Content-Type": "multipart/form-data",
      ...getAuthHeader(),
    },
    data: DataForm,
  };
  var response;
  response = await axios(config);

  return response;
};

const getprescription = (page) =>
  axios.get(
    `https://dev-api-pharmacy.automagicjapan.tech/api/prescription_api/?page=${page}`,
    {
      headers: getAuthHeader(),
    }
  );

const getPrescriptionOcr = (data) =>
  axios.post("https://dev-api-pharmacy.automagicjapan.tech/api/prescription_ocr/", data, {
    headers: getAuthHeader(),
  });

const getprescriptionid = (idParam, headers) =>
  axios.get(`https://dev-api-pharmacy.automagicjapan.tech/api/prescription_api/${idParam}`, {
    headers: getAuthHeader(),
  });

const editPrescription = (id, data, headers) =>
  axios.post(
    `https://dev-api-pharmacy.automagicjapan.tech/api/prescription_api/${id}`,
    data,
    {
      headers: getAuthHeader(),
    }
  );


  const createPrescription=async (data)=>{
    var DataForm=new FormData();
DataForm.append("prescription_date",data.prescription_date);
DataForm.append("time","");
DataForm.append("isRed",false);
DataForm.append("insurer_number",data.insurer_number)
DataForm.append("name",data.name);
DataForm.append("birthday",data.birthday)
DataForm.append("medical_institution_name",data.medical_institution_name)
DataForm.append("contact",data.contact)
DataForm.append("prescribing_physician",data.prescribing_physician)
DataForm.append("prescription_details",data.prescription_details);
DataForm.append("gender",data.gender)
DataForm.append("change_to_generic_drugs",data.change_to_generic_drugs)
DataForm.append("number_of_split_dispensing",data.number_of_split_dispensing)

  if(data.prescription_image && data.prescription_image.name!=="") {
    DataForm.append("prescription_image",data.prescription_image)
  }

  const config = {
    method: "post",
    url: `https://dev-api-pharmacy.automagicjapan.tech/api/prescription_api/`,
    headers: {
      "Content-Type": "multipart/form-data",
      ...getAuthHeader(),
    },
    data: DataForm,
  };
  var response;
  response = await axios(config);

  return response;
};


const prescriptionAPI = {
  editPrescription,
  sendPrescription,
  getprescription,
  getprescriptionid,
  createPrescription,
  getPrescriptionOcr
};
export default prescriptionAPI
