// インプットフォーム
import { memo, useEffect } from "react";
import axios from "axios";
import { usePreviousProps } from "@mui/utils";
import { getID } from "../../../helpers/storage";

var id = getID();

const FormBorderInput = memo((props) => {
  const {
    name,
    data1,
    placeholder,
    label,
    required,
    type,
    errorMessage,
    disabled,
    value,
    autocomplete,
    isControlled,
  } = props;

  var data = "";
  if (props.name === "insurer_number" && props.hasOwnProperty("item"))
    data = props.item.insurer_number;
  else if (props.name === "name" && props.hasOwnProperty("item"))
    data = props.item.name;
  else if (props.name === "birthday" && props.hasOwnProperty("item"))
    data = props.item.birthday;
  else if (
    props.name === "medical_institution_name" &&
    props.hasOwnProperty("item")
  )
    data = props.item.medical_institution_name;
  else if (props.name === "contact" && props.hasOwnProperty("item"))
    data = props.item.contact;
  else if (
    props.name === "prescribing_physician" &&
    props.hasOwnProperty("item")
  )
    data = props.item.prescribing_physician;
  if (props.name === "userName" && props.hasOwnProperty("title"))
    data = props.title.name;
  if (props.name === "nickname" && props.hasOwnProperty("title"))
    data = props.title.nickname;
  if (props.name === "phoneNumber" && props.hasOwnProperty("title"))
    data = props.title.phone_no;
  if (props.name === "email" && props.hasOwnProperty("title"))
    data = props.title.email;
  else if (props.name === "password" && props.hasOwnProperty("title"))
    data = "Nhs@123a";

  if (props.hasOwnProperty("title")) {
    if (
      props.name === "household" &&
      props.title.hasOwnProperty("patient_insurance") &&
      props.title.patient_insurance.length !== 0
    )
      data = props.title.patient_insurance[0].name_of_head_of_household;
    else if (
      props.name === "office" &&
      props.title.hasOwnProperty("patient_insurance") &&
      props.title.patient_insurance.length !== 0
    )
      data = props.title.patient_insurance[0].office_name;
    else if (
      props.name === "insuranceNumber" &&
      props.title.hasOwnProperty("patient_insurance") &&
      props.title.patient_insurance.length !== 0
    )
      data = props.title.patient_insurance[0].insurance_number;
    else if (
      props.name === "issure" &&
      props.title.hasOwnProperty("patient_insurance") &&
      props.title.patient_insurance.length !== 0
    )
      data = props.title.patient_insurance[0].recipient_name;
    else if (
      props.name === "insurer" &&
      props.title.hasOwnProperty("patient_insurance") &&
      props.title.patient_insurance.length !== 0
    )
      data = props.title.patient_insurance[0].insurer_name;
    else if (
      props.name === "insurerAddress" &&
      props.title.hasOwnProperty("patient_insurance") &&
      props.title.patient_insurance.length !== 0
    )
      data = props.title.patient_insurance[0].insurer_location;
    else if (
      props.name === "hospital" &&
      props.title.hasOwnProperty("patient_insurance") &&
      props.title.patient_insurance.length !== 0
    )
      data = props.title.patient_insurance[0].family_medical_institution;
    else if (
      props.name === "store" &&
      props.title.hasOwnProperty("patient_insurance") &&
      props.title.patient_insurance.length !== 0
    )
      data = props.title.patient_insurance[0].family_dispensing_pharmacy;
    else if (
      props.name === "pharmacist" &&
      props.title.hasOwnProperty("patient_insurance") &&
      props.title.patient_insurance.length !== 0
    )
      data = props.title.patient_insurance[0].pharmacist_in_charge;
  }
  console.log(label);

  return (
    <div className="l-formInput__form border">
      <label>
        {label}
        {required && <span className="l-formInput__required">必須</span>}
        {!required && <span className="l-formInput__any">任意</span>}
      </label>
      <input
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        type={type}
        // value={value}
        className={errorMessage ? "c-inputBorder error" : "c-inputBorder"}
        autoComplete={autocomplete}
        defaultValue={data === "" ? data1 : data}
        // {...(isControlled && { value })}
      />

      {errorMessage && (
        <span className="l-formInput__errorMessage">{errorMessage}</span>
      )}
    </div>
  );
});

export default FormBorderInput;
