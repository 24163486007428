import { memo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Pagination from '@mui/material/Pagination';

import { getDateFromWPValue, getTimeFromWPValue } from "../../../utils/hooks"
import { URL } from "../../../utils/data";

const TipsPager = memo((props) => {
  const { id, type, } = props

  const [ allPages, setAllPages ] = useState('')
  const [ page, setPage ] = useState(1)
  const handleChange = (event, value) => {
    setPage(value)
  };

  const [ data, setData ] = useState({isLoaded: false, items: '', error: false})
  useEffect(() => {
    const url = URL
    let endpoint
    if (type === 'category') {
      endpoint = `posts?categories=${id}&per_page=6`
    } else {
      endpoint = `posts?page=${page}&per_page=6`
    }
    const dataURL = url + "wp-json/wp/v2/" + endpoint
    fetch(dataURL, {method: "GET"})
    .then(res => {
      setAllPages(res.headers.get('x-wp-totalpages'))
      return res.json()
    })
    .then(data => {
      setData({
        isLoaded: true,
        items: data,
        error: false
      })
    })
    .catch(error => {
      console.log('error', error)
      setData({
        isLoaded: true,
        items: '',
        error: true
      })
    })

  }, [id, type, page])

  return (
    <>
      <dl>
        {data.isLoaded && !data.error &&
          data.items.map((item, i) => {
            return (
              <div key={i} className="c-tipsPageList">
                <Link to={`/tips/${item.id}`}>
                  <div className="c-tipsPageList__top">
                    <p>
                      <span>{getDateFromWPValue(item.date)}</span>
                      <span>{getTimeFromWPValue(item.date)}</span>
                    </p>
                    <span>{item.category_name}</span>
                  </div>
                  <dt>{item.title.rendered}</dt>
                  <dd
                    dangerouslySetInnerHTML={{
                      __html: item.excerpt.rendered.replace('[&hellip;]', '')
                    }} />
                </Link>
              </div>
            )
          })
        }
        {data.isLoaded && data.error &&
          <div className="c-tipsPageList__error">
            <dt>データの取得に失敗しました。</dt>
            <dd>運営までお問い合わせください。</dd>
          </div>
        }
      </dl>
      <div className="c-tipsPageList__pagination">
        <Pagination 
          count={Number(allPages)} 
          page={page} 
          onChange={handleChange} 
          size="large"
          siblingCount={0}
        />
      </div>
    </>
  )

})

export default TipsPager