import axios from "axios";

const contactApi = (data) =>
  axios.post("https://dev-api-pharmacy.automagicjapan.tech/api/inquiry_api/", data);

const contactHeadersApi = (data, headers) =>
  axios.post("https://dev-api-pharmacy.automagicjapan.tech/api/inquiry_api/", data, {
    headers: headers,
  });

const contact = {
  contactHeadersApi,
  contactApi,
};

export default contact;
