const regex = /^[A-Za-z0-9]*$/

export const isHalfSize = (value, name) => {
  if(!value) {
    return `${name}は必須入力項目です`
  } else if(!regex.test(value)) {
    return `${name}は半角英数でご入力ください。`
  } else {
    return ""
  }
}