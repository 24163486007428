import { memo, useState,useEffect } from "react";




const FormRadio = memo((props) => {
  const { title, name, options, required, method} = props;
  const [flag,setFlag]=useState(false)
  console.log(flag)

  var data;

     console.log(props.item)
     
   


useEffect(()=>{



  
  var path=window.location.pathname

  console.log(path)
  if(path!="/mypage/prescription/new"  && !flag)
  {

    console.log(props)
   if(name=="gender")
   setValue(props.item.gender)
   else if(name=="change_to_generic_drugs")
   setValue(props.item.change_to_generic_drugs)
   
  }
  if(props.hasOwnProperty("item")){

    if(Object.values(props.item).length!=0)
    {
    setFlag(true)
    }

  }
  



})





     
     

  const [value, setValue] = useState(options[0].value);
  console.log("value")
console.log(value);

  const handleChange = (e) => {
    if (method) {
      method(e.target.value);
      setValue(e.target.value);
    } else {
      setValue(e.target.value);
    }
  };
  
  

  // useEffect(()=>{
  //    if(props.hasOwnProperty("item"))
  //    {
     
  //    setValue("女");
     
  //    }
  //    else
  //    setValue(value);

  // },[])




  return (
    <div className="l-formInput__radio">
      <p>
        {title}
        {required && <span className="l-formInput__required">必須</span>}
      </p>
      {options.map((option, i) => {
        return (
          <label key={i}>
            <input
              type="radio"
              name={name}
              value={option.value}
              onChange={handleChange}
              checked={value == option.value ? "checked" : ""}
            />
            <span className="l-formInput__radio__text">{option.label}</span>
          </label>
        );
      })}
    </div>
  );
});

export default FormRadio;
