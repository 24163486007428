import { MypageTopMenu, SearchStore } from "../components/ui";
import {
  Reserve,
  NewsList,
  LinkButton,
  TipsList,
  LinkText,
} from "../components/projects";
import { useContext, useEffect, useState } from "react";
import noticeAPI from "../../apis/notice";
import getReceipt from "../../apis/prescriptionreceipt";
import { getAuthToken, getID } from "../../helpers/storage";
import MedList from "../components/projects/MedList";
import medicineTips from "../../apis/medicinetips";
import { Navigate, useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import moment from "moment";
import Loader from "../components/ui/loader";

// 予約ダミーデータ
const reserveItems = [
  {
    date: "2021/12/21",
    time: "13:22",
    store: "薬局・薬店名。薬局・薬店名。薬局・薬店名。",
    message: true,
    red: true,
    id: 1,
  },
  {
    date: "2021/12/21",
    time: "13:22",
    store: "薬局・薬店名。薬局・薬店名。薬局・薬店名。",
    red: false,
    id: 1,
  },
];

// お知らせダミーデータ
const newsItems = [
  {
    date: "2021/12/21",
    time: "13:22",
    text: "お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。",
    id: 1,
  },
  {
    date: "2021/12/21",
    time: "13:22",
    text: "お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。",
    id: 2,
  },
];

export default function Mypage() {
  const [isLoading, setIsLoading] = useState(false);
  const [news, setNews] = useState([]);
  const [message, setMessage] = useState([]);
  const [medicine, setMedicine] = useState([]);
  const [noticePage, setNoticePage] = useState({ pages: 1 });
  const [noticeCount, setNoticeCount] = useState(null);
  const [medicinePage, setMedicinePage] = useState({ pages: 1 });
  const [medicineCount, setMedicineCount] = useState(null);
  const [registeredNotice, setRegisteredNotice] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);
  const [pagination, setPagination] = useState();

  const onChange = (page) => {
    console.log("page number", page);
    setPageNumber(page);
  };

  const onMedicine = (page) => {
    setMedicinePage({ pages: page });
  };

  console.log(noticePage);

  var temp_arr = [];
  var resArr = [];

  const fetchData = async () => {
    // setIsLoading(true);
    // try {
    //   const { data } = await noticeAPI.notice(noticePage.pages);
    //   setNews(data.results);
    //   setNoticeCount(data.count)
    //   setPagination({
    //     next: data.next,
    //     prev: data.previous,
    //     count: data.count,
    //   });
    // } catch (error) {
    //   console.log(error);
    // }
    // //  finally {
    // //   setIsLoading(false);
    // // }

    var res1;
    res1 = await noticeAPI.notice(noticePage.pages);

    var len;
    if (res1.data.count > 10) {
      if (res1.data.count % 10 != 0) len = res1.data.count / 10 + 1;
      else len = res1.data.count / 10;
    }

    for (var i = 1; i <= len; i++) {
      var res2 = await noticeAPI.notice(i);

      for (var j = 0; j < res2.data.results.length; j++)
        resArr.push(res2.data.results[j]);
    }

    setTimeout(() => {
      console.log(resArr);
      temp_arr = resArr.filter(
        (date) =>
          moment(todaysDate).isSameOrAfter(date.deliver_start_time) &&
          moment(todaysDate).isSameOrBefore(date.deliver_end_time)
      );

      var newTempArr = [];
      var count = 0;

      for (var k = 0; k < temp_arr.length; k++) {
        count++;
        if (count == 11) break;

        newTempArr.push(temp_arr[k]);
      }

      setNews(newTempArr);
      setRegisteredNotice(temp_arr);
      setNoticeCount(temp_arr.length);
    }, len * 1000);
  };

  // useEffect(() => , [pageNumber]);

  const id = getID("id");

  const navigate = useNavigate();

  const todaysDate = moment(moment().toDate()).format("YYYY-MM-DD");

  const getData = async () => {
    getAuthToken() === null ? navigate("/") : navigate("/mypage");
    const token = "token" + " " + getAuthToken();
    const headers = { Authorization: token };
    var page = 1;
    try {
      const response2 = await medicineTips.getData(medicinePage.pages);
      const response1 = await getReceipt.getReceiptData(headers, page);
      console.log("medicine tips", response2);
      console.log("prescription receipt", response1);
      setMessage(response1.data?.results);
      setMedicine(response2.data.results);
      setMedicineCount(response2.data.count);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData();
  }, [medicinePage]);

  useEffect(() => {
    fetchData();
  }, []);

  const onNotice = (page) => {
    var count = 0;
    var tempArr = [];

    temp_arr = registeredNotice;

    if (temp_arr.length != 0) {
      for (var i = (page - 1) * 10; i < temp_arr.length; i++) {
        count++;
        if (count == 11) break;

        tempArr.push(temp_arr[i]);
      }

      setNews(tempArr);

      setNoticePage({ pages: page });
    }
  };

  console.log(news);

  if (isLoading) return <Loader />;
  console.log("message", message);
  console.log("news", news);

  console.log("this is news");
  console.log(news);

  return (
    <main className="p-mypage">
      <MypageTopMenu />

      <section className="p-mypage__reserve">
        <h2 className="c-sectionTitle">処方箋受け取り予約</h2>
        <ul>
          {message?.map((item, i) => {
            return <Reserve key={i} item={item} class="red" />;
          })}
        </ul>
        <div>
          {/* <Pagination
            size="small"
            // total={reservePagination && reservePagination.count}
            // onChange={reserveOnChange()}
            current={reservePageNo}
            showSizeChanger={false}
          /> */}
        </div>
      </section>

      <section className="p-home__news">
        <h2 className="c-sectionTitle">じぶん薬局からのお知らせ</h2>
        <dl>
          {news &&
            news.map((item, i) => {
              return <NewsList key={i} to={`/news/${item.id}`} item={item} />;
            })}
        </dl>
      </section>
      <center>
        <Pagination
          total={noticeCount != null ? noticeCount : ""}
          onChange={onNotice}
          pageSize={10}
          showSizeChanger={false}
          size="medium"
        />
      </center>
      <br />
      <br />

      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          background: "#F9F9F9",
          padding: "8px 20px",
          marginBottom: "35px",
        }}
      >
        <button
          style={{
            background: "#42B19D",
            color: "#FFFFFF",
            padding: "8px 15px",
            borderRadius: "8px",
          }}
          onClick={() => setPageNumber((prev) => Math.max(1, prev - 1))}
          disabled={pagination && !pagination.prev}
        >
          BACK
        </button>
        <span>PAGE {pageNumber}</span>
        <button
          style={{
            background: "#42B19D",
            color: "#FFFFFF",
            padding: "8px 15px",
            borderRadius: "8px",
          }}
          onClick={() => setPageNumber((prev) => prev + 1)}
          disabled={pagination && !pagination.next}
        >
          NEXT
        </button>
      </div> */}

      {/* <div style={{ marginBottom: "29px", textAlign: "center" }}>
        <Pagination
          // defaultCurrent={1}
          total={pagination && pagination.count}
          onChange={onChange}
          current={pageNumber}
        />
      </div> */}

      <LinkButton
        text="じぶん薬局へのお問い合わせ"
        url="/contact"
        color="blue"
      />

      <section className="p-home__tips">
        <h2 className="c-sectionTitle">おくすりTips</h2>
        {medicine?.map((item, i) => {
          return <MedList key={i} to={`/mymedicine/${item.id}`} item={item} />;
        })}
        <TipsList number={4} />
        <LinkText url="/" text="おくすりTipsのコンテンツ一覧をみる" />
      </section>
      <center>
        <Pagination
          total={medicineCount != null ? medicineCount : ""}
          onChange={onMedicine}
          pageSize={10}
          showSizeChanger={false}
          size="medium"
        />
      </center>
      <br />
      <br />

      <SearchStore />
    </main>
  );
}
