import { useState, useEffect } from "react";
import { usePostalJp } from "use-postal-jp";
import { useNavigate } from "react-router-dom";
import { userDetails, userEdit } from "../../apis/userEdit";
import { MypageTopMenu } from "../components/ui";
import FormRadioExtended from "../components/projects/FormRadioExtended";
import {
  FormBorderInput,
  FormDateInput,
  FormRadio,
  FormOrigDateInput,
  MainButton,
} from "../components/projects";
import {
  isEntered,
  isEmail,
  isPhoneNumber,
  isPassword,
  isHalfSize,
} from "../../utils/validation";
import { prefectures, laterDate, agoDate } from "../../utils/data";
import { useLocation } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import { getAuthToken, getID } from "../../helpers/storage";
import Loader from "../components/ui/loader";

// ダミーデータ
const item = {
  userName: "XXXX XXXX",
};

export default function UserInfoUpdate() {
  const stateData = useLocation();
  const [numSymbol, setNumSymbol] = useState("");
  const [gender, setGender] = useState("");
  const [detailsId, setDetailsId] = useState({});
  const [oldPatient, setOldPatient] = useState(false);
  const [patientId, setPatientId] = useState(0);
  const [postNum, setPostNum] = useState("");
  const [streetName, setStreetName] = useState("");
  const [insurance, setInsurance] = useState(null);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [symbolChar, setSymbolChar] = useState("");
  const [reSize, setReSize] = useState(0);

  var [fileUpload, setFileUpload] = useState(0);
  const [userData, setUserData] = useState({
    address: "",
    patient_insurance: [{ symbol: "" }],
  });
  const [enable, setEnable] = useState(false);

  const fetchData = async () => {
    getAuthToken() === null ? navigate("/") : setEnable(true);
    var res;
    const id = window.localStorage.getItem("id");
    res = await userEdit.userDetails("", id);
    var symbolNum = "";
    var postingNum;
    setPatientId(res?.data?.id);
    setData(res?.data);

    console.log(res);

    if (res.data.address != null) {
      if (
        Object.keys(res.data.address).length != 0 &&
        res.data.patient_insurance.length != 0
      ) {
        postingNum = res.data.address.address.split(",");
        setPostNum(postingNum[0]);
        setStreetName(postingNum[1]);
        setOldPatient(true);
        setDetailsId({
          addressId: res?.data?.address?.id,
          insuranceId: res?.data?.patient_insurance[0]?.id,
          address: res?.data?.address,
        });
        setInsurance(res?.data?.patient_insurance[0]);
      }
    }

    var symbolChar = "";
    var tempSymbolChar = "";

    if (res.data.patient_insurance.length != 0) {
      if (res.data.patient_insurance[0].symbol.includes("01")) {
        symbolChar = res.data.patient_insurance[0].symbol.split("01");
        symbolNum = "01";
        tempSymbolChar = symbolChar[0];
        tempSymbolChar = tempSymbolChar.replace("番号", "");
      } else if (res.data.patient_insurance[0].symbol.includes("02")) {
        symbolNum = "02";
        symbolChar = res.data.patient_insurance[0].symbol.split("02");
        tempSymbolChar = symbolChar[0];
        tempSymbolChar = tempSymbolChar.replace("番号", "");
      }
    }

    var genders =
      res.data.gender == "male"
        ? "男"
        : res.data.gender == "female"
        ? "女"
        : "";

    setGender(genders);
    setSymbolChar(tempSymbolChar);
    setNumSymbol(symbolNum);
    setUserData(res.data);
    setIsLoading(false);
  };

  useEffect(() => fetchData(), []);

  const handleFile = (e) => {
    const files = e.target.files[0];
    // console.log(files);
    setFileUpload(files);
  };

  // 性別
  const genderRadio = [
    {
      label: "男",
      value: "男",
      initial: true,
    },
    {
      label: "女",
      value: "女",
      initial: false,
    },
  ];

  // 保険証
  const insuranceRadio = [
    {
      label: "事業者健康保険証",
      value: "事業者健康保険証",
      initial: true,
    },
    {
      label: "国民健康保険証",
      value: "国民健康保険証",
      initial: false,
    },
  ];

  // 番号セレクトボックス
  const numberOptions = ["01", "02"];

  // 住所検索
  const [postNumber, setPostNumber] = useState("");
  const [address] = usePostalJp(postNumber, postNumber.length >= 7);
  const handleChangeNumber = (e) => {
    setPostNumber(e.target.value);
  };

  // 保険証種類
  const [insuranceType, setInsuranceType] = useState("事業者健康保険証");
  const handleChangeType = (e) => {
    setInsuranceType(e);
  };

  // 入力バリデーション
  // 事業者健康保険証
  const [errorMessages, setErrorMessages] = useState({});
  const isValidate = (values) => {
    let messages = {};
    messages.gender = values.gender ? "" : "性別は必須入力項目です。";
    messages.userName = isEntered("お名前", values.userName, false);
    messages.birthday = isEntered("生年月日", values.birthday, false);
    messages.email = isEmail(values.email);
    messages.postNumber = isEntered("郵便番号", values.postNumber, false);
    messages.prefecture = isEntered("都道府県", values.prefecture, true);
    messages.city = isEntered("市区町村", values.city, false);
    messages.address = isEntered("町名番地", values.address, false);
    messages.phoneNumber = isPhoneNumber(values.phoneNumber);
    messages.password = isPassword(values.password, "パスワード");
    messages.delivary = isEntered("交付年月日", values.delivary1, false);
    messages.insuranceCode =
      isEntered("記号・番号（※国保は枝番）", values.insuranceCode1, false) ||
      isEntered("記号・番号（※国保は枝番）", values.insuranceCode2, false);
    messages.getDate =
      isEntered("資格取得年月日）", values.year, false) ||
      isEntered("記号・番号（※国保は枝番）", values.month, false) ||
      isEntered("記号・番号（※国保は枝番）", values.date, false);
    messages.office = isEntered("事業所名称", values.office, false);
    messages.insuranceNumber = isHalfSize(values.insuranceNumber, "保険番号");
    messages.insurer = isEntered("保険者名称", values.insurer, false);
    messages.insurerAddress = isEntered(
      "保険者所在地",
      values.insurerAddress,
      false
    );

    setErrorMessages({ ...messages });
    return Object.values(messages).every((message) => message === "");
  };
  // 国民健康保険
  const isValidate2 = (values) => {
    let messages = {};
    messages.gender = values.gender ? "" : "性別は必須入力項目です。";
    messages.userName = isEntered("お名前", values.userName, false);
    messages.birthday = isEntered("生年月日", values.birthday, false);
    messages.email = isEmail(values.email);
    messages.postNumber = isEntered("郵便番号", values.postNumber, false);
    messages.prefecture = isEntered("都道府県", values.prefecture, true);
    messages.city = isEntered("市区町村", values.city, false);
    messages.address = isEntered("町名番地", values.address, false);
    messages.phoneNumber = isPhoneNumber(values.phoneNumber);
    messages.password = isPassword(values.password, "パスワード");
    messages.limit = isEntered("有効期限", values.limit, false);
    messages.delivary = isEntered("交付年月日", values.delivary2, false);
    messages.insuranceCode =
      isEntered("記号・番号（※国保は枝番）", values.insuranceCode1, false) ||
      isEntered("記号・番号（※国保は枝番）", values.insuranceCode2, false);
    messages.getDate =
      isEntered("資格取得年月日）", values.year, false) ||
      isEntered("記号・番号（※国保は枝番）", values.month, false) ||
      isEntered("記号・番号（※国保は枝番）", values.date, false);
    messages.household = isEntered("世帯主氏名", values.household, false);
    messages.insuranceNumber = isHalfSize(values.insuranceNumber, "保険番号");
    messages.issure = isEntered("交付者", values.issure, false);

    setErrorMessages({ ...messages });
    return Object.values(messages).every((message) => message === "");
  };

  // 変更処理
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const values = Object.fromEntries(data.entries());

    if (insuranceType === "事業者健康保険証" && isValidate(values)) {
      values.getDate = values.year + "-" + values.month + "-" + values.date;
      delete values.year;
      delete values.month;
      delete values.date;

      values.insuranceCode =
        values.insuranceCode1 + "番号" + values.insuranceCode2;
      delete values.insuranceCode1;
      delete values.insuranceCode2;

      values.delivary = values.delivary1;
      delete values.delivary1;

      delete values.household;
      delete values.issure;
      delete values.limit;

      // userEdit.userEditApi1(values);

      // userEdit.userEditApi2(values, fileUpload);

      // userEdit.userEditApi3(values);

      userEdit
        .userDetailsEdit(values, detailsId, oldPatient, patientId)
        .then((res) => {
          setTimeout(() => {
            window.location.href = "/mypage/user";
          }, 1000);
        })
        .catch((err) => {});

      // setTimeout(() => {

      //   navigate('/mypage/user')

      // }, 5000);
    } else if (insuranceType === "国民健康保険証" && isValidate2(values)) {
      values.getDate = values.year + "-" + values.month + "-" + values.date;
      delete values.year;
      delete values.month;
      delete values.date;

      values.insuranceCode =
        values.insuranceCode1 + "番号" + values.insuranceCode2;
      delete values.insuranceCode1;
      delete values.insuranceCode2;

      values.delivary = values.delivary2;
      delete values.delivary2;

      delete values.office;
      delete values.insurer;
      delete values.insurerAddress;

      // userEdit.userEditApi1(values);

      // userEdit.userEditApi2(values, fileUpload);

      // userEdit.userEditApi3(values);

      // setTimeout(() => {

      //   navigate('/mypage/user')

      // }, 5000);

      var response;
      response = userEdit.userDetailsEdit(
        values,
        detailsId,
        oldPatient,
        patientId
      );

      response
        .then((res) => {
          setTimeout(() => {
            window.location.href = "/mypage/user";
          }, 1000);
        })
        .catch((err) => {});
    }

    console.log("Values", values);
    console.log("userData", userData);
  };

  if (isLoading || !prefectures || !data) return <Loader />;

  console.log("prefectures", prefectures);
  console.log("data", data && data && data.prefecture);
  console.log("data", data);
  // let prefectured = prefectures.map((item) =>
  //   item.find((d) => d.code == data && data && data.prefecture)
  // );;

  console.log(prefectures);

  var prefectured = "";
  if (data.address != null) {
    prefectured = prefectures.find(
      (item) => item.code === data?.address.prefecture
    );

    console.log(errorMessages);
    console.log(numSymbol);
  }

  console.log("prefecture" + prefectured);

  const screenWidth = window.innerWidth;
  window.onresize = () => {
    setReSize(reSize + 1);
  };

  if (screenWidth > 1025) {
    var styleObj = {
      marginLeft: "23px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "50%",

      paddingLeft: "20%",
    };
  } else if (screenWidth > 500 && screenWidth < 1025) {
    var styleObj = {
      marginLeft: "23px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "50%",

      paddingLeft: "15%",
    };
  } else if (screenWidth > 300 && screenWidth < 500) {
    var styleObj = {
      marginLeft: "23px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
      textAlign: "center",
      paddingLeft: "22%",
    };
  } else {
    var styleObj = {
      marginLeft: "23px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
      textAlign: "center",
      paddingLeft: "10%",
    };
  }

  return (
    <main>
      <MypageTopMenu />
      <center>
        <div style={styleObj}>
          {Object.values(errorMessages).length != 0 ? (
            <>
              {errorMessages && errorMessages.userName != "" ? (
                <p
                  className="l-formInput__errorMessage"
                  style={{ color: "red" }}
                >
                  {errorMessages.userName}
                </p>
              ) : (
                <p style={{ display: "none" }}>{""}</p>
              )}
              {errorMessages.userName != "" ? <br /> : ""}

              {errorMessages && errorMessages.birthday != "" ? (
                <p
                  className="l-formInput__errorMessage"
                  style={{ color: "red" }}
                >
                  {errorMessages.birthday}
                  <br />
                </p>
              ) : (
                <p style={{ display: "none" }}>{""}</p>
              )}
              {errorMessages.birthday != "" ? <br /> : ""}

              {errorMessages && errorMessages.address != "" ? (
                <p
                  className="l-formInput__errorMessage"
                  style={{ color: "red" }}
                >
                  {errorMessages.address}
                </p>
              ) : (
                <p style={{ display: "none" }}>{""}</p>
              )}
              {errorMessages.address != "" ? <br /> : ""}

              {errorMessages && errorMessages.postNumber != "" ? (
                <p
                  className="l-formInput__errorMessage"
                  style={{ color: "red" }}
                >
                  {errorMessages.postNumber}
                </p>
              ) : (
                <p style={{ display: "none" }}>{""}</p>
              )}

              {errorMessages.postNumber != "" ? <br /> : ""}

              {errorMessages && errorMessages.phoneNumber != "" ? (
                <p
                  className="l-formInput__errorMessage"
                  style={{ color: "red" }}
                >
                  {errorMessages.phoneNumber}
                </p>
              ) : (
                <p style={{ display: "none" }}>{""}</p>
              )}

              {errorMessages.phoneNumber != "" ? <br /> : ""}
              {errorMessages && errorMessages.email != "" ? (
                <p
                  className="l-formInput__errorMessage"
                  style={{ color: "red" }}
                >
                  {errorMessages.email}
                </p>
              ) : (
                <p style={{ display: "none" }}>{""}</p>
              )}
              {errorMessages.email != "" ? <br /> : ""}

              {errorMessages && errorMessages.password != "" ? (
                <p
                  className="l-formInput__errorMessage"
                  style={{ color: "red" }}
                >
                  {errorMessages.password}
                </p>
              ) : (
                <p style={{ display: "none" }}>{""}</p>
              )}

              {errorMessages.password != "" ? <br /> : ""}

              {errorMessages && errorMessages.getDate != "" ? (
                <p
                  className="l-formInput__errorMessage"
                  style={{ color: "red" }}
                >
                  {errorMessages.getDate}
                </p>
              ) : (
                <p style={{ display: "none" }}>{""}</p>
              )}

              {errorMessages.getDate != "" ? <br /> : ""}

              {errorMessages && errorMessages.insuranceCode != "" ? (
                <p
                  className="l-formInput__errorMessage"
                  style={{ color: "red" }}
                >
                  {errorMessages.insuranceCode}
                </p>
              ) : (
                <p style={{ display: "none" }}>{""}</p>
              )}

              {errorMessages.insuranceCode != "" ? <br /> : ""}

              {errorMessages && errorMessages.insuranceNumber != "" ? (
                <p
                  className="l-formInput__errorMessage"
                  style={{ color: "red" }}
                >
                  {errorMessages.insuranceNumber}
                </p>
              ) : (
                <p style={{ display: "none" }}>{""}</p>
              )}
              {errorMessages.insuranceNumber != "" ? <br /> : ""}

              {errorMessages &&
              errorMessages.insurerAddress != "" &&
              !errorMessages.hasOwnProperty("limit") ? (
                <p
                  className="l-formInput__errorMessage"
                  style={{ color: "red" }}
                >
                  {errorMessages.insurerAddress}
                </p>
              ) : (
                <p style={{ display: "none" }}>{""}</p>
              )}

              {errorMessages.insurerAddress != "" &&
              !errorMessages.hasOwnProperty("limit") ? (
                <br />
              ) : (
                ""
              )}
              {errorMessages &&
              errorMessages.office &&
              !errorMessages.hasOwnProperty("limit") != "" ? (
                <p
                  className="l-formInput__errorMessage"
                  style={{ color: "red" }}
                >
                  {errorMessages.office}
                </p>
              ) : (
                <p style={{ display: "none" }}>{""}</p>
              )}

              {errorMessages.office != "" &&
              !errorMessages.hasOwnProperty("limit") != "" ? (
                <br />
              ) : (
                ""
              )}

              {errorMessages && errorMessages.prefecture != "" ? (
                <p
                  className="l-formInput__errorMessage"
                  style={{ color: "red" }}
                >
                  {errorMessages.prefecture}
                </p>
              ) : (
                <p style={{ display: "none" }}>{""}</p>
              )}
              {errorMessages.prefecture != "" ? <br /> : ""}

              {errorMessages && errorMessages.delivary != "" ? (
                <p
                  className="l-formInput__errorMessage"
                  style={{ color: "red" }}
                >
                  {errorMessages.delivary}
                </p>
              ) : (
                <p style={{ display: "none" }}>{""}</p>
              )}

              {errorMessages.delivary != "" ? <br /> : ""}

              {errorMessages && errorMessages.city != "" ? (
                <p
                  className="l-formInput__errorMessage"
                  style={{ color: "red" }}
                >
                  {errorMessages.city}
                </p>
              ) : (
                <p style={{ display: "none" }}>{""}</p>
              )}

              {errorMessages.city != "" ? <br /> : ""}

              {errorMessages &&
              errorMessages.limit &&
              Object.keys("limit") != "" ? (
                <p
                  className="l-formInput__errorMessage"
                  style={{ color: "red" }}
                >
                  {errorMessages.limit}
                </p>
              ) : (
                <p style={{ display: "none" }}>{""}</p>
              )}

              {errorMessages.limit != "" ? <br /> : ""}

              {errorMessages &&
              errorMessages.household &&
              Object.keys("limit") != "" ? (
                <p
                  className="l-formInput__errorMessage"
                  style={{ color: "red" }}
                >
                  {errorMessages.household}
                </p>
              ) : (
                <p style={{ display: "none" }}>{""}</p>
              )}
              {errorMessages &&
              errorMessages.gender &&
              Object.keys("limit") != "" ? (
                <p
                  className="l-formInput__errorMessage"
                  style={{ color: "red" }}
                >
                  {errorMessages.gender}
                </p>
              ) : (
                <p style={{ display: "none" }}>{""}</p>
              )}
              {errorMessages &&
              errorMessages.issure &&
              Object.keys("limit") != "" ? (
                <p
                  className="l-formInput__errorMessage"
                  style={{ color: "red" }}
                >
                  {errorMessages.issure}
                </p>
              ) : (
                <p style={{ display: "none" }}>{""}</p>
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </center>

      <section className="p-prescription__new">
        <h2 className="c-sectionTitle c-mypageHeading">
          {userData.name != null ? userData.name : ""} 様のユーザー情報
        </h2>

        <div className="p-prescription__new__container">
          <form onSubmit={handleSubmit}>
            <FormBorderInput
              name="userName"
              label="お名前（フルネームでご入力ください）"
              placeholder="お名前をフルネームで入力してください"
              required={true}
              type="text"
              errorMessage={
                errorMessages.userName ? errorMessages.userName : ""
              }
              title={userData}
            />
            <FormBorderInput
              name="nickname"
              label="ニックネーム"
              placeholder="ニックネームを入力してください"
              required={false}
              type="text"
              title={userData}
            />
            <FormDateInput
              label="生年月日"
              required={true}
              name="birthday"
              errorMessage={
                errorMessages.birthday ? errorMessages.birthday : ""
              }
              initialValue="1970-01-01"
              title={userData}
            />
            {/* <FormRadio
              name="gender"
              title="性別"
              options={genderRadio}
              required={true}
              item={gender}
            /> */}
            <FormRadioExtended
              name="gender"
              title="性別"
              options={genderRadio}
              required={false}
              defaultValue={gender}
              errorMessage={errorMessages.gender ? errorMessages.gender : ""}
            />
            <div className="l-formInput__form border">
              <label>
                住所
                <span className="l-formInput__required">必須</span>
              </label>
              <div className="l-formInput__address number">
                〒
                <input
                  name="postNumber"
                  placeholder="1234567"
                  type="text"
                  // value={postNumber}
                  defaultValue={postNum}
                  maxLength={7}
                  className={
                    errorMessages.postNumber
                      ? "c-inputBorder error"
                      : "c-inputBorder"
                  }
                  onChange={handleChangeNumber}
                />
              </div>
              {errorMessages.postNumber && (
                <span className="l-formInput__errorMessage">
                  {errorMessages.postNumber}
                </span>
              )}
              <div className="l-formInput__address prefecture">
                <div>
                  <div className="c-selectBox__container address">
                    <select
                      //                      defaultValue={prefectured != "" ? prefectured.name : ""}
                      defaultValue={prefectured != "" ? prefectured.code : ""}
                      name="prefecture"
                      className={`${errorMessages.prefecture ? "error" : ""}`}
                    >
                      {/* <option value="">都道府県</option> */}
                      {prefectures.map((item, i) => {
                        return (
                          <option
                            value={item.code}
                            key={i}
                            selected={
                              (userData.prefecture != null ||
                                userData.prefecture != undefined) &&
                              userData.address.prefecture == item.code
                                ? true
                                : ""
                            }
                          >
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {errorMessages.prefecture && (
                    <span className="l-formInput__errorMessage">
                      {errorMessages.prefecture}
                    </span>
                  )}
                </div>
                <div>
                  <input
                    name="city"
                    placeholder="市区町村を入力してください"
                    type="text"
                    className={
                      errorMessages.city
                        ? "c-inputBorder error"
                        : "c-inputBorder"
                    }
                    defaultValue={
                      userData.hasOwnProperty("address") &&
                      userData.address != null
                        ? userData.address.city
                        : ""
                    }
                  />
                  {errorMessages.city && (
                    <span className="l-formInput__errorMessage">
                      {errorMessages.city}
                    </span>
                  )}
                </div>
              </div>
              <input
                name="address"
                placeholder="町名番地、建物名などを入力してください"
                type="text"
                className={
                  errorMessages.address
                    ? "c-inputBorder error"
                    : "c-inputBorder"
                }
                defaultValue={streetName}
              />
              {errorMessages.address && (
                <span className="l-formInput__errorMessage">
                  {errorMessages.address}
                </span>
              )}
            </div>
            <FormBorderInput
              name="phoneNumber"
              label="電話番号"
              placeholder="電話番号を入力してください"
              required={true}
              type="text"
              errorMessage={
                errorMessages.phoneNumber ? errorMessages.phoneNumber : ""
              }
              autocomplete="tel"
              title={userData}
            />
            <FormBorderInput
              name="email"
              label="メールアドレス"
              placeholder="メールアドレスを入力してください"
              required={true}
              type="email"
              errorMessage={errorMessages.email ? errorMessages.email : ""}
              autocomplete="email"
              title={userData}
            />
            <FormBorderInput
              name="password"
              label="パスワード"
              placeholder="パスワードを入力してください"
              required={true}
              type="password"
              errorMessage={
                errorMessages.password ? errorMessages.password : ""
              }
              autocomplete="off"
              title={userData}
            />
            <div className="l-formInput__form border">
              <label>
                アレルギー情報
                <span className="l-formInput__any">任意</span>
              </label>
              <textarea
                name="allergy"
                placeholder="アレルギー情報を入力してください"
                className="c-textarea__border min"
                defaultValue={
                  userData.hasOwnProperty("allergy_info")
                    ? userData.allergy_info
                    : ""
                }
              />
            </div>
            <div className="l-formInput__form border">
              <label>
                アレルギー反応薬剤
                <span className="l-formInput__any">任意</span>
              </label>
              <textarea
                name="drugAllergy"
                placeholder="アレルギー反応薬剤を入力してください"
                className="c-textarea__border min"
                defaultValue={
                  userData.hasOwnProperty("allergy_reactive")
                    ? userData.allergy_reactive
                    : ""
                }
              />
            </div>
            <FormRadio
              name="insurance"
              title="保険証情報"
              options={insuranceRadio}
              required={true}
              method={handleChangeType}
            />
            {insuranceType === "国民健康保険証" && (
              <FormDateInput
                label="有効期限"
                required={true}
                name="limit"
                errorMessage={errorMessages.limit ? errorMessages.limit : ""}
                initialValue={laterDate(2)}
                title={userData}
              />
            )}
            {insuranceType === "事業者健康保険証" && (
              <FormDateInput
                label="交付年月日"
                required={true}
                name="delivary1"
                errorMessage={
                  errorMessages.delivary ? errorMessages.delivary : ""
                }
                initialValue={agoDate(2)}
                title={userData}
              />
            )}
            <div className="l-formInput__form border">
              <label>
                記号
                <span className="l-formInput__required">必須</span>
              </label>
              <div className="l-formInput__insuranceNum">
                <input
                  name="insuranceCode1"
                  placeholder="記号を入力してくださいを入力してください"
                  type="text"
                  className={
                    errorMessages.insuranceCode
                      ? "c-inputBorder error"
                      : "c-inputBorder"
                  }
                  defaultValue={symbolChar}
                />
                <div className="c-selectBox__container address">
                  <select
                    name="insuranceCode2"
                    className={`${errorMessages.insuranceCode ? "error" : ""}`}
                  >
                    <option value="">
                      {insuranceType === "事業者健康保険証" ? "番号" : "枝番"}
                    </option>
                    {numberOptions.map((item, i) => {
                      return (
                        <option
                          value={item}
                          key={i}
                          selected={item == numSymbol ? true : ""}
                        >
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {errorMessages.insuranceCode && (
                <span className="l-formInput__errorMessage">
                  {errorMessages.insuranceCode}
                </span>
              )}
            </div>
            <FormOrigDateInput
              required
              label={
                insuranceType === "事業者健康保険証"
                  ? "資格取得年月日"
                  : "適用開始年月日"
              }
              defaultValue={insurance && insurance.date_of_qualification}
              errorMessage={errorMessages.getDate ? errorMessages.getDate : ""}
            />
            {insuranceType === "国民健康保険証" && (
              <FormDateInput
                label="交付年月日"
                required={true}
                name="delivary2"
                errorMessage={
                  errorMessages.delivary ? errorMessages.delivary : ""
                }
                initialValue={agoDate(2)}
              />
            )}
            {insuranceType === "国民健康保険証" && (
              <FormBorderInput
                name="household"
                label="世帯主氏名"
                placeholder="世帯主氏名を入力してください"
                required={true}
                type="text"
                errorMessage={
                  errorMessages.household ? errorMessages.household : ""
                }
                title={userData}
              />
            )}
            {insuranceType === "事業者健康保険証" && (
              <FormBorderInput
                name="office"
                label="事業所名称"
                placeholder="事業所名称を入力してください"
                required={true}
                type="text"
                errorMessage={errorMessages.office ? errorMessages.office : ""}
                title={userData}
              />
            )}
            <FormBorderInput
              name="insuranceNumber"
              label="保険番号"
              placeholder="保険番号を入力してください"
              required={true}
              type="text"
              errorMessage={
                errorMessages.insuranceNumber
                  ? errorMessages.insuranceNumber
                  : ""
              }
              title={userData}
            />
            {insuranceType === "国民健康保険証" && (
              <FormBorderInput
                name="issure"
                label="交付者"
                placeholder="交付者を入力してください"
                required={true}
                type="text"
                errorMessage={errorMessages.issure ? errorMessages.issure : ""}
                title={userData}
              />
            )}
            {insuranceType === "事業者健康保険証" && (
              <>
                <FormBorderInput
                  name="insurer"
                  label="保険者名称"
                  placeholder="保険者名称を入力してください"
                  required={true}
                  type="text"
                  errorMessage={
                    errorMessages.insurer ? errorMessages.insurer : ""
                  }
                  title={userData}
                />
                <FormBorderInput
                  name="insurerAddress"
                  label="保険者所在地"
                  placeholder="保険者所在地を入力してください"
                  required={true}
                  type="text"
                  errorMessage={
                    errorMessages.insurerAddress
                      ? errorMessages.insurerAddress
                      : ""
                  }
                  title={userData}
                />
              </>
            )}
            <div className="l-formInput__form border">
              <label>
                基礎疾患
                <span className="l-formInput__any">任意</span>
              </label>
              <textarea
                name="disease"
                placeholder="基礎疾患を入力してください"
                className="c-textarea__border min"
                defaultValue={
                  insurance && insurance.underlying_disease
                  // userData.hasOwnProperty("patient_insurance") &&
                  // userData.patient_insurance.length != 0
                  //   ? userData.patient_insurance[0].underlying_disease
                  //   : ""
                }
              />
            </div>
            <FormBorderInput
              name="hospital"
              label="かかりつけ医療機関"
              placeholder="かかりつけ医療機関を入力してください"
              required={false}
              type="text"
              errorMessage={
                errorMessages.hospital ? errorMessages.hospital : ""
              }
              title={userData}
            />
            <FormBorderInput
              name="store"
              label="かかりつけ調剤薬局"
              placeholder="かかりつけ調剤薬局を入力してください"
              required={false}
              type="text"
              errorMessage={errorMessages.store ? errorMessages.store : ""}
              title={userData}
            />
            <FormBorderInput
              name="pharmacist"
              label="担当薬剤師"
              placeholder="担当薬剤師を入力してください"
              required={false}
              type="text"
              errorMessage={
                errorMessages.pharmacist ? errorMessages.pharmacist : ""
              }
              title={userData}
            />
            {/* <label>Image File: </label>
            <input type="file" name="file" onChange={handleFile} /> */}
            <MainButton text="この内容で登録する" color="blue min" />
          </form>
        </div>
      </section>
    </main>
  );
}

//new comment
// updated comment

//new updated comment
