import axios from "axios";

const drugClassification = (headers) =>
  axios.get(`https://dev-api-pharmacy.automagicjapan.tech/api/drug_classification/`, {
    headers: headers,
  });

const detailedDrug = (id, headers) =>
  axios.get(`https://dev-api-pharmacy.automagicjapan.tech/api/pharma_otc_info/${id}/`, {
    headers: headers,
  });

const otcdetails = {
  drugClassification,
  detailedDrug,
};

export default otcdetails;
