// パスワード再設定ページ
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import auth from "../../apis/forgotpassword";
import { isPassword, isConfPassword } from "../../utils/validation";

import { FormInput } from "../components/projects";
import { MainButton } from "../components/projects";

export default function NewPassword() {
  // 入力バリデーション
  const [errorMessages, setErrorMessages] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const emailparam = searchParams.get("email");
  console.log("useparam", emailparam);

  const isValidate = (values) => {
    let messages = {};

    messages.password = isPassword(values.password, "パスワード");
    messages.confirm_password = isConfPassword(
      values.confirm_password,
      values.password
    );

    setErrorMessages({ ...messages });
    return Object.values(messages).every((message) => message === "");
  };

  // パスワード再設定処理
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const values = Object.fromEntries(data.entries());

    if (isValidate(values)) {
      console.log("ok");

      const value = { ...values };
      value.email = emailparam;

      console.log("values", value);
      //  Param Hoook
      setSearchParams({ email: "email" });

      try {
        const response = await auth.newPassword(value);
        console.log("response");
        // if (response.status === 200) {
        //   toast.success(
        //     "パスワードのリセットが完了しました",
        //     {
        //       position: toast.POSITION.TOP_CENTER,
        //     },
        //     2000
        //   );
        // }

        response.status == 200 ? navigate("/") : console.log("doesnt work");
      } catch (e) {
        console.log(e.response.data);
        // toast.error(
        //   "Please try again",
        //   {
        //     position: toast.POSITION.TOP_CENTER,
        //   },
        //   2000
        // );
      }
      navigate("/");
    }
  };

  return (
    <main>
      <article className="c-formMessage">
        <p className="c-formMessage__bold u-textAlign">
          ログインパスワードの再設定を行います。
          <br />
          再設定用のパスワードをご入力ください。
        </p>
      </article>

      <section className="p-signUp__form">
        <form onSubmit={handleSubmit}>
          <FormInput
            name="password"
            label="再設定パスワード"
            placeholder="パスワードを入力してください"
            required="true"
            type="password"
            errorMessage={errorMessages.password ? errorMessages.password : ""}
            autocomplete="off"
          />
          <FormInput
            name="confirm_password"
            label="パスワード確認"
            placeholder="もう一度パスワードを入力してください"
            required="true"
            type="password"
            errorMessage={
              errorMessages.confirm_password
                ? errorMessages.confirm_password
                : ""
            }
            autocomplete="off"
          />
          <MainButton text="パスワード再設定" color="blue" />
        </form>
      </section>
    </main>
  );
}
