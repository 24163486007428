import { useState, useEffect, useContext } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { SearchStore } from "../components/ui";
import { Stores, SignUpLink, LinkButton } from "../components/projects";
import { Contextdata } from "../../UserContext";
import { prefectures } from "../../utils/data";
import { getAuthToken } from "../../helpers/storage";
import drugStoreAPi from "../../apis/drugstore";


const initialValue = {
  prefecture: "",
  city: "",
  word: "",
  entry: false,
};

export default function SearchStores() {
  const { prescriptionData, setPrescriptionData } = useContext(Contextdata);
  // its is working

  // クエリ取得
  const [searchEntry, setSearchEntry] = useState(initialValue);
  const [searchParams] = useSearchParams();
  const prefecture = searchParams.get("prefecture");
  const city = searchParams.get("city");
  const word = searchParams.get("word");
  const token = getAuthToken();

  const navigate = useNavigate();
  // 検索した場合、値をセット
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get('searchAll') === 'true') {
      const values = {
        drugstore_name: '',
        city: ''
      }
      var response;
      
      response=drugStoreAPi.storeSearch(values, '')
      response.then((res)=>{
        setPrescriptionData(res.data);
      })
      setTimeout(()=>{
        navigate("/stores");
      },1000)
    }
    if (prefecture || city || word) {
      setSearchEntry({
        prefecture: prefecture,
        city: city,
        word: word,
        entry: true,
      });
    }
  }, [prefecture, city, word]);

  console.log("prescription data", prescriptionData);

  return (
    <main className="p-searchStores">
      <SearchStore />

      <section className="p-searchStores__result">
        {searchEntry.entry ? (
          <h2 className="p-searchStores__title">
            「{searchEntry.prefecture && <span>{searchEntry.prefecture}</span>}
            {searchEntry.city && <span>{searchEntry.city}</span>}
            {searchEntry.word && <span>{searchEntry.word}</span>}
            」<br />
            でのドラッグストア検索結果一覧
          </h2>
        ) : (
          <h2 className="p-searchStores__title">ドラッグストア一覧</h2>
        )}

        <ul className="p-searchStores__list">
          {prescriptionData.length === 0 ? (
            <div
              style={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              検索結果がありません。条件を変えてもう一度お試しください。
            </div>
          ) : (
            prescriptionData?.results.map((item, i) => {
              return <Stores item={item} key={i} />;
            })
          )}

          {/* {prescriptionData.map((item, i) => {
            return <Stores item={item} key={i} />;
          })} */}
        </ul>
      </section>

      {!token && <SignUpLink />}
      <LinkButton
        text="じぶん薬局へのお問い合わせ"
        url="/contact"
        color="blue"
      />
    </main>
  );
}
