import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { FormInput, FormSelect, MainButton } from "../components/projects";
import { isEmail, isEntered } from "../../utils/validation";
import contact from "../../apis/contact";
import { getAuthToken, getFromLocalStorage } from "../../helpers/storage";
import { userEdit } from "../../apis/userEdit";
import { useEffect } from "react";

const category = [
  "じぶん薬局について",
  "サイト・サービスの利用方法",
  "登録やログインに関するお問いわせ",
  "その他のお問い合わせ",
];

export default function Contactout() {
  // 入力バリデーション
  const [errorMessages, setErrorMessages] = useState({});
  const [data, setData] = useState();

  const token = "token" + " " + getAuthToken();
  const headers = { Authorization: token };

  var id = window.localStorage.getItem("id");

  const isValidate = (values) => {
    let messages = {};

    messages.contact_category = isEntered(
      "お問い合わせカテゴリ",
      values.contact_category,
      true
    );
    messages.email = isEmail(values.email);
    messages.name = isEntered("お名前", values.name, false);
    messages.contents_of_inquiry = isEntered(
      "お問い合わせ内容",
      values.contents_of_inquiry,
      false
    );

    setErrorMessages({ ...messages });
    return Object.values(messages).every((message) => message === "");
  };

  // 確認画面表示
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const values = Object.fromEntries(data.entries());

    if (isValidate(values)) {
      setFormValues(values);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setOpen((prevState) => !prevState);
    } else {
      window.scrollTo({ top: 200, behavior: "smooth" });
    }
    setData(values);
    // setOpen((prevState) => !prevState);
  };

  const getData = async () => {
    console.log("data", data);
    const response = await contact.contactApi(data);
    console.log("response", response);

    navigate("/thanks");
  };

  // 確認画面を閉じる
  const handleClickClose = () => {
    setOpen((prevState) => !prevState);
  };

  // メール送信処理
  const navigate = useNavigate();
  const sendEmail = () => {
    setOpen((prevState) => !prevState);
    getData();
  };

  return (
    <main className="p-contact">
      <section>
        <h2>じぶん薬局へのお問い合わせ</h2>
        <div className="p-contact__container">
          <form className="p-contact__input" onSubmit={handleSubmit}>
            <label>
              お問い合わせカテゴリ
              <span className="l-formInput__required">必須</span>
            </label>
            <FormSelect
              name="contact_category"
              options={category}
              initialValue="お問い合わせカテゴリを選択"
              errorMessage={
                errorMessages.contact_category
                  ? errorMessages.contact_category
                  : ""
              }
            />
            <FormInput
              name="name"
              label="お名前"
              placeholder="お名前を入力してください"
              required="true"
              type="name"
              errorMessage={errorMessages.name ? errorMessages.name : ""}
              autocomplete="name"
            />
            <FormInput
              name="email"
              label="メールアドレス"
              placeholder="メールアドレスを入力してください"
              required="true"
              type="email"
              errorMessage={errorMessages.email ? errorMessages.email : ""}
              autocomplete="email"
            />
            <div className="l-formInput__form">
              <label>
                お問い合わせ内容
                <span className="l-formInput__required">必須</span>
              </label>
              <textarea
                name="contents_of_inquiry"
                placeholder="お問い合わせ内容をご入力ください"
                className={
                  errorMessages.contents_of_inquiry
                    ? "c-textarea error"
                    : "c-textarea"
                }
              />
              {errorMessages.contents_of_inquiry && (
                <span className="l-formInput__errorMessage">
                  {errorMessages.contents_of_inquiry}
                </span>
              )}
            </div>
            <MainButton text="この内容で問い合わせる" color="blue" />
          </form>
        </div>
      </section>

      <section
        className={open ? "p-contact__confirm open" : "p-contact__confirm"}
      >
        <button className="p-contact__closeBtn" onClick={handleClickClose} />
        <h2>お問い合わせ内容確認</h2>
        <div className="p-contact__table">
          <dl>
            <div>
              <dt>お問い合わせカテゴリ</dt>
              {formValues.contact_category && (
                <dd>{formValues.contact_category}</dd>
              )}
            </div>
            <div>
              <dt>お名前</dt>
              {formValues.name && <dd>{formValues.name}</dd>}
            </div>
            <div>
              <dt>メールアドレス</dt>
              {formValues.email && <dd>{formValues.email}</dd>}
            </div>
            <div>
              <dt>お問い合わせ内容</dt>
              {formValues.contents_of_inquiry && (
                <dd>{formValues.contents_of_inquiry}</dd>
              )}
            </div>
          </dl>
          <MainButton
            text="この内容で問い合わせる"
            color="blue"
            onClick={sendEmail}
          />
        </div>
      </section>
    </main>
  );
}
