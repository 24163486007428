import { useLocation, useNavigate, useParams } from "react-router-dom";
import prescriptionAPI from "../../apis/sendprescription";

import { MypageTopMenu } from "../components/ui";
import { LinkButton } from "../components/projects";
import { useEffect, useState } from "react";
import { getAuthToken } from "../../helpers/storage";
import { getAgeFromValue } from "../../utils/hooks";
import Loader from "../components/ui/loader";

// ダミーデータ
const data = {
  date: "YYYY年 MM月 DD日",
  limit: "YYYY年 MM月 DD日",
  number: "XXXXXXXX",
  birthday: "YYYY年 MM月 DD日",
  name: "XXXXXX",
  gender: "女",
  hospital: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
  contact: "03-123-5678",
  doctore: "XXXXXXXXXXXXXXXXXXXXXXXX",
  detail: [
    "お薬の名前",
    "お薬の形（錠剤、カプセル剤など）",
    "お薬の量 1回あたりに飲む量",
    "1日に飲む回数",
    "飲むタイミング",
  ],
  change: true,
};

export default function Prescription() {
  const [prescriptionDataID, setPrescriptionDataID] = useState({
    contact: "",
    change_to_generic_drugs: "",
    time: "",
    isRed: false,
    prescription_date: "",
    insurer_number: "",
    name: "",
    birthday: "",
    gender: "",
    medical_institution_name: "",
    prescribing_physician: "",
    prescription_image: "",
  });
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [expiry, setExpiry] = useState("");

  // id取得
  const location = useLocation();
  const id = location.pathname.replace("/mypage/prescription/", "");
  const updateUrl = `/mypage/prescription/${id}/update`;

  const navigate = useNavigate();

  const token = "token" + " " + getAuthToken();
  const headers = { Authorization: token };

  const getData = async () => {
    getAuthToken() === null ? navigate("/") : console.log("work");
    try {
      const response = await prescriptionAPI.getprescriptionid(id, headers);
      console.log(response.data);
      setPrescriptionDataID(response.data);
      var presDate = response.data.prescription_date.split("-");

      var date = Number(presDate[2]);
      var month = Number(presDate[1]);
      var year = Number(presDate[0]);
      presDate[2] = String(date + 4);

      if (
        (presDate[2] > 30 &&
          month == 2 &&
          year % 4 != 0 &&
          year % 400 != 0 &&
          year % 100 != 0) ||
        (presDate[2] > 30 && month == 6) ||
        (presDate[2] > 30 && month == 9) ||
        (presDate[2] > 30 && month == 11)
      ) {
        presDate[1] = month + 1;
        presDate[2] = date % 10;
      } else if (
        (presDate[2] > 31 && month != 2) ||
        (presDate[2] > 31 && month != 6) ||
        (presDate[2] > 31 && month != 9) ||
        (presDate[2] > 31 && month != 11)
      ) {
        presDate[1] = month + 1;
        presDate[2] = date % 10;
      } else if (
        presDate[2] > 28 &&
        month == 2 &&
        year % 4 == 0 &&
        year % 400 == 0 &&
        year % 100 == 0
      ) {
        presDate[1] = month + 1;
        presDate[2] = date % 10;
      } else if (presDate[2] > 31 && month == 12) {
        presDate[0] = year + 1;
        presDate[1] = 1;
        presDate[2] = date % 10;
      }

      presDate = presDate.join("/");
      setExpiry(presDate);
    } catch(e) {
      console.log(e);
      setApiError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => getData(), []);

  console.log(prescriptionDataID);

  if (loading) return <Loader />;

  return apiError ? (
    <>Something went wrong!</>
  ) : (
    <main className="p-prescription">
      <MypageTopMenu />

      <section className="p-prescription__detail">
        <h2 className="c-sectionTitle c-mypageHeading">処方箋詳細</h2>
        <p className="p-prescription__message">
          <span>
            今回の処方箋にはアレルギーとなる薬剤が含まれている
            <br />
            可能性があります。処方時にはご注意ください。
          </span>
        </p>
        <dl>
          <div>
            <dt className="c-contentTitle">処方年月日</dt>
            <dd>
              {prescriptionDataID.prescription_date}
              <span>有効期限: &nbsp;{expiry}</span>
            </dd>
          </div>
          <div>
            <dt className="c-contentTitle">保険者番号</dt>
            <dd>{prescriptionDataID.insurer_number}</dd>
          </div>
          <div>
            <dt className="c-contentTitle">お名前</dt>
            <dd>{prescriptionDataID.name}</dd>
          </div>
          <div>
            <dt className="c-contentTitle">生年月日</dt>
            <dd>
              {prescriptionDataID.birthday} (
              {getAgeFromValue(prescriptionDataID.birthday)}歳)
            </dd>
          </div>
          <div>
            <dt className="c-contentTitle">性別</dt>
            <dd>{prescriptionDataID.gender == "male" ? "男" : "女性"}</dd>
          </div>
          <div>
            <dt className="c-contentTitle">医療機関名</dt>
            <dd>{prescriptionDataID.medical_institution_name}</dd>
          </div>
          <div>
            <dt className="c-contentTitle">連絡先</dt>
            <dd>{prescriptionDataID.contact}</dd>
          </div>
          <div>
            <dt className="c-contentTitle">処方担当医師</dt>
            <dd>{prescriptionDataID.prescribing_physician}</dd>
          </div>
          <div>
            <dt className="c-contentTitle">処方詳細</dt>
            {/* <dd>
              {Array.isArray(prescriptionDataID.prescription_details) ? (
                <ul>
                  {prescriptionDataID.prescription_details.map(
                    (item, index) => (
                      <li key={index}>{item}</li>
                    )
                  )}
                </ul>
              ) : (
                prescriptionDataID.prescription_details
              )}
            </dd> */}
            <dd>{prescriptionDataID.prescription_details}</dd>
          </div>
          <div>
            <dt className="c-contentTitle">ジェネリック医薬品への変更</dt>
            <dd>
              {prescriptionDataID.change_to_generic_drugs=="possible"
                ? "変更可"
                : "変更不可"}
            </dd>
          </div>
          <div>
            <dt className="c-contentTitle">分割調剤回数</dt>
            <dd>{prescriptionDataID.number_of_split_dispensing}回</dd>
          </div>

          <div>
            <dt className="c-contentTitle">処方箋画像</dt>
            <dd>
              <img src={prescriptionDataID.prescription_image} />
            </dd>
          </div>
        </dl>
        <LinkButton
          text="この処方箋を編集する"
          url={updateUrl}
          color="allBlue"
        />
      </section>
    </main>
  );
}
