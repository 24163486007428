import { Link } from "react-router-dom"
import { memo } from "react"

const LinkButton = memo((props) => {

  const { text, url, color } = props

  return (
    <div className={`c-linkButton ${color}`}>
      <Link to={url}>
        <span>{text}</span>
      </Link>
    </div>
  )
})

export default LinkButton