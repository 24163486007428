import { memo, useState } from 'react'
import moment from 'moment'

const FormDateInput = memo(props => {
  const {
    label,
    required,
    name,
    errorMessage,
    initialValue,
    value,
    isControlled,
  } = props
  // const [ value, setValue ] = useState(initialValue)

  // const handleChange = (e) => {
  //   setValue(e.target.value)
  // }

  var data = ''
  if (props.name === 'birthday' && props.hasOwnProperty('item'))
    data = props.item.birthday
  else if (props.name === 'prescription_date' && props.hasOwnProperty('item'))
    data = props.item.prescription_date
  else if (props.name === 'birthday' && props.hasOwnProperty('title'))
    data = props.title.dob

  if (props.hasOwnProperty('title')) {
    if (
      props.name === 'delivary1' &&
      props.title.hasOwnProperty('patient_insurance') &&
      props.title.patient_insurance.length !== 0
    )
      data = props.title.patient_insurance[0].date_of_issuance
  }

 

  return (
    <div className='l-formInput__date'>
      <label>
        {label}
        {required && <span className='l-formInput__required'>必須</span>}
        {!required && <span className='l-formInput__any'>任意</span>}
      </label>
      {label !== "処方年月日"? 
      <input
        type='date'
        name={name}
        max={moment().format("YYYY-MM-DD")}
        className={errorMessage ? 'c-inputBorder error' : 'c-inputBorder'}
        defaultValue={data}
       
        // onChange={handleChange}
        // {...(isControlled && { value })}
      />: <input
      type='date'
      name={name}
      min={moment().format("YYYY-MM-DD")}
      className={errorMessage ? 'c-inputBorder error' : 'c-inputBorder'}
      defaultValue={data}
     
      // onChange={handleChange}
      // {...(isControlled && { value })}
    />}
      {errorMessage && (
        <span className='l-formInput__errorMessage'>{errorMessage}</span>
      )}
    </div>
  )
})

export default FormDateInput
