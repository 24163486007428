import { useState, memo, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

import { FormInput, MainButton, LinkText } from "../projects";
import { isEmail, isPassword } from "../../../utils/validation";
import logInApi from "../../../apis/login";
import {
  getAuthToken,
  setID,
  setLocalStorage,
  setMail,
} from "../../../helpers/storage";
import testObject from "../../../apis/test";

import LineAccessToken from "../../../apis/accesstoken";
import LineLoginbackend from "../../../apis/linelogin";
import { regex } from "../../../utils/validation/isEmail";

const SignIn = memo((props) => {
  // 入力バリデーション
  const [errorMessages, setErrorMessages] = useState({});

  const [linecounter, setlinecounter] = useState(0);
  const [lineLoginEmail, setlineLoginEmail] = useState(false);
  const [lineerrorMessages, setlineerrorMessages] = useState("");

  const [searchParams] = useSearchParams();

  const lineLogin = () => {
    setlineLoginEmail(true);

    // window.location.href=`https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1657234805&redirect_uri=http://localhost:3000/&state=${randomString(10,"dsewvefew")}&scope=profile%20openid`
    return null;
  };


  const isValidate = (values) => {
    let messages = {};

    messages.email = isEmail(values.email);
    messages.password = isPassword(values.password, "ログインパスワード");

    setErrorMessages({ ...messages });
    return Object.values(messages).every((message) => message === "");
  };

  // ログイン処理
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const values = Object.fromEntries(data.entries());
    // document.getElementById("error-message").innerHTML="";
    // document.getElementById("error-message").innerHTML = "登録されていないメールアドレスです。";
    if (isValidate(values)) {
      try {
        const response = await logInApi.logIn(values);
        console.log("ok");
        if (response.status === 200) {
          const urlRedirectAfterLogin = searchParams.get("redirectAfterLogin");
          urlRedirectAfterLogin
            ? navigate(urlRedirectAfterLogin)
            : navigate("/mypage");
        }
        console.log(response.data.user_token);
        const id = response.data.id;
        setLocalStorage({ token: response.data.user_token });
        setID(id);
        window.localStorage.setItem("name", response.data.email);
        console.log(response);
      } catch (e) {
        console.log("Error");
        document.getElementById("error-message").innerHTML =
          e.response.data.hasOwnProperty("password")
            ? e.response.data.password
            : e.response.data;
      }
      const authentification = getAuthToken();
      console.log("authentification", authentification);
    }
  };

  const handleLineform = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const values = Object.fromEntries(data.entries());
    if (!isEmail(values.email)) {
      window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1660823504&redirect_uri=${window.location.origin}&state=${values.email}&scope=openid%20profile`;
    } else {
      if (lineLoginEmail && linecounter > 1) {
        setlineerrorMessages(isEmail(values.email));
      }
    }
  };

  return (
    <section className="l-signIn">
      {errorMessages.email || errorMessages.password ? (
        <small className="l-signIn__error">
          ご入力いただいたログインID・パスワードに誤りがあります。
          <br />
          内容をご確認の上、再度ご入力ください。
        </small>
      ) : (
        ""
      )}
      <h2>
        <span>
          使い方を知っている人は
          <br />
          今すぐログイン！
        </span>
      </h2>
      <form className="l-formInput" onSubmit={handleSubmit}>
        <FormInput
          name="email"
          label="ログインID（メールアドレス）"
          placeholder="ログインID（メールアドレス）を入力してください"
          required="true"
          type="email"
          errorMessage={errorMessages.email ? errorMessages.email : ""}
          autocomplete="email"
        />
        <FormInput
          name="password"
          label="ログインパスワード"
          placeholder="ログインパスワードを入力してください"
          required="true"
          type="password"
          errorMessage={errorMessages.password ? errorMessages.password : ""}
          autocomplete="off"
        />
        <p id="error-message" style={{ color: "#faa04d" }}></p>
        <MainButton text="ログインする" color="blue" />
      </form>
      <span className="p-home__line">
        LINEアカウント連携でご登録された方はこちらからログイン
      </span>
      <form className="l-formInput" onSubmit={handleLineform}>
        {lineLoginEmail && (
        <div className="l-formInput">
          <FormInput
            name="email"
            label="LINE登録メールアドレス"
            placeholder="ログインID（メールアドレス）を入力してください"
            type="email"
            autocomplete="email"
            errorMessage={lineerrorMessages ? lineerrorMessages : ""}
            required="true"
          />
        </div>
        )}
        <MainButton
          text="LINEアカウントを連携してログインする"
          color="green"
          onClick={() => {
            setlinecounter(linecounter + 1);
            lineLogin();
          }}
        />
      </form>

      <LinkText url="/password" text="ログインID・パスワードを忘れた方へ" />
    </section>
  );
});

export default SignIn;

function randomString(length, chars) {
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}
