// パスワード忘れのメール入力ページ
import { useState } from "react";
import { Navigate } from "react-big-calendar";
import { useNavigate } from "react-router-dom";
import auth from "../../apis/forgotpassword";
import { isEmail } from "../../utils/validation";

import { FormInput } from "../components/projects";
import { MainButton } from "../components/projects";

export default function Password() {
  // 入力バリデーション
  const [errorMessages, setErrorMessages] = useState({});
  const isValidate = (values) => {
    let messages = {};

    messages.email = isEmail(values.email);

    setErrorMessages({ ...messages });
    return Object.values(messages).every((message) => message === "");
  };
  const navigate = useNavigate();
  // メール送信処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const values = Object.fromEntries(data.entries());

    if (isValidate(values)) {
      try {
        const response = await auth.frgtPassword(values);
        console.log("response");
        console.log(response.status);
      } catch (e) {
        console.log("error", e);
      }
    }
  };

  return (
    <main>
      <article className="c-formMessage">
        <p className="c-formMessage__bold">
          パスワードをお忘れの方は、以下のフォームに
          <br />
          ご登録のメールアドレスをご入力ください。
        </p>
        <p>
          その後、パスワード再設定用メールが届きますので、
          メール内の再設定用リンクを押下し、パスワードの
          再設定をおこなってください。
        </p>
      </article>

      <section className="p-signUp__form">
        <form onSubmit={handleSubmit}>
          <FormInput
            name="email"
            label="ご登録ID（メールアドレス）を入力してください"
            placeholder="ご登録ID（メールアドレス）を入力してください"
            required="true"
            type="email"
            errorMessage={errorMessages.email ? errorMessages.email : ""}
            autocomplete="email"
          />
          <MainButton text="メールを送信" color="blue" />
        </form>
      </section>
    </main>
  );
}
