import { useNavigate, useParams } from "react-router-dom";

import { MypageTopMenu } from "../components/ui";
import {
  ItemsList,
  FormMemoInput,
  LinkText,
  MemoMessage,
} from "../components/projects";
import { useEffect, useState } from "react";
import userMemo from "../../apis/usermemo";
import { getAuthToken, getFromLocalStorage } from "../../helpers/storage";
import MemoDisplay from "../components/projects/MemoDisplay";
import moment from "moment";
import FormMemoField from "../components/projects/FormMemoField";
import Loader from "../components/ui/loader";

// ダミーデータ
const item1 = [
  {
    title: "かかりつけ薬局",
    text: "kio",
  },
  {
    title: "予約申請日",
    text: "2021/12/21 13:00",
  },
  {
    title: "申請する処方箋",
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "処方した医師の名前",
    name: "お薬の名前。お薬の名前。お薬の名前。お薬の名前。",
    limit: "YYYY年 MM月 DD日",
    red: true,
  },
  {
    title: "リモート服薬",
    text: "希望する",
  },
  {
    title: "指導予約日",
    text: "2021/12/21 13:00",
  },
  {
    title: "服薬指導担当薬剤師",
    text: "薬剤師名",
  },
];

const message = [
  {
    date: "2021/12/22",
    time: "14:55",
    text: "担当薬剤師からの服薬指導メッセージ。担当薬剤師からの服薬指導メッセージ。担当薬剤師からの服薬指導メッセージ。担当薬剤師からの服薬指導メッセージ。担当薬剤師からの服薬指導メッセージ。担当薬剤師からの",
  },
];

export default function MemoUpdate() {
  // メモ更新
  const navigate = useNavigate();
  const handleUpdate = () => {
    // 更新処理
    console.log("ok");
    navigate("/mypage/memo");
  };

  const [memomsg, setMemomsg] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [enable, setEnable] = useState(false);

  const token = "token" + " " + getFromLocalStorage("token");
  const headers = { Authorization: token };

  console.log("headers", headers);

  let id = useParams();

  const getData = async () => {
    if (getAuthToken() === null) {
      navigate("/");
    }

    const response = await userMemo.getDetailMemo(id.id, headers);
    setMemomsg(response.data);
    setIsLoading(false);
  };

  // useEffect(
  //   getAuthToken() === null ? navigate("/") : setEnable(true),
  //   []
  // );
  useEffect(() => getData(), [enable]);

  if (isLoading) return <Loader />;

  console.log("memomsg", memomsg);
  console.log(
    "memomsg prescriptions",
    memomsg?.prescription_reservation?.prescription
  );

  var resrvdate =
    memomsg?.prescription_reservation?.reservation_scheduled_time.slice(0, 10);
  console.log("resrvdate", resrvdate);

  var resrvtime =
    memomsg?.prescription_reservation?.reservation_scheduled_time.slice(11, 16);
  console.log("resrv", resrvtime);

  const item = [
    {
      title: "かかりつけ薬局",
      text: memomsg?.pharmacy_name,
      prescription: "",
    },
    {
      title: "予約申請日",
      text: memomsg?.prescription_reservation?.reservation_scheduled_time
        ? moment(
            memomsg?.prescription_reservation?.reservation_scheduled_time
          ).format("YYYY年MM月DD日h:mm")
        : " ", //moment(memomsg?.created_at).format("YYYY-MM-DD")
      prescription: "",
    },
    {
      title: "申請する処方箋",

      prescription: memomsg?.prescription_reservation?.prescription,

      red: true,
    },
    {
      title: "リモート服薬",
      text:
        (memomsg?.prescription_reservation?.is_remote_accepted
          ? memomsg?.prescription_reservation?.is_remote_accepted
          : " ") === true
          ? "はい"
          : "いいえ",
      prescription: "",
    },
    {
      title: "指導予約日",
      text: memomsg?.prescription_reservation?.guidance_scheduled_time
        ? moment(
            memomsg?.prescription_reservation?.guidance_scheduled_time
          ).format("YYYY年MM月DD日h:mm")
        : " ",
      prescription: "",
    },
    {
      title: "服薬指導担当薬剤師",
      text: memomsg?.pharmacist,
      prescription: "",
    },
  ];
  // console.log("incoming Data", item);
  // const data = item?.filter((d) => d.text != " ");
  // const dataSet = data?.filter((d) => d.text != "いいえ");
  // const dataMap = dataSet?.filter((d) => d.prescription != undefined);

  return (
    <main className="p-memo">
      <MypageTopMenu />

      <section className="p-memo__update">
        <h2 className="c-sectionTitle c-mypageHeading">服薬指導メモ</h2>
        <dl>
          {memomsg.prescription_reservation == null
            ? []
            : item?.map((value, i) => {
                return <MemoDisplay key={i} item={value} />;
              })}
        </dl>
        {/* {message && (
          <MemoMessage title="服薬指導メッセージ" message={message} />
        )} */}

        <FormMemoField onSubmit={handleUpdate} item={memomsg} />

        <LinkText
          text="服薬指導メモ一覧に戻る"
          url="/mypage/memo"
          className="underline"
        />
      </section>
    </main>
  );
}
