// トップページ
import {
  NewsList,
  LinkButton,
  TipsList,
  LinkText,
  SignUpLink,
} from "../components/projects";
import { SearchStore, HowTo, SignIn } from "../components/ui";
import { useEffect, useState } from "react";
import noticeAPI from "../../apis/notice";
import MedList from "../components/projects/MedList";
import { getAuthToken, setID, setLocalStorage } from "../../helpers/storage";
import medicineTips from "../../apis/medicinetips";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Pagination } from "antd";
import Loader from "../components/ui/loader";
import LineLoginbackend from "../../apis/linelogin";
import LineAccessToken from "../../apis/accesstoken";


const defaultPageSize = 5;

export default function Home() {
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [medicine, setMedicine] = useState([]);
  const [noticePage, setNoticePage] = useState({ pages: 1 });
  const [medicinePage, setMedicinePage] = useState({ pages: 1 });
  const [medicineCount, setMedicineCount] = useState(null);
  const [registeredNotice, setRegisteredNotice] = useState([]);
  let [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const state = searchParams.get("state");

  const onMedicine = (page) => {
    setMedicinePage({ pages: page });
  };

  const navigate = useNavigate();

  const isSignOut = false;

  const todaysDate = moment(moment().toDate()).format("YYYY-MM-DD");

  const getData = async () => {
    if (getAuthToken()) {
      navigate("/mypage");
    }
    // {APi calls}
    // const response = await noticeAPI.notice(noticePage.pages);
    const response2 = await medicineTips.getData(medicinePage.pages);
    // setNoticeCount(response.data.count)
    setMedicine(response2.data.results);
    setMedicineCount(response2.data.count);
    // setNews(response.data);
    setIsLoading(false);
  };

  useEffect(() => getData(), [medicinePage]);

  const getNewsData = async () => {
    const res1 = await noticeAPI.notice(noticePage.pages);
    const len = Math.ceil(res1?.data?.count / 10);
    const getNewsPromises = [];
    for (let i = 2; i <= len; i++) {
      const promise = noticeAPI.notice(i);
      getNewsPromises.push(promise);
    }
    Promise.all(getNewsPromises).then((responses) => {
      setRegisteredNotice(
        responses
          .reduce((acc, currentResponse) => {
            return acc.concat(currentResponse?.data?.results ?? []);
          }, res1?.data?.results ?? [])
          .filter(
            (item) =>
              [1, 2].includes(item.delivery_target.delivery_target_choice) &&
              moment(todaysDate).isSameOrAfter(item.deliver_start_time) &&
              moment(todaysDate).isSameOrBefore(item.deliver_end_time)
          )
      );
    });
  };

  useEffect(() => {
    if (getAuthToken()) {
      navigate("/mypage");
    }
    getNewsData();
  }, []);

  useEffect(() => {
    const from = (noticePage.pages - 1) * defaultPageSize;
    const to = from + defaultPageSize;
    setNews(registeredNotice.slice(from, to));
  }, [registeredNotice, noticePage]);

  /**
   * handle login system by LINE's Email
   */
  useEffect(() => {
    if (code && code !== null) {
      LineAccessToken.getAccesstokenLine(code)
        .then((response) => {
          const body = {
            token: response.data.access_token,
            email: decodeURIComponent(state),
          };
          LineLoginbackend.lineLoginuser(body)
            .then((res) => {
              res.status === 200 ? navigate("/mypage") : navigate("/");
              const id = res.data.id;
              setLocalStorage({ token: res.data.user_token });
              setID(id);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => console.error(error));
    }
  }, [code, navigate, state]);

  const onNotice = (page) => {
    setNoticePage({
      pages: page,
    });
  };

  if (isLoading) return <Loader />;

  return (
    <main className="p-home">
      <HowTo />

      {isSignOut && (
        <>
          <p className="p-home__signout">ログアウトしました</p>
          <article className="c-formMessage">
            <p>
              再ログインするには、以下のログインフォームから
              <br />
              お願いいたします。
            </p>
          </article>
        </>
      )}

      <SignIn />
      <SignUpLink />

      <section className="p-home__news">
        <h2 className="c-sectionTitle">じぶん薬局からのお知らせ</h2>
        <dl>
          {news &&
            news.map((item, i) => {
              return <NewsList key={i} to={`/news/${item.id}`} item={item} />;
            })}
        </dl>
      </section>
      <center>
        <Pagination
          current={noticePage.pages}
          total={registeredNotice.length}
          onChange={onNotice}
          pageSize={defaultPageSize}
          showSizeChanger={false}
          size="medium"
        />
      </center>
      <br />
      <br />

      <LinkButton
        text="じぶん薬局へのお問い合わせ"
        url="/contactout"
        color="blue"
      />

      <section className="p-home__tips" style={{ color: "red" }}>
        <h2 className="c-sectionTitle">おくすりTips</h2>
        {medicine.map((item, i) => {
          return <MedList key={i} to={`/mymedicine/${item.id}`} item={item} />;
        })}
        <TipsList number={4} />
        <LinkText url="/tips" text="おくすりTipsのコンテンツ一覧をみる" />
      </section>

      <center>
        <Pagination
          total={medicineCount != null ? medicineCount : ""}
          onChange={onMedicine}
          pageSize={10}
          showSizeChanger={false}
          size="medium"
        />
      </center>

      <br />
      <br />

      <SearchStore />
    </main>
  );
}
