import { memo, useCallback, useMemo, useState } from 'react'

const monthsList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

// 閏年
const isLeapYear = year => {
  if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
    return true
  } else {
    return false
  }
}

const date = (year, month) => {
  const lists = []
  let datesOfYear

  datesOfYear = [
    31,
    isLeapYear(year) ? 29 : 30,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ]

  for (let i = 1; i <= datesOfYear[month - 1]; i++) lists.push(i)

  return lists
}

const FormOrigDateInput = memo(props => {
  const { label, required, errorMessage, defaultValue } = props

  // 今日
  const today = new Date()
  const thisYear = today.getFullYear()
  const thisMonth = today.getMonth() + 1
  const thisDate = today.getDate()
  // 日付
  const yearsList = Array.from({ length: 99 }).map((_, i) => thisYear - i)
  // [thisYear, thisYear - 1, thisYear - 2, thisYear - 3, thisYear - 4]

  const [startDatesList, setStartDatesList] = useState(
    date(thisYear, thisMonth)
  )
  const [values, setValues] = useState({
    year: thisYear,
    month: thisMonth,
    date: thisDate,
  })

  const [defaultYear, defaultMonth, defaultDate] = useMemo(
    () => (defaultValue ? defaultValue.split('-') : []),
    [defaultValue]
  )
  console.log(defaultYear, defaultMonth, defaultDate)

  const handleInputChange = useCallback(
    e => {
      const { name, value } = e.target
      // 各月の日数変更
      if (name === 'year') {
        setStartDatesList(date(value, values.month))
      } else if (name === 'month') {
        setStartDatesList(date(values.year, value))
      }

      setValues({
        ...values,
        [name]: value,
      })
    },
    [values]
  )

  return (
    <div className='l-formInput__form border'>
      <label>
        {label}
        {required && <span className='l-formInput__required'>必須</span>}
        {!required && <span className='l-formInput__any'>任意</span>}
      </label>
      <div className='c-selectBox__orig'>
        <div className='c-selectBox__container year'>
          <select
            key={defaultYear}
            name='year'
            placeholder='西暦'
            defaultValue={defaultYear}
            onChange={handleInputChange}
            className={`${errorMessage ? 'error' : ''}`}>
            {/* <option value=''>西暦</option> */}
            {yearsList.map((year, index) => {
              return (
                <option key={index} value={year}>
                  {year}
                </option>
              )
            })}
          </select>
        </div>
        <div className='c-selectBox__container date'>
          <select
            key={defaultMonth}
            name='month'
            onChange={handleInputChange}
            defaultValue={defaultMonth}
            placeholder='月'
            className={`${errorMessage ? 'error' : ''}`}>
            {/* <option value=''>月</option> */}
            {monthsList.map((month, index) => {
              return (
                <option key={index} value={month}>
                  {month}月
                </option>
              )
            })}
          </select>
        </div>
        <div className='c-selectBox__container date'>
          <select
            key={defaultDate}
            name='date'
            placeholder='日'
            defaultValue={defaultDate}
            onChange={handleInputChange}
            className={`${errorMessage ? 'error' : ''}`}>
            {/* <option value=''>日</option> */}
            {startDatesList.map((date, index) => {
              return (
                <option key={index} value={date}>
                  {date}日
                </option>
              )
            })}
          </select>
        </div>
      </div>
      {errorMessage && (
        <span className='l-formInput__errorMessage'>{errorMessage}</span>
      )}
    </div>
  )
})

export default FormOrigDateInput
