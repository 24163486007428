import axios from "axios";

const lineLoginuser = (data) =>
  axios.post("https://dev-api-pharmacy.automagicjapan.tech/api/line/auth/", data);

const LineLoginbackend = {
  lineLoginuser,
};

export default LineLoginbackend;
