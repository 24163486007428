import { Link, useLocation, useNavigate } from "react-router-dom";
import { Link as Scroll } from "react-scroll";

const footerNavs1 = [
  // {
  //   name: "マイページログイン",
  //   url: "/",
  // },
  {
    name: "新規利用登録",
    url: "/signUp",
  },
];

const footerNavs2 = [
  {
    name: "お知らせ",
    url: "/news",
  },
  {
    name: "おくすりTips",
    url: "/tips",
  },
  {
    name: "お問い合わせ",
    url: "/contactout",
  },
  {
    name: "利用規約",
    url: "/privacy-policy",
  },
];

const memberNavs1 = [
  {
    name: "処方箋履歴",
    url: "/mypage/prescription",
  },
  {
    name: "通院カレンダー",
    url: "/mypage/calendar",
  },
  {
    name: "服薬指導メモ",
    url: "/mypage/memo",
  },
  {
    name: "処方箋受け取り予約",
    url: "/mypage/reserve",
  },
];

const memberNavs2 = [
  {
    name: "ユーザー情報の更新・編集",
    url: "/mypage/user",
  },
  {
    name: "お知らせ",
    url: "/mypage/news",
  },
];

const memberNavs3 = [
  {
    name: "おくすりTips",
    url: "/tips",
  },
  {
    name: "お問い合わせ",
    url: "/contact",
  },
  {
    name: "利用規約",
    url: "/privacy-policy",
  },
];

export default function Footer() {
  const location = useLocation();
  const navigate = useNavigate();

  // ログアウト処理
  const handleSignOut = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <footer className="l-footer">
      <Scroll to="root" smooth={true} className="l-footer__btn"></Scroll>
      {location.pathname.includes("mypage") ? (
        <>
          <div className="l-footer__signOut">
            <div className="l-footer__logo">
              <img src="/assets/img/logo.png" alt="じぶん薬局" />
            </div>
            <button onClick={handleSignOut}>ログアウト</button>
          </div>
          <nav className="l-footer__mypage">
            <div className="l-footer__book">
              <p>おくすり手帳</p>
              <ul>
                {memberNavs1.map((nav, i) => {
                  return (
                    <li key={i}>
                      <Link to={nav.url} className="l-footer__nav">
                        {nav.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <Link to="/mypage/reserve" className="l-footer__nav">
              遠隔服薬指導
            </Link>
            <Link to="/mypage/medicine" className="l-footer__nav">
              OTC（薬局で購入できる医薬品）/ 医療用医薬品検索
            </Link>
            <ul className="l-footer__user">
              {memberNavs2.map((nav, i) => {
                return (
                  <li key={i}>
                    <Link to={nav.url} className="l-footer__nav">
                      {nav.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
            <ul className="l-footer__user">
              {memberNavs3.map((nav, i) => {
                return (
                  <li key={i}>
                    <Link to={nav.url} className="l-footer__nav">
                      {nav.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </>
      ) : (
        <>
          <div className="l-footer__logo">
            <img src="/assets/img/logo.png" alt="じぶん薬局" />
          </div>
          <nav>
            <ul>
              {/* <li>
                <Link
                  to="/"
                  onClick={() => {
                    localStorage.removeItem("token");
                  }}
                >
                  ログアウト
                </Link>
              </li> */}
              {footerNavs1.map((nav, i) => {
                return (
                  <li key={i}>
                    <Link to={nav.url} className="l-footer__nav">
                      {nav.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
            <ul>
              {footerNavs2.map((nav, i) => {
                return (
                  <li key={i}>
                    <Link to={nav.url} className="l-footer__nav">
                      {nav.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </>
      )}

      <small>&copy; 2021 Orthrus Corporation. All Rights Reserved.</small>
    </footer>
  );
}
