import { memo } from "react";
import { LinkButton } from ".";

const SignUpLink = memo(() => {
  return (
    <article className="c-signUpLink">
      <p>ご利用には無料会員登録が必要です。まだの方はこちらから！</p>
      <LinkButton
        text="「じぶん薬局」に会員登録する"
        url="/signUp"
        color="red"
      />
    </article>
  );
});

export default SignUpLink;
