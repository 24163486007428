import { useNavigate } from "react-router-dom";

import { MypageTopMenu } from "../components/ui";
import { FormMemoInput, LinkText } from "../components/projects";
import { getAuthToken } from "../../helpers/storage";
import { useEffect } from "react";
import FormMemoField from "../components/projects/FormMemoField";

export default function MemoInput() {
  // 新規メモ登録
  const navigate = useNavigate();
  const handleCreate = () => {
    // 新規登録処理
    console.log("ok");
    navigate("/mypage/memo");
    //window.location.reload();
  };

  useEffect(() => {
    getAuthToken() === null ? navigate("/") : console.log("works fine");
  }, []);

  return (
    <main>
      <MypageTopMenu />

      <section className="p-memo__new">
        <h2 className="c-sectionTitle c-mypageHeading">服薬指導メモ</h2>
        <FormMemoInput onSubmit={handleCreate} />
        <LinkText
          text="服薬指導メモ一覧に戻る"
          url="/mypage/memo"
          className="underline"
        />
      </section>
    </main>
  );
}
