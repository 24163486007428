import axios from "axios";

const userMemoAPi = (data, headers) =>
  axios.post("https://dev-api-pharmacy.automagicjapan.tech/api/user_memo_api/", data, {
    headers: headers,
  });

const getMemoApi = (headers, page) =>
  axios.get(`https://dev-api-pharmacy.automagicjapan.tech/api/user_memo_api/?page=${page}`, {
    headers: headers,
  });

const getDetailMemo = (id, headers) =>
  axios.get(`https://dev-api-pharmacy.automagicjapan.tech/api/user_memo_api/${id}`, {
    headers: headers,
  });

const memoMessage = (id, data, headers) =>
  axios.put(`https://dev-api-pharmacy.automagicjapan.tech/api/user_memo_api/${id}/`, data, {
    headers: headers,
  });

const userMemo = {
  userMemoAPi,
  getMemoApi,
  getDetailMemo,
  memoMessage,
};

export default userMemo;
