// ヘッダー
import { useLocation, Link } from "react-router-dom";
import { useEffect, useState } from "react";

import { getAuthToken } from "../../../helpers/storage";
import { userEdit } from "../../../apis/userEdit";

const initial = {
  title: true,
  text: "",
  img: "/assets/img/header-dammy.jpg",
};

export default function Header() {
  const path = window.location.pathname;
  const location = useLocation();

  const [user, setUser] = useState("");

  var name = "";

  if (path != "/" && user != "") {
    if (user.includes("@")) {
      name = user;
    } else {
      name = user.split(" ");

      name.reverse();

      name = name.join(" ");
    }
  }

  const [item, setItem] = useState(initial);
  useEffect(() => {
    if (location.pathname.includes("news")) {
      setItem((prevState) => ({
        ...prevState,
        text: "じぶん薬局からのお知らせ",
      }));
    } else if (location.pathname.includes("tips")) {
      setItem((prevState) => ({
        ...prevState,
        text: "おくすりTips",
        img: "/assets/img/header-dammy01.jpg",
      }));
    } else if (location.pathname.includes("stores")) {
      setItem({
        title: false,
        text: "",
        img: "/assets/img/searchStore-dammy.jpg",
      });
    } else if (location.pathname.includes("mypage")) {
      setItem({
        title: false,
        text: "",
        img: "/assets/img/mypage-dammy.jpg",
      });
    } else {
      setItem(initial);
    }

    const token = "token" + " " + getAuthToken();
    const patient_id = window.localStorage.getItem("id");

    const headers = {
      Authorization: token,
    };

    console.log(patient_id)

    var response = userEdit.userDetails(headers, patient_id);
    response.then((res) => {
      setUser(res.data.name);
    });
  }, [location.pathname]);

  const [userName, setUserName] = useState("");

  // useEffect(()=>{

  //   var response;
  //      response=userResObj.userData();
  //      response.then((res)=>{
  //       console.log(res.data.patients[0].name);
  //       setUserName(res.data.patients[0].name);
  //      })
  // })

  return (
    <header className="l-header">
      <h1 className="l-header__logo">
        <Link to={path == "/" ? "/" : "/mypage"}>
          <img src="/assets/img/logo.png" alt="じぶん薬局" className="logo-img"/>
        </Link>
      </h1>
      <div className="l-header__img">
        <img src={item.img} alt="広告" />
        {item.text && <p className="l-header__title">{item.text}</p>}
        {item.title && (
          <h1 className="l-header__text">
            あなたの健康を管理する電子版「おくすり手帳」
          </h1>
        )}
      </div>
      {location.pathname.includes("/mypage") && (
        <span className="l-header__user">ようこそ {name} さん</span>
      )}
    </header>
  );
}
