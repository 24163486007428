import axios from "axios";

const notice = (page) =>
  axios.get("https://dev-api-pharmacy.automagicjapan.tech/api/notice_api/", {
    params: { page },
  });

const noticeid = (id) =>
  axios.get(`https://dev-api-pharmacy.automagicjapan.tech/api/notice_api/${id}/`);

const noticeAPI = {
  notice,
  noticeid,
};
export default noticeAPI;
