import { memo, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Contextdata } from "../../../UserContext";

const Stores = memo((props) => {
  // const [prescriptionData] = useContext(Contextdata);
  // console.log(prescriptionData);
  console.log(props);
  const renderStoreAddress = (item) => {
    return (
      <div>
        {item.postal_code && <div>〒{item.postal_code}</div>}
        <div>
          {item?.prefectures?.prefecture_name_jp || ""} {item?.city || ""}{" "}
          {item?.address || ""}
        </div>
      </div>
    );
  };
  return (
    <li className="c-stores">
      <dl>
        <dt className="c-stores__shop">
          {props.item.drugstore_name ?? ""}
        </dt>
        <dd>
          <dl className="c-stores__detail">
            <dt className="c-stores__bold">住所</dt>
            <dd>{renderStoreAddress(props.item)}</dd>
          </dl>
          <dl className="c-stores__detail">
            <dt className="c-stores__bold">営業時間</dt>
            <dd>
              {props.item.time_raw == null ? "":props.item.time_raw}
            </dd>
            {/* <dd>
              《平日》
              {props.item.time1_start?(props.item.time1_start +"-"+
                props.item.time1_end) : ""}
            </dd>
            <dd>
              《土・日・祝日》
              {props.item.time2_start?props.item.time2_start +"-"+
                props.item.time2_end: ""}
            </dd> */}
          </dl>
          <dl className="c-stores__detail">
            <dt className="c-stores__bold">取り扱い</dt>
            <div className="c-stores__note">
              {
                props.item.handling_classification ? props.item.handling_classification: ""
              }
            </div>
          </dl>
          <dl className="c-stores__detail">
            <div className="c-stores__contact">
              <dt className="c-stores__bold">電話番号 &#058; </dt>
              <dd style={{minWidth: "100px"}}>{props.item.telephone_number ? props.item.telephone_number: ""}</dd>
              <dt className="c-stores__bold">FAX &#058; </dt>
              <dd>{props.item.fax ? props.item.fax: ""}</dd>
            </div>
          </dl>
        </dd>
      </dl>
      <Link to={`/stores/${props.item.id}`}>
        この薬局の詳細をみる
      </Link>
    </li>
  );
});

export default Stores;
