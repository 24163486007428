export const regex =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

// /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/

export const isEmail = (value) => {
  if (!value) {
    return "メールアドレスは必須入力項目です。";
  } else if (!regex.test(value)) {
    return "メールアドレスの形式が不正です。";
  } else {
    return "";
  }
};
