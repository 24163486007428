import { useContext, useState, useEffect, useCallback, useRef } from "react";
import { todayDate } from "../../utils/data";
import { useNavigate, useParams } from "react-router-dom";
import { MypageTopMenu } from "../components/ui";
import {
  FormDateInput,
  FormBorderInput,
  FormRadio,
  FormSelect,
  MainButton,
  LinkButton,
  LinkText,
} from "../components/projects";

import { isEntered, isPhoneNumber } from "../../utils/validation";
import prescriptionAPI from "../../apis/sendprescription";
import { getID, getAuthToken } from "../../helpers/storage";
import CircularProgress from "@mui/material/CircularProgress";
import { isNumeric } from 'validator';

// 分割の回数.
const times = () => {
  let num = [];
  for (let i = 0; i <= 10; i++) {
    num.push(i);
  }
  return num;
};
const number = times();

export default function PrescriptionInput() {
  var data = new FormData();

  const [values, setValues] = useState([]);
  const [isOcrLoading, setIsOcrLoading] = useState(false);
  const [ocrCheck,setOcrCheck]=useState(true)
  const [patientVal,setPatientVal]=useState({change_to_generic_drugs: "", gender: ""})
  const [checkDefaultImg,setCheckDefaultImg]=useState(false)
  const [ImgName,setImgName]=useState("")
  const [responseData,setResponseDataAdarsh]=useState([])
  const [isLoading,setIsLoading]=useState(false)



  const ocrInputRef = useRef(null)

  const { id: presId } = useParams();

  // 性別
  var genderRadio = [
    {
      label: "男",
      value: "male",
      initial: true,
    },
    {
      label: "女",
      value: "female",
      initial: false,
    },
  ];

  // ジェネリック医薬品への変更
  var changeRadio = [
    {
      label: "可",
      value: "possible",
      initial: true,
    },
    {
      label: "不可",
      value: "impossible",
      initial: false,
    },
  ];

  // 今日
  const initialValue = todayDate;

  // 日付変換
  const getDateFromValue = (value) => {
    const d = new Date(value);
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const date = d.getDate();

    return year + "年" + month + "月" + date + "日";
  };

  const token = "token " + getAuthToken();
  const headers = {};
  headers.Authorization = token;

  const navigate = useNavigate();

  // 画像選択
  const [file, setFile] = useState(null);

  const handleInputFile = async (e) => {
    const file = e.target.files[0];
    // var imgFile=file
    // console.log(imgFile)
    e.target.value = "";

   
    if (file) {
      setCheckDefaultImg(true);
      setFile({
        name: file.name,
        ImgURL: window.URL.createObjectURL(file),
        file,
      });

      setImgName(file.name)


      if(ocrCheck){
        const fd = new FormData();
        fd.append("file", file);
        try {
          setIsOcrLoading(true);

          const res = await prescriptionAPI.getPrescriptionOcr(fd);
          console.log(values);
          setPresDetails(res.data.data);
          setValues(res.data.data)
          setResponseDataAdarsh(res.data.message);
        } catch (err) {
          alert("結果が見つかりません");
        } finally {
          setIsOcrLoading(false);
        }
      }
    }
  };

  // var path = window.location.pathname;

  // 入力バリデーション
  const [errorMessages, setErrorMessages] = useState({});
  const isValidate = (values) => {
    let messages = {};
    console.log(values);

    messages.prescription_date = isEntered(
      "処方年月日",
      values.prescription_date,
      false
    );
    if (!values.insurer_number) {
      messages.insurer_number = "保険者番号は必須入力項目です。";
    } else if (!isNumeric(values.insurer_number, { no_symbols: true })) {
      messages.insurer_number = "保険者番号は半角数字でご入力ください。";
    };
    messages.name = isEntered("お名前", values.name, false);
    messages.birthday = isEntered("生年月日", values.birthday, false);
    messages.medical_institution_name = isEntered(
      "医療機関名",
      values.medical_institution_name,
      false
    );
    // messages.contact = isHalfSize(values.contact, "連絡先");
    messages.contact = isPhoneNumber(values.contact);
    messages.prescribing_physician = isEntered(
      "処方箋担当医師",
      values.prescribing_physician,
      false
    );
    messages.prescription_details = isEntered(
      "処方詳細",
      values.prescription_details,
      false
    );
    messages.number_of_split_dispensing = isEntered(
      "分割調剤の回数",
      values.number_of_split_dispensing,
      true
    );

    
    // if (!file)
    //   messages.prescription_image = isEntered("処方箋画像", null, false);

    setErrorMessages({ ...messages });
    return Object.values(messages).every((message) => message === "");
  };

  // 確認画面
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState("");
  const [PresDetails, setPresDetails] = useState("");

  useEffect(() => {
    getAuthToken() === null ? navigate("/") : console.log("work");
    var response;
    const path = window.location.pathname;
    if (path !== "/mypage/prescription/new") {
      response = prescriptionAPI.getprescriptionid(presId, "");
      response.then((res) => {
        setPresDetails(res.data);
        setValues(res.data)
        const image_split=res.data.prescription_image.split("/");
        const name=image_split[image_split.length-1];
        setImgName(name)

        

      });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // const data = new FormData(e.target);
    // const values = Object.fromEntries(data.entries());
    // console.log(values);
    // setValues(values);

    if (isValidate(values)) {
      setFormValues(values);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setOpen((prevState) => !prevState);
     
    } else {
      return;
    }
  };

  // 確認画面を閉じる
  const handleClickClose = () => {
    setOpen((prevState) => !prevState);
  };

  // 登録

  const handleCreate = async () => {
    try {
      setIsLoading(true)
      // values && values.append("patient", id);
      let res;
      var gender,generic_drug;
      const path = window.location.pathname;

      var tempObj;
      if(patientVal.gender === "" && patientVal.change_to_generic_drugs === ""){
      tempObj={
          gender: "male",
          change_to_generic_drugs: "possible"
      }
    }
    else if(patientVal.gender === "" && patientVal.change_to_generic_drugs !== ""){

      if(patientVal.change_to_generic_drugs === "可")
      generic_drug="possible"
      else
      generic_drug="impossible"

      tempObj={gender: "male",
    change_to_generic_drugs: generic_drug}
    }
    else if(patientVal.gender !== "" && patientVal.change_to_generic_drugs === ""){

      if(patientVal.gender === "男")
      gender="male"
      else
      gender="female"

      tempObj={
          gender: gender,
        change_to_generic_drugs:"possible"
      }
    }
    else if(patientVal.gender!=="" && patientVal.change_to_generic_drugs !== ""){


      if(patientVal.gender==="男")
      gender="male"
      else
      gender="female"

      if(patientVal.change_to_generic_drugs==="可")
      generic_drug="possible"
      else
      generic_drug="impossible"

      tempObj={
          gender: gender,
        change_to_generic_drugs: generic_drug
      }
    }
      const payload = { ...values,...tempObj};

      if(file)
        payload.prescription_image= file.file

      if (path !== "/mypage/prescription/new")
        res = await prescriptionAPI.sendPrescription(payload, headers, presId,checkDefaultImg);
      else res = await prescriptionAPI.createPrescription(payload);

      if (res.status === 200) {
        // path === "/mypage/prescription/new"
        //   ? toast.success(
        //       "処方箋は正常に追加されました",
        //       {
        //         position: toast.POSITION.TOP_CENTER,
        //       },
        //       2000
        //     )
        //   : toast.success(
        //       "Edit Successful",
        //       {
        //         position: toast.POSITION.TOP_CENTER,
        //       },
        //       2000
        //     );

        navigate("/mypage/prescription");
      }
    } catch (e) {
      // console.log(e.response.status);
    } finally {
      setIsLoading(false)
    }
  };

  const onFormValuesChange = useCallback((e) => {
    setValues((v) => ({ ...v, [e.target.name]: e.target.value }));
  }, []);

  const checkInfo=(value)=>{
    if(value === "male" || value === "female"){
      if(value === "male")
      setPatientVal({...patientVal,gender:"可"})
      else
      setPatientVal({...patientVal,gender: "女"})
    }
    else if(value === "possible" || value === "impossible")
    {
    if(value === "possible")
    setPatientVal({...patientVal,change_to_generic_drugs: "可"})
    else
    setPatientVal({...patientVal,change_to_generic_drugs: "不可"})
    }
    
  }


  return (
    <main className="p-prescription">
      <MypageTopMenu />

      {isOcrLoading && (
        <div className="c-linkButton__loading">
          <CircularProgress size={20} style={{ color: "var(--c-blue)" }} />
        </div>
      )}
      {!Object.values(errorMessages).every((x) => x == null || x === "") ? (
        <center>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                margin: "auto",
              }}
            >
              <>
                {errorMessages && errorMessages.prescription_date !== "" ? (
                  <p
                    className="l-formInput__errorMessage"
                    style={{ color: "red" }}
                  >
                    {errorMessages.prescription_date}
                  </p>
                ) : (
                  <span style={{ display: "none" }}>{""}</span>
                )}
                <br />
                {errorMessages && errorMessages.birthday !== "" ? (
                  <p
                    className="l-formInput__errorMessage"
                    style={{ color: "red" }}
                  >
                    {errorMessages.birthday}
                  </p>
                ) : (
                  <span style={{ display: "none" }}>{""}</span>
                )}
                <br />
                {errorMessages && errorMessages.contact !== "" ? (
                  <p
                    className="l-formInput__errorMessage"
                    style={{ color: "red" }}
                  >
                    {errorMessages.contact}
                  </p>
                ) : (
                  <span style={{ display: "none" }}>{""}</span>
                )}
                <br />
                {errorMessages && errorMessages.insurer_number !== "" ? (
                  <p
                    className="l-formInput__errorMessage"
                    style={{ color: "red" }}
                  >
                    {errorMessages.insurer_number}
                  </p>
                ) : (
                  <span style={{ display: "none" }}>{""}</span>
                )}
                <br />
                {errorMessages &&
                errorMessages.medical_institution_name !== "" ? (
                  <p
                    className="l-formInput__errorMessage"
                    style={{ color: "red" }}
                  >
                    {errorMessages.medical_institution_name}
                  </p>
                ) : (
                  <span style={{ display: "none" }}>{""}</span>
                )}
                <br />
                {errorMessages && errorMessages.name !== "" ? (
                  <p
                    className="l-formInput__errorMessage"
                    style={{ color: "red" }}
                  >
                    {errorMessages.name}
                  </p>
                ) : (
                  <span style={{ display: "none" }}>{""}</span>
                )}
                <br />
                {errorMessages &&
                errorMessages.number_of_split_dispensing !== "" ? (
                  <p
                    className="l-formInput__errorMessage"
                    style={{ color: "red" }}
                  >
                    {errorMessages.number_of_split_dispensing}
                  </p>
                ) : (
                  <span style={{ display: "none" }}>{""}</span>
                )}
                <br />
                {errorMessages && errorMessages.prescribing_physician !== "" ? (
                  <p
                    className="l-formInput__errorMessage"
                    style={{ color: "red" }}
                  >
                    {errorMessages.prescribing_physician}
                  </p>
                ) : (
                  <span style={{ display: "none" }}>{""}</span>
                )}
                <br />
                {errorMessages && errorMessages.prescription_details !== "" ? (
                  <p
                    className="l-formInput__errorMessage"
                    style={{ color: "red" }}
                  >
                    {errorMessages.prescription_details}
                  </p>
                ) : (
                  <span style={{ display: "none" }}>{""}</span>
                )}
                <br />
                {/* {errorMessages && errorMessages.prescription_image != "" ? (
                  <p
                    className="l-formInput__errorMessage"
                    style={{ color: "red" }}
                  >
                    {errorMessages.prescription_image}
                  </p>
                ) : (
                  <span style={{ display: "none" }}>{""}</span>
                )} */}
                <br />
              </>
            </div>
          </div>
        </center>
      ) : (
        <div style={{ width: "0px", height: "0px" }}>{""}</div>
      )}

      <section className="p-prescription__new">
        <h2 className="c-sectionTitle c-mypageHeading">処方箋詳細</h2>
        <div className='c-linkButton allBlue' onClick={() => {ocrInputRef.current.click()
        setOcrCheck(true)}}>
          <a>
            <span>処方箋・QRコードを撮影する</span>
          </a>
        </div>
        {/* <LinkButton
          text="処方箋・QRコードを撮影する"
          url="/mypage/prescription/new"
          color="allBlue"
        /> */}
        <div className="p-prescription__new__container">
          <form onSubmit={handleSubmit} onChange={onFormValuesChange}>
            <FormDateInput
              isControlled
              value={PresDetails.prescription_date}
              label="処方年月日"
              required={true}
              name="prescription_date"
              errorMessage={
                errorMessages.prescription_date
                  ? errorMessages.prescription_date
                  : ""
              }
              initialValue={initialValue}
              item={PresDetails}
            />
            <FormBorderInput
              isControlled
              value={PresDetails.insurer_number}
              name="insurer_number"
              label="保険者番号（半角英数字）"
              placeholder="保険者番号を入力してください"
              required="true"
              type="text"
              item={PresDetails}
              errorMessage={
                errorMessages.insurer_number ? errorMessages.insurer_number : ""
              }
            />
            <FormBorderInput
              isControlled
              value={PresDetails.name}
              name="name"
              label="お名前（フルネームでご入力ください）"
              placeholder="お名前（フルネームでご入力ください）"
              required="true"
              type="text"
              autoComplete="name"
              item={PresDetails}
              errorMessage={errorMessages.name ? errorMessages.name : ""}
            />
             <FormDateInput
              isControlled
              value={PresDetails.birthday}
              label="生年月日"
              required={true}
              name="birthday"
              errorMessage={errorMessages.birthday ? errorMessages.birthday : ""}
              initialValue={initialValue}
              item={PresDetails}
            />
            {/* <FormDateInput
              isControlled
              value={PresDetails.birthday}
              label="生年月日"
              required={true}
              name="birthday"
              item={PresDetails}
              errorMessage={
                errorMessages.birthday ? errorMessages.birthday : ""
              }
              initialValue="1970-01-01"
            /> */}
            <FormRadio
              name="gender"
              title="性別"
              options={genderRadio}
              required={true}
              item={PresDetails}
              method={checkInfo}
            />
            <FormBorderInput
              isControlled
              value={PresDetails.medical_institution_name}
              name="medical_institution_name"
              label="医療機関名"
              placeholder="処方箋発行医療機関名を入力してください"
              required="true"
              type="text"
              item={PresDetails}
              errorMessage={
                errorMessages.medical_institution_name
                  ? errorMessages.medical_institution_name
                  : ""
              }
            />
            <FormBorderInput
              isControlled
              value={PresDetails.contact}
              name="contact"
              label="連絡先（半角英数字）"
              placeholder="医療機関の連絡先を入力してください"
              required="true"
              item={PresDetails}
              type="text"
              errorMessage={errorMessages.contact ? errorMessages.contact : ""}
            />
            <FormBorderInput
              isControlled
              value={PresDetails.prescribing_physician}
              name="prescribing_physician"
              label="処方箋担当医師"
              placeholder="処方箋を発行した担当医師名を入力してください"
              required="true"
              type="text"
              item={PresDetails}
              errorMessage={errorMessages.prescribing_physician ?? ""}
            />
            <div className="l-formInput__form border">
              <label>
                処方詳細
                <span className="l-formInput__required">必須</span>
              </label>
              <textarea
                // value={PresDetails.prescription_details}
                name="prescription_details"
                placeholder="処方箋の詳細を入力してください"
                className={
                  errorMessages.prescription_details
                    ? "c-textarea__border error"
                    : "c-textarea__border"
                }
                defaultValue={
                  PresDetails.hasOwnProperty("prescription_details")
                    ? PresDetails.prescription_details
                    : ""
                }
              />
              {errorMessages.prescription_details && (
                <span className="l-formInput__errorMessage">
                  {errorMessages.prescription_details}
                </span>
              )}
            </div>
            <FormRadio
              name="change_to_generic_drugs"
              title="ジェネリック医薬品への変更"
              options={changeRadio}
              required={true}
              item={PresDetails !==""? PresDetails: ""}
              method={checkInfo}
            />
            <label>
              分割調剤の回数
              <span className="l-formInput__required">必須</span>
            </label>
            <FormSelect
              name="number_of_split_dispensing"
              options={number}
              initialValue="回数"
              errorMessage={errorMessages.number_of_split_dispensing ?? ""}
              border={true}
              item={PresDetails}
            />
            <div className="l-formInput__form border file">
              <p className="l-formInput__form__label">
                処方箋画像
                {/* <span className="l-formInput__required">必須</span> */}
              </p>
              <div>
                <label
                  className={
                    errorMessages.prescription_image
                      ? "c-input file error"
                      : "c-input file"
                  }
                >
                  {file && file.name ? `${file.name}` : "処方箋画像を選択"}
                  <input
                    type="file"
                    ref={ocrInputRef}
                    accept="image/*"
                    onChange={handleInputFile}
                    onClick={()=>{
                      setOcrCheck(false)

                    }}
                    name="prescription_image"
                  />
                </label>
                {/* <div className="c-mainButton photo">処方箋を撮影する</div> */}
              </div>
              {errorMessages.prescription_image && (
                <span className="l-formInput__errorMessage">
                  {errorMessages.prescription_image}
                </span>
              )}
            </div>
            <div>
              scanned data 
              {responseData.map((data, index) => (
                <p key={index}>{data}</p>
              ))}
            </div>
            <MainButton
              text="この内容で処方箋を登録する"
              color="blue min"
              imgName={ImgName}
            />
          </form>
        </div>

        <LinkText
          text="処方箋一覧に戻る"
          url="/mypage/prescription"
          className="underline"
        />
      </section>

      <section className={open ? "l-confirm open" : "l-confirm"}>
        <button className="l-confirm__closeBtn" onClick={handleClickClose} />
        <h2>お問い合わせ内容確認</h2>
        <div className="l-confirm__table">
          <dl>
            <div>
              <dt>処方年月日</dt>
              {formValues.prescription_date && (
                <dd>{getDateFromValue(formValues.prescription_date)}</dd>
              )}
            </div>
            <div>
              <dt>保険者番号</dt>
              {formValues.insurer_number && (
                <dd>{formValues.insurer_number}</dd>
              )}
            </div>
            <div>
              <dt>お名前</dt>
              {formValues.name && <dd>{formValues.name}</dd>}
            </div>
            {/* <div>
              <dt>生年月日</dt>
              {formValues.birthday && (
                <dd>{getDateFromValue(formValues.birthday)}</dd>
              )}
            </div> */}
            <div>
              <dt>生年月日</dt>
              {formValues.birthday && (
                <dd>{formValues.birthday}</dd>
              )}
            </div>
            <div>
              <dt>性別</dt>
              {<dd>{patientVal.gender === "" ? "男" : patientVal.gender}</dd>}
            </div>
            <div>
              <dt>医療機関名</dt>
              {formValues.medical_institution_name && (
                <dd>{formValues.medical_institution_name}</dd>
              )}
            </div>
            <div>
              <dt>連絡先</dt>
              {formValues.contact && <dd>{formValues.contact}</dd>}
            </div>
            <div>
              <dt>処方担当医師</dt>
              {formValues.prescribing_physician && (
                <dd>{formValues.prescribing_physician}</dd>
              )}
            </div>
            <div>
              <dt>処方詳細</dt>
              {formValues.prescription_details && (
                <dd>{formValues.prescription_details}</dd>
              )}
            </div>
            <div>
              <dt>ジェネリック医薬品への変更</dt>
              {(
                <dd>{patientVal.change_to_generic_drugs === ""?"可":patientVal.change_to_generic_drugs}</dd>
              )}
            </div>
            <div>
              <dt>分割調剤の回数</dt>
              {formValues.number_of_split_dispensing && (
                <dd>{formValues.number_of_split_dispensing}回</dd>
              )}
            </div>
            <div>
              <dt>処方箋画像</dt>
              {file ?
                <>
              {(
                    <dd className="l-confirm__img">
                      <img src={file.ImgURL} id="imgId" alt="処方箋" />
                  
                    </dd>
                
              )}
                </>
              : 
                <>
              {(
                    <dd className="l-confirm__img">
                  <img src={PresDetails && PresDetails?.prescription_image && PresDetails.prescription_image} id="imgId" alt="処方箋" />
                    </dd>
              )}
              </>}
            </div>
          </dl>
          <MainButton
            text="この内容で処方箋内容を登録する"
            color="blue min"
            disabled={isLoading}
            onClick={handleCreate}
          />
        </div>
      </section>
    </main>
  );
}
//new comment
