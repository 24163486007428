import moment from "moment";
import { memo } from "react";
import { getID } from "../../../helpers/storage";

const MemoMessage = memo((props) => {
  const { title, message } = props;
  console.log("memomg", message);
  const id = getID("id");
  console.log("id in the message component", id);

  return (
    <article className="p-memo__message">
      <h3>{title}</h3>
      {message.map((item, i) => {
        return (
          <div
            style={{
              backgroundColor: item.sender.id === id ? "#f2fff4" : "#f4f4f4",
            }}
          >
            <div
              key={i}
              style={{
                backgroundColor: item.sender.id === id ? "#f2fff4" : "#f4f4f4",
              }}
            >
              <dl>
                <dt>
                  <span>{moment(item.created_at).format("YYYY-MM-DD")}</span>
                  <span>{moment(item.created_at).format("HH-mm")}</span>
                </dt>
                <dd>{item.message}</dd>
              </dl>
            </div>
          </div>
        );
      })}
    </article>
  );
});

export default MemoMessage;
