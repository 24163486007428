import { useNavigate } from "react-router-dom";

const useNavigateLogin = () => {
  const navigate = useNavigate();
  return () =>
    navigate({
      pathname: "/",
      search: `?redirectAfterLogin=${window.location.pathname}`,
    });
};

export default useNavigateLogin;