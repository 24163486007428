import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"

import { TipsPageList, SignUpLink, LinkButton, TipsCategoryList} from "../components/projects"
import { SearchStore } from "../components/ui"
import { getDateFromWPValue, getTimeFromWPValue } from "../../utils/hooks"
import { URL } from "../../utils/data"
import { getAuthToken } from "../../helpers/storage"

export default function Tips() {

  const { id } = useParams();
  const [ data, setData ] = useState({isLoaded: false, items: '', error: false})
  const token = getAuthToken();
  useEffect(() => {
    // 投稿記事取得
    const url = URL
    const endpoint = `posts/${id}?_embed`
    const dataURL = url + "/wp-json/wp/v2/" + endpoint

    fetch(dataURL, {method: "GET"})
    .then(res => res.json())
    .then(data => {
      setData({
        isLoaded: true,
        items: data,
        error: false
      })
    })
    .catch(error => {
      console.log('error', error)
      setData({
        isLoaded: true,
        items: '',
        error: true
      })
    })

  }, [id])

  return (
    <main className="p-tips">
      <section className="p-tips__articleArea">
        {data.isLoaded && !data.error &&
          <>
            <div className="p-tips__date">
              <p>
                <span>{getDateFromWPValue(data.items.date)}</span>
                <span>{getTimeFromWPValue(data.items.date)}</span>
              </p>
              <span>{data.items.category_name}</span>
            </div>
            <h2>{data.items.title.rendered}</h2>
            <div className="p-tips__article">
              <div className="p-tips__img">
                <img src={data.items._embedded['wp:featuredmedia'][0].source_url} alt={data.items.title.rendered} />
              </div>
              <div className="p-tips__text" 
                dangerouslySetInnerHTML={{
                  __html: data.items.content.rendered
                }}
              />
            </div>
          </>
        }
        {data.isLoaded && data.error &&
          <div className="p-tips__article">
            <p className="p-articleList__error">
              データの取得に失敗しました。<br />
              運営までお問い合わせください。
            </p>
          </div>
        }
      </section>

      <section className="p-tips__same">
        <h3 className="c-sectionTitle">同じカテゴリの新着Tips</h3>
        {data.isLoaded && !data.error &&
          <TipsPageList
            id={data.items.categories[0]}
            type='category'
            number={2}
          />
        }
      </section>

      <TipsCategoryList />

      {!token &&<SignUpLink />}
      <LinkButton 
        text="じぶん薬局へのお問い合わせ"
        url="/contact"
        color="blue"
      />
      <SearchStore />

    </main>
  )
}