import axios from "axios";
import { getAuthToken } from "../helpers/storage";

const getToken = () => "token" + " " + getAuthToken();

const id = window.localStorage.getItem("id");
var response;

const pharmaotcinfoapi = async (values) => {
  var drug_efficacy = "";
  for (var i = 0; i < values.type.length; i++) {
    if (i > 0) drug_efficacy += " ";
    drug_efficacy += values.type[i];
  }

  var category;

  if (values.medicineType == "神経系及び感覚器官用医薬品") category = 1;
  else if (values.medicineType == "個々の器官系用医薬品") category = 2;
  else if (values.medicineType == "代謝性医薬品") category = 3;
  else if (values.medicineType == "組織細胞機能用医薬品") category = 4;
  else if (values.medicineType == "生薬及び漢方処方に基づく医薬品")
    category = 5;
  else if (values.medicineType == "病原生物に対する医薬品") category = 6;
  else if (values.medicineType == "治療を主目的としない医薬品") category = 7;
  else if (values.medicineType == "麻薬") category = 8;

  var config = {
    method: "get",

    url: `https://dev-api-pharmacy.automagicjapan.tech/api/drug/search/?contact_category=${category}&start_update_date=${
      values.updateStartDate
    }&effects=${
      values.effect
    }&effects_logic=${values.effectOption.toLowerCase()}&warning=${
      values.warning
    }&warning_logic=${values.warningOption.toLowerCase()}&contraindications=${
      values.prohibition
    }&contraindications_logic=${values.prohibitionOption.toLowerCase()}&concomitant=${
      values.jointly
    }&concomitant_logic=${values.jointlyOption.toLowerCase()}&generic_name=${
      values.medicineName
    }&drug_efficasy_classification=${drug_efficacy}&inquiry_company_name=${
      values.campanyName
    }
        `,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
    },
  };

  response = await axios(config);
  return response;
};

const classification = async () => {
  var response;
  const config = {
    method: "get",
    url: `https://dev-api-pharmacy.automagicjapan.tech/api/drug_classification/`,
    headers: {
      Authorization: getToken(),
    },
  };
  response = await axios(config);
  return response;
};

export const pharmaotcinforesponse = {
  pharmaotcinfoapi,
  classification,
};
