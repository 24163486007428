import { useState, memo } from "react";

const HowTo = memo(() => {
  // 使い方の開閉
  const [openText, setOpenText] = useState(false);
  const handleClickBtn = () => {
    setOpenText((prevState) => !prevState);
  };

const howToStyle={
  maxHeight:"500px"
}


 

  return (
    <article className="p-howto">
      <button onClick={handleClickBtn} >
        <span>じぶん薬局のご利用方法</span>
      </button>
      <div className={openText ? "p-howto__open" : ""} style={openText? howToStyle: {maxHeight:"0"}} >
        <p style={{ textAlign: "center" }}>
          <p>
            じぶん薬局は、かかりつけの薬局とおくすりを必要とする患者様とのコミュニケーションを活性化するためのサービスです。
          </p>
          <p>
            医師から受け取った処方箋、申請と受け取りに面倒を感じたことはありませんか？
          </p>
          <p>
            せっかく行っても混んでいて長い時間待たされてしまったり、処方箋の有効期限が切れてしまって、困ってしまったこともあるのではないでしょうか。
          </p>

          <p>
            じぶん薬局はそんなおくすりでお困りの方に、そっと寄り添うために生まれたサービスです。
          </p>
        </p>
        <button onClick={handleClickBtn}>ご利用方法を閉じる</button>
      </div>
    </article>
  );
});

export default HowTo;
