import { MypageTopMenu } from "../components/ui";
import { LinkButton, Reserve } from "../components/projects";
import getReceipt from "../../apis/prescriptionreceipt";
import { useState, useEffect } from "react";
import { getAuthToken, getFromLocalStorage } from "../../helpers/storage";
import moment from "moment";
import { Navigate } from "react-big-calendar";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import Loader from "../components/ui/loader";

// 予約ダミーデータ
const items = [
  {
    date: "2021/12/21",
    time: "13:22",
    store: "薬局・薬店名。薬局・薬店名。薬局・薬店名。",
    message: true,
    reserve: true,
    red: true,
    id: 1,
  },
  {
    date: "2021/12/21",
    time: "13:22",
    store: "薬局・薬店名。薬局・薬店名。薬局・薬店名。",
    teach: true,
    red: true,
    id: 2,
  },
  {
    date: "2021/12/21",
    time: "13:22",
    store: "薬局・薬店名。薬局・薬店名。薬局・薬店名。",
    red: false,
    id: 3,
  },
  {
    date: "2021/12/21",
    time: "13:22",
    store: "薬局・薬店名。薬局・薬店名。薬局・薬店名。",
    red: false,
    id: 4,
  },
  {
    date: "2021/12/21",
    time: "13:22",
    store: "薬局・薬店名。薬局・薬店名。薬局・薬店名。",
    red: false,
    id: 5,
  },
];

export default function ReserveList() {
  const [reserveList, setReserveList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [enable, setEnable] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pagination, setPagination] = useState();

  const onChange = (page) => {
    setPageNumber(page);
  };

  const token = "token" + " " + localStorage.getItem("token");
  const headers = { Authorization: token };
  console.log("headers", headers);

  const navigate = useNavigate();

  const getData = async () => {
    getAuthToken() === null ? navigate("/") : setEnable(true);
    const response = await getReceipt.getReceiptData(headers, pageNumber);
    setPagination({
      count: response.data.count,
      previous: response.data.previous,
      next: response.data.next,
    });
    console.log("response", response);
    setReserveList(response.data.results);
    setIsLoading(false);
  };

  useEffect(() => getData(), [pageNumber]);

  if (isLoading) return <Loader />;
  console.log("reservelist", reserveList && reserveList);

  return (
    <main className="p-reserve">
      <MypageTopMenu />

      <section className="p-reserve__list">
        <h2 className="c-sectionTitle c-mypageHeading">処方箋受け取り予約</h2>
        <LinkButton
          text="処方箋の受け取り予約申請"
          url="/mypage/reserve/new"
          color="allBlue"
        />
        <ul>
          {reserveList?.map((item, i) => {
            return <Reserve key={i} item={item} />;
          })}
          <Pagination
            size="small"
            total={pagination && pagination.count}
            onChange={onChange}
            current={pageNumber}
            showSizeChanger={false}
          />
        </ul>
      </section>
    </main>
  );
}
