import { MypageTopMenu } from "../components/ui";
import { LinkText } from "../components/projects";
import { useContext } from "react";
import { Contextdata } from "../../UserContext";
import { useEffect, useState } from "react";
import otcdetails from "../../apis/otcinfo";
import { useNavigate, useParams } from "react-router-dom";
import { getAuthToken } from "../../helpers/storage";

// ダミーデータ
const data = {
  medicineName: "モルヒネ塩酸塩注射液50mg「シオノギ」",
  code: "2134324543654",
  standardName: "日本薬局方 モルヒネ塩酸塩注射液",
  classNumber: "878114",
  YJcode: "8114401A1112",
  categoryName: "モルヒネ系製剤",
  category: "第一類医薬品",
  approvalNuber: "22000AMX01076",
  lang: "Morphine Hydrochloride「SHIONOGI」",
  priceDate: "1951年8月",
  startDate: "1995年6月",
  keep: "遮光・室温保存",
  limit: "外箱等に表示（使用期間5年）",
  regulation: "劇薬、麻薬、処方箋医薬品",
  regulationCaution: "注意−医師等の処方箋により使用すること",
  component: ["1管（5mL", "中モルヒネ塩酸塩水和物50mg"],
  add: "塩酸，注射用水",
  properties: [
    "無色〜微黄褐色澄明の液である。",
    "光によって徐々に黄褐色を帯びる。（注射剤）",
  ],
  ph: "2.5〜5.0",
  pressure: "〔生理食塩液に対する比〕 約0.2",
  warning:
    "本剤の硬膜外及びくも膜下投与は，これらの投与法に習熟した医師のみにより，本剤の投与が適切と判断される患者についてのみ実施すること。",
  prohibition: [
    {
      title: "1.	皮下・静脈内，硬膜外及びくも膜下投与共通",
      text: `(1) 重篤な呼吸抑制のある患者［呼吸抑制を増強する。］
(2) 気管支喘息発作中の患者［気道分泌を妨げる。］
(3) 重篤な肝障害のある患者［昏睡に陥ることがある。］
(4) 慢性肺疾患に続発する心不全の患者［呼吸抑制や循環不全を増強する。］
(5)	痙攣状態（てんかん重積症，破傷風，ストリキニーネ中毒）にある患者［脊髄の刺激効果があらわれる。］
(6)	急性アルコール中毒の患者［呼吸抑制を増強する。］
(7)	アヘンアルカロイドに対し過敏症の患者
(8)	出血性大腸炎の患者［腸管出血性大腸菌（Ｏ157等）や赤痢菌等の重篤な細菌性下痢のある患者では，症状の悪化，治療期間の延長を来すおそれがある。］
(9)	ナルメフェン塩酸塩水和物を投与中又は投与中止後1週間以内の患者［「相互作用」の項参照］`,
    },
    {
      title: "2.	硬膜外投与の場合",
      text: `(1)	注射部位又はその周辺に炎症のある患者［化膿性髄膜炎症状を起こすことがある。］
(2)	敗血症の患者［敗血症性の髄膜炎を生じるおそれがある。］`,
    },
    {
      title: "3.	くも膜下投与の場合",
      text: `(1)	注射部位又はその周辺に炎症のある患者［化膿性髄膜炎症状を起こすことがある。］
(2)	敗血症の患者［敗血症性の髄膜炎を生じるおそれがある。］
(3)	中枢神経系疾患（髄膜炎，灰白脊髄炎，脊髄癆等）の患者［くも膜下投与により病状が悪化するおそれがある。］
(4) 脊髄・脊椎に結核，脊椎炎及び転移性腫瘍等の活動性疾患のある患者［くも膜下投与により病状が悪化するおそれがある。］`,
    },
  ],
  prohibition2: {
    text1:
      "（次の患者には投与しないことを原則とするが、特に必要とする場合には慎重に投与すること）",
    text2: "皮下・静脈内，硬膜外及びくも膜下投与共通",
    text3: "細菌性下痢のある患者",
    text4: "［治療期間の延長を来すおそれがある。］",
  },
  effect: [
    {
      title: "1.	皮下及び静脈内投与の場合",
      text: `(1) 激しい疼痛時における鎮痛・鎮静
(2) 激しい咳嗽発作における鎮咳
(3)	激しい下痢症状の改善及び手術後等の腸管蠕動運動の抑制
(4)	麻酔前投薬，麻酔の補助
(5)	中等度から高度の疼痛を伴う各種癌における鎮痛`,
    },
    {
      title: "2.	硬膜外及びくも膜下投与の場合",
      text: `(1) 激しい疼痛時における鎮痛
(2)	中等度から高度の疼痛を伴う各種癌における鎮痛`,
    },
  ],
  usage: [
    {
      title: "1.	皮下及び静脈内投与の場合",
      text: `通常，成人にはモルヒネ塩酸塩水和物として1回5〜10mgを皮下に注射する。また，麻酔の補助として，静脈内に注射することもある。
なお，年齢，症状により適宜増減する。
中等度から高度の疼痛を伴う各種癌における鎮痛において持続点滴静注又は持続皮下注する場合には，通常，成人にはモルヒネ塩酸塩水和物として1回50〜200mgを投与する。
なお，年齢，症状により適宜増減する。`,
    },
    {
      title: "2.	硬膜外投与の場合",
      text: `通常，成人にはモルヒネ塩酸塩水和物として1回2〜6mgを硬膜外腔に注入する。
なお，年齢，症状により適宜増減する。
硬膜外腔に持続注入する場合は，通常，成人にはモルヒネ塩酸塩水和物の1日量として2〜10mgを投与する。
なお，年齢，症状により適宜増減する。`,
    },
    {
      title: "3.	くも膜下投与の場合",
      text: `通常，成人にはモルヒネ塩酸塩水和物として1回0.1〜0.5mgをくも膜下腔に注入する。
なお，年齢，症状により適宜増減する。`,
    },
  ],
  usageCaution: [
    {
      title: "1.	皮下及び静脈内投与の場合",
      text: `200mg注射液（4％製剤）は，10mgあるいは50mg注射液（1％製剤）の4倍濃度であるので，1％製剤から4％製剤への切り替えにあたっては，持続注入器の注入速度，注入量を慎重に設定し，過量投与とならないように注意して使用すること。`,
    },
    {
      title: "2.	硬膜外投与の場合",
      text: `(1) 200mg注射液（4％製剤）は硬膜外投与には使用しないこと。
(2)	オピオイド鎮痛薬を使用していない患者に対しては，初回投与時には，24時間以内の総投与量が10mgを超えないこと。
(3)	硬膜外投与で十分な鎮痛効果が得られず，さらに追加投与が必要な場合には，患者の状態（呼吸抑制等）を観察しながら慎重に投与すること。`,
    },
    {
      title: "3.	くも膜下投与の場合",
      text: `(1) 200mg注射液（4％製剤）はくも膜下投与には使用せず，原則として10mg注射液（1％製剤）を使用すること。
(2)	患者の状態（呼吸抑制等）を観察しながら慎重に投与すること。
(3)	原則として追加投与や持続投与は行わないが，他の方法で鎮痛効果が得られない場合には，患者の状態を観察しながら，安全性上問題がないと判断できる場合にのみ，その実施を考慮すること。`,
    },
  ],
  useCation: [
    { title: "", text: "慎重投与（次の患者には慎重に投与すること）" },
    {
      title: "1.	皮下・静脈内，硬膜外及びくも膜下投与共通",
      text: `(1) 心機能障害のある患者［循環不全を増強するおそれがある。］
(2)	呼吸機能障害のある患者［呼吸抑制を増強するおそれがある。］
(3)	肝・腎機能障害のある患者［代謝・排泄が遅延し副作用があらわれるおそれがある。］
(4)	脳に器質的障害のある患者［呼吸抑制や頭蓋内圧の上昇を起こすおそれがある。］
(5)	ショック状態にある患者［循環不全や呼吸抑制を増強するおそれがある。］
(6)	代謝性アシドーシスのある患者［呼吸抑制を起こすおそれがある。］
(7)	甲状腺機能低下症（粘液水腫等）の患者［呼吸抑制や昏睡を起こすおそれがある。］
(8)	副腎皮質機能低下症（アジソン病等）の患者［呼吸抑制作用に対し，感受性が高くなっている。］
(9)	薬物依存の既往歴のある患者［依存性を生じやすい。］
(10)	高齢者［「高齢者への投与」の項参照］
(11)	新生児，乳児［「小児等への投与」の項参照］
(12)	衰弱者［呼吸抑制作用に対し，感受性が高くなっている。］
(13)	前立腺肥大による排尿障害，尿道狭窄，尿路手術術後の患者［排尿障害を増悪することがある。］
(14)	器質的幽門狭窄，麻痺性イレウス又は最近消化管手術を行った患者［消化管運動を抑制する。］
(15)	痙攣の既往歴のある患者［痙攣を誘発するおそれがある。］
(16)	胆嚢障害及び胆石のある患者［胆道痙攣を起こすことがある。］
(17)	重篤な炎症性腸疾患のある患者［連用した場合，巨大結腸症を起こすおそれがある。］
(18)	ジドブジン（アジドチミジン）を投与中の患者［「相互作用」の項参照］`,
    },
    {
      title: "2.	硬膜外投与の場合",
      text: `(1) 中枢神経系疾患（髄膜炎，灰白脊髄炎，脊髄癆等）の患者［硬膜外投与により病状が悪化するおそれがある。］
(2)	脊髄・脊椎に結核，脊椎炎及び転移性腫瘍等の活動性疾患のある患者［硬膜外投与により病状が悪化するおそれがある。］
(3)	血液凝固障害のある患者又は抗凝血剤を投与中の患者［出血しやすく，血腫形成や脊髄への障害を起こすことがある。］
(4)	脊柱に著明な変形のある患者［脊髄や神経根の損傷のおそれがある。］`,
    },
    {
      title: "3.	くも膜下投与の場合",
      text: `(1) 血液凝固障害のある患者又は抗凝血剤を投与中の患者［出血しやすく，血腫形成や脊髄への障害を起こすことがある。］
(2)	脊柱に著明な変形のある患者［脊髄や神経根の損傷のおそれがある。］`,
    },
  ],
  basicCaution: [
    {
      title: "1.	皮下・静脈内，硬膜外及びくも膜下投与共通",
      text: `(1) 連用により薬物依存を生じることがあるので，観察を十分に行い，慎重に投与すること。［「副作用」の項参照］
(2)	眠気，眩暈が起こることがあるので，本剤投与中の患者には自動車の運転等危険を伴う機械の操作に従事させないように注意すること。`,
    },
    {
      title: "2.	硬膜外投与の場合",
      text: `(1) 本剤の使用に際しては，初回投与あるいは導入時から，鎮痛状態が安定し，安全性上問題ないと判断できるまでは，必ず気道確保，呼吸管理等の蘇生設備の完備された場所で，厳重な管理の下に使用すること。
(2)	重篤な呼吸抑制が投与から数時間以上経過した後に発現することがあるので，十分に注意すること。
(3)	硬膜外腔内留置カテーテルを介した投与により肉芽腫等の腫瘤が生じることがあるので，十分に注意すること。［「副作用」の項参照］`,
    },
    {
      title: "3. くも膜下投与の場合",
      text: `(1) 本剤の使用に際しては，必ず気道確保，呼吸管理等の蘇生設備の完備された場所で，厳重な管理の下に使用すること。
(2)	重篤な呼吸抑制が投与から数時間以上経過した後に発現することがあるので，十分に注意すること。
(3)	くも膜下腔内留置カテーテルを介した投与により肉芽腫等の腫瘤が生じることがあるので，十分に注意すること。［「副作用」の項参照］`,
    },
  ],
  interaction: "併用禁忌（併用しないこと）",
  drugName: "ナルメフェン塩酸塩水和物 セリンクロ",
  clinical:
    "本剤の鎮痛作用を減弱させることがある。また，退薬症候を起こすことがある。緊急の手術等によりやむを得ず本剤を投与する場合は，患者毎に用量を漸増し，呼吸抑制等の中枢神経抑制症状を注意深く観察すること。手術等において本剤を投与することが事前にわかる場合には，少なくとも1週間前にナルメフェン塩酸塩水和物の投与を中断すること。［「禁忌」の項参照］",
  danger: "μオピオイド受容体拮抗作用により，本剤の作用が競合的に阻害される。",
  interactionCaution: [
    {
      title: "1.	薬剤名等",
      text: `中枢神経抑制剤
フェノチアジン系薬剤，バルビツール酸系薬剤等吸入麻酔剤MAO阻害剤三環系抗うつ剤β遮断剤アルコール

臨床症状・措置方法
臨床症状：呼吸抑制，低血圧及び顕著な鎮静又は昏睡が起こることがある。措置方法：減量するなど慎重に投与すること。

機序・危険因子
相加的に中枢神経抑制作用を増強させる。`,
    },
    {
      title: "2.	薬剤名等",
      text: `クマリン系抗凝血剤　ワルファリンカリウム

臨床症状・措置方法
クマリン系抗凝血剤の作用が増強されることがある。

機序・危険因子
機序は不明`,
    },
    {
      title: "3.	薬剤名等",
      text: `抗コリン作用を有する薬剤
臨床症状・措置方法
臨床症状：麻痺性イレウスに至る重篤な便秘又は尿貯留が起こるおそれがある。

機序・危険因子
共に抗コリン作用を有する。`,
    },
    {
      title: "4.	薬剤名等",
      text: `ジドブジン（アジドチミジン）
臨床症状・措置方法
ジドブジンのクリアランスを低下させる。

機序・危険因子
ジドブジンの代謝が阻害される。`,
    },
    {
      title: "5.	薬剤名等",
      text: `ブプレノルフィン
臨床症状・措置方法
ブプレノルフィンの高用量（8mg連続皮下投与）において，本剤の作用に拮抗するとの報告がある。

機序・危険因子
ブプレノルフィンはμ受容体部分的アゴニストである。`,
    },
  ],
  sideEffects: [
    {
      title: "",
      text: `副作用等発現状況の概要

本剤は使用成績調査等の副作用発現頻度が明確となる調査を実施していない。

重大な副作用`,
    },
    {
      title: "1.依存性（頻度不明）",
      text: `：連用により薬物依存を生じることがあるので，観察を十分に行い，慎重に投与すること。また，連用中における投与量の急激な減少ないし投与の中止により，あくび，くしゃみ，流涙，発汗，悪心，嘔吐，下痢，腹痛，散瞳，頭痛，不眠，不安，譫妄，振戦，全身の筋肉・関節痛，呼吸促迫等の退薬症候があらわれることがあるので，投与を中止する場合には，1日用量を徐々に減量するなど，患者の状態を観察しながら行うこと。`,
    },
    {
      title: "2.呼吸抑制（頻度不明）",
      text: `：呼吸抑制があらわれることがあるので，息切れ，呼吸緩慢，不規則な呼吸，呼吸異常等があらわれた場合には，投与を中止するなど適切な処置を行うこと。なお，本剤による呼吸抑制には，麻薬拮抗剤（ナロキソン，レバロルファン等）が拮抗する。3.錯乱，譫妄（頻度不明）：錯乱，譫妄があらわれることがあるので，このような場合には，減量又は投与を中止するなど適切な処置を行うこと。4.無気肺，気管支痙攣，喉頭浮腫（頻度不明）：無気肺，気管支痙攣，喉頭浮腫があらわれるとの報告がある。5.麻痺性イレウス，中毒性巨大結腸（頻度不明）：炎症性腸疾患の患者に投与した場合，麻痺性イレウス，中毒性巨大結腸があらわれるとの報告がある。`,
    },
  ],
  otherSideEffects1: {
    title: "過敏症",
    text: ["頻度不明", "発疹，そう痒感"],
  },
  otherSideEffects2: {
    title: "消化器",
    text: ["頻度不明", "眠気，眩暈，不安，不穏，興奮，視調節障害，発汗"],
  },
  otherSideEffects3: {
    title: "循環器",
    text: ["頻度不明", "不整脈，血圧変動，顔面潮紅"],
  },
  otherSideEffects4: {
    title: "投与部位",
    text: ["頻度不明", "発赤，腫脹，硬結，疼痛，肉芽腫等の腫瘤"],
  },
  otherSideEffects5: {
    title: "その他",
    text: ["頻度不明", "排尿障害，尿閉，頭蓋内圧の亢進，脱力"],
  },
  otherSideEffectsCaution: "症状があらわれた場合には投与を中止すること。",
  senior:
    "一般に高齢者では生理機能が低下しており，特に呼吸抑制の感受性が高いため，低用量から投与を開始するなど患者の状態を観察しながら，慎重に投与すること。",
  pregnant: [
    "1. 妊婦又は妊娠している可能性のある婦人には，治療上の有益性が危険性を上回ると判断される場合にのみ投与すること。［動物試験（マウス）で催奇形作用が報告されている1)。］",
    "2.	分娩前に投与した場合，出産後新生児に退薬症候（多動，神経過敏，不眠，振戦等）があらわれることがある。",
    "3.	分娩時の投与により，新生児に呼吸抑制があらわれることがある。",
    "4.	授乳中の婦人には，本剤投与中は授乳を避けさせること。［ヒト母乳中へ移行することがある。］",
  ],
  child:
    "新生児，乳児では呼吸抑制の感受性が高いため，低用量から投与を開始するなど患者の状態を観察しながら，慎重に投与すること。",
  overdose: [
    "徴候，症状：呼吸抑制，意識不明，痙攣，錯乱，血圧低下，重篤な脱力感，重篤な眩暈，嗜眠，心拍数の減少，神経過敏，不安，縮瞳，皮膚冷感等を起こすことがある。",
    "処置：過量投与時には以下の治療を行うことが望ましい。",
    "1.	投与を中止し，気道確保，補助呼吸及び呼吸調節により適切な呼吸管理を行う。",
    "2.	麻薬拮抗剤（ナロキソン，レバロルファン等）投与を行い，患者に退薬症候又は麻薬拮抗剤の副作用が発現しないよう慎重に投与する。なお，麻薬拮抗剤の作用持続時間はモルヒネのそれより短いので，患者のモニタリングを行うか又は患者の反応に応じて初回投与後は注入速度を調節しながら持続静注する。",
    "3.	必要に応じて補液，昇圧剤等の投与又は他の補助療法を行う。",
  ],
  applicationCaution: [
    {
      title: "1. 調製時",
      text: `：低温下では結晶が析出することがあるので，このような場合には体温付近まで加温し，溶解後使用する2),3)。`,
    },
    {
      title: "2. 皮下及び静脈内投与時",
      text: `：投与経路：モルヒネ製剤の癌疼痛における臨床使用方法としては経口投与又は直腸内投与が不可能なとき，初めて注射を用いる。
  
  投与速度：急速静注により，アナフィラキシー，重篤な呼吸抑制，低血圧，末梢循環虚脱，心停止が起こるおそれがあるので，静注する場合には緩徐に行うことが望ましい。`,
    },
    {
      title: "3. 硬膜外投与時",
      text: `：調製時：5〜10mLの生理食塩液等に希釈し投与すること。持続投与する場合には，生理食塩液等に希釈し投与すること。
  
  投与時：
    (1) 注射針又はカテーテル先端が，血管又はくも膜下腔に入っていないことを確かめること。
    (2) 試験的に注入（test dose）し，注射針又はカテーテルが適切に留置されていることを確認すること。`,
    },
    {
      title: "4.	くも膜下投与時",
      text: `：調製時：生理食塩液等に希釈し投与すること。なお，本剤と混合又は希釈する液の種類及び比重により鎮痛効果の持続時間，鎮痛領域（分節性）に違いが生じる可能性があるので，疼痛の種類，患者の状態に応じて適切な希釈液を選択すること。
  
  投与時：
    (1) 髄液の漏出を最小に防ぐために，脊髄くも膜下麻酔針は，できるだけ細いものを用いること。（脊髄くも膜下腔穿刺により脊髄麻酔後頭痛が，また，まれに一過性の外転神経麻痺等があらわれることがある。なお，このような症状があらわれた場合には輸液投与を行うなど適切な処置を行うこと。）
    (2) まれに脊髄神経障害があらわれることがあるので，穿刺に際して患者が放散痛を訴えた場合，脳脊髄液が出にくい場合又は血液混入を認めた場合には，本剤を注入しないこと。
  
  5.	アンプルカット時：本品はワンポイントカットアンプルであるが，アンプルの首部をエタノール綿等で清拭してから，カットすることが望ましい。
  6.	患者等に対する指導：本剤が不要となった場合には，病院又は薬局へ返却するなどの処置について適切に指導すること。`,
    },
  ],
  dynamics: [
    {
      title: "1.	血漿中濃度",
      text: `モルヒネ塩酸塩注射液10mg・50mgの持続点滴静注及び持続皮下注の定常状態における血漿中モルヒネ濃度とモルヒネ塩酸塩注射液投与量の関係を図1，2に示す4)。対象：各種がん患者測定法：HPLC
(1) 持続点滴静注r＝0.745（p＜0.01）（n＝26）y＝0.41x＋15.66
(2) 持続皮下注r＝0.724（p＜0.01）（n＝18）y＝0.54x＋33.61`,
    },
    {
      title: "2.	分布",
      text: `モルヒネは骨格筋，腎臓，肝臓，小腸，肺，脾臓，脳に分布する5)。また胎盤を通過し，乳汁中にも検出される。（外国人によるデータ）`,
    },
    {
      title: "3.	代謝",
      text: "モルヒネは主としてグルクロン酸抱合を受け，モルヒネ-3-グルクロナイド及び薬理活性を持つモルヒネ-6-グルクロナイドに代謝される。",
    },
    {
      title: "4.	排泄",
      text: "モルヒネは大部分が抱合体として，24時間までの尿中に約90％，糞中に7〜10％が排泄される。",
    },
    {
      title: "5. その他",
      text: "血漿蛋白結合率：約35％6)（外国人によるデータ）",
    },
  ],
  pharmacology: "1管（1mL）中モルヒネ塩酸塩水和物10mg",
  pharmacologicalAction: [
    {
      title: "1.	中枢神経系",
      text: "モルヒネ塩酸塩水和物の中枢神経抑制作用は，大脳皮質に始まり，順次下降して延髄・脊髄に及ぶ。ヒトでは，5〜10mgで運動中枢，意識，知覚に影響することなく痛覚の感受性を減じ，鎮痛の目的に用いられる。また，呼吸・咳嗽中枢を抑制し，呼吸鎮静作用，鎮咳作用をあらわす。増量に従い，発揚状態から催眠作用があらわれ，もうろう状態に至り，1回30mgで深い睡眠に陥る。この経過中に延髄の嘔吐中枢を刺激して嘔気，嘔吐を起こすことがある。",
    },
    {
      title: "2.	消化器系",
      text: "胃腸管の運動を低下させ，止瀉作用をあらわす。また，膵液や腸液等消化液の分泌を減少させる。",
    },
    {
      title: "3.	循環器系",
      text: "薬用量では心拍数，血圧にほとんど影響がないか，あっても軽度である。大量では血圧下降があらわれる。",
    },
    {
      title: "4.	その他",
      text: "体温調節中枢の抑制作用，瞳孔縮小作用，汗腺を除く外分泌腺の分泌抑制作用等を示す。",
    },
  ],
  findings: [
    {
      title: "一般的名称",
      text: "：モルヒネ塩酸塩水和物（JAN）［日局］Morphine Hydrochloride Hydrate",
    },
    {
      title: "化学名",
      text: "(5R,6S)-4,5-Epoxy-17-methyl-7,8-didehydromorphinan-3,6-diol monohydrochloride trihydrate",
    },
    { title: "分子式", text: "：C17H19NO3・HCl・3H2O" },
    { title: "分子量", text: "375.84" },
    { title: "化学構造式", text: "：" },
    {
      title: "性状",
      text: "：白色の結晶又は結晶性の粉末である。ギ酸に溶けやすく，水にやや溶けやすく，メタノールにやや溶けにくく，エタノール（95）に溶けにくい。光によって徐々に黄褐色を帯びる。",
    },
    {
      title: "融点",
      text: `：約200 ℃（分解）
分配係数：1.2［pH7.4，1-オクタノール/緩衝液］`,
    },
  ],
  packaging: [
    "モルヒネ塩酸塩注射液10mg「シオノギ」：1mL 10管",
    "モルヒネ塩酸塩注射液50mg「シオノギ」：5mL 5管",
  ],
  literature: [
    `主要文献
〔文献請求番号〕`,
    `1) Harpel,H.S.et al.：
J.Pharm.Sci.,1968,57(9),1590〔196800240〕`,
    `2)MH-200 共同社内資料-5（使用上の注意及びその設定根拠，2001)〔200100752〕`,
    `3)MH-200 共同社内資料-7（50mg，200mg の低温・凍結保存試験，2003)〔200300177〕`,
    `4)山村秀夫ほか：基礎と臨床，
1993,27(13),5403〔199301566〕`,
    `5)Christopoulos,G.N.et al.：
J.Chromatogr.,1972,65,507〔197200407〕`,
    `6)Olsen,G.D.：
Clin.Pharmacol.Ther.,1975,17(1),31〔197500780〕`,
  ],
  claim: [
    "主要文献に記載の社内資料につきましても下記にご請求ください。",
    `塩野義製薬株式会社 医薬情報センター
〒541-0045大阪市中央区道修町3丁目1番8号
電話0120-956-734
FAX 06-6202-1541
http://www.shionogi.co.jp/med/`,
  ],
  info: "本剤は厚生労働省告示第75号（平成24年3月5日付）に基づき，投与量は1回30日分を限度とされている。",
  address: [
    `*製造販売元
シオノギファーマ株式会社
大阪府摂津市三島2丁目5番1号`,
    `*販売元
塩野義製薬株式会社
大阪市中央区道修町3丁目1番8号`,
  ],
};

export default function Medicine() {
  const [otc, setOtc] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [enable, setEnable] = useState(false);

  const id = useParams();

  const token = "token" + " " + getAuthToken();
  const headers = { Authorization: token };

  const navigate = useNavigate();

  const getData = async () => {
    const response = await otcdetails.detailedDrug(id.id, headers);
    console.log("response", response);
    setOtc(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getAuthToken() === null ? navigate("/") : setEnable(true);
  });
  useEffect(() => getData(), [enable]);
  if (isLoading) return <></>;
  if (!otc) return <div>Please try again</div>;
  return (
    <main>
      <MypageTopMenu />
      {otc && (
        <section className="p-searchMedicine__detail">
          <h2 className="c-sectionTitle c-mypageHeading">
            OTC医薬品・医療用医薬品検索 {console.log("otc", otc)}
          </h2>

          <dl>
            <div>
              <dt className="p-searchMedicine__detail__title">販売名</dt>
              <dd className="p-searchMedicine__detail__text">
                {otc.brand_name == null ? " " : otc.brand_name}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">販売名コード</dt>
              <dd className="p-searchMedicine__detail__text">
                {otc.sales_name_code == null ? " " : otc.sales_name_code}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">基準名</dt>
              <dd className="p-searchMedicine__detail__text">
                {otc.drug_name === null ? " " : otc.drug_name}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">
                日本標準商品分類番号
              </dt>
              <dd className="p-searchMedicine__detail__text">
                {otc.japan_standard_prod_classification_number === null
                  ? " "
                  : otc.japan_standard_prod_classification_number}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">YJコード</dt>
              <dd className="p-searchMedicine__detail__text">
                {otc.yj_code === null ? " " : otc.yj_code}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">薬効分類名</dt>
              <dd className="p-searchMedicine__detail__text">
                {otc.medicinal_effect_classification_name === null
                  ? " "
                  : otc.medicinal_effect_classification_name}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">医薬品分類</dt>
              <dd className="p-searchMedicine__detail__text">
                {otc.drug_classification?.drug_classification_en === null
                  ? " "
                  : otc.drug_classification?.drug_classification_en}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">承認番号</dt>
              <dd className="p-searchMedicine__detail__text">
                {otc.approval_number === null ? " " : otc.approval_number}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">欧文商標名</dt>
              <dd className="p-searchMedicine__detail__text">
                {otc.european_trademark_name === null
                  ? " "
                  : otc.european_trademark_name}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">
                薬価基準収載年月
              </dt>
              <dd className="p-searchMedicine__detail__text">
                {otc.nhi_price_standard_listing_date === null
                  ? " "
                  : otc.nhi_price_standard_listing_date}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">販売開始年月</dt>
              <dd className="p-searchMedicine__detail__text">
                {otc.sales_start_date === null ? " " : otc.sales_start_date}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">貯法</dt>
              <dd className="p-searchMedicine__detail__text">
                {otc.saving_method === null ? " " : otc.saving_method}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">使用期限</dt>
              <dd className="p-searchMedicine__detail__text">
                {otc.expiration_date === null ? " " : otc.expiration_date}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">規制区分</dt>
              <dd className="p-searchMedicine__detail__text">
                {/* {data.regulation}
              {data.regulationCaution && <span>注1）</span>}
              {data.regulationCaution && (
                <>
                  <br />
                  <span>注1）</span>
                  data.regulationCaution
                </>
              )} */}
                {otc.regulation_classification === null
                  ? " "
                  : otc.regulation_classification}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">有効成分</dt>
              <dd className="p-searchMedicine__detail__text">
                {/* {data.component.map((item, i) => {
                return <p key={i}>{item}</p>;
              })} */}
                {otc.active_ingredient === null ? " " : otc.active_ingredient}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">添加物</dt>
              <dd className="p-searchMedicine__detail__text">
                {otc.additive === null ? " " : otc.additive}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">性状・剤形</dt>
              <dd className="p-searchMedicine__detail__text">
                <dl className="p-searchMedicine__detail__detail">
                  {/* <div>
                  <dt>pH</dt>
                  <dd>{data.ph}</dd>
                </div>
                <div>
                  <dt>浸透圧比</dt>
                  <dd>{data.pressure}</dd>
                </div> */}
                  <div>{otc.properties === null ? " " : otc.properties}</div>
                </dl>
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title red">警告</dt>
              <dd className="p-searchMedicine__detail__text red">
                {otc.warning === null ? " " : otc.warning}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title red">禁忌</dt>
              <dd className="p-searchMedicine__detail__text">
                <dl className="p-searchMedicine__detail__detail">
                  {/* {data.prohibition.map((item, i) => {
                  return (
                    <div key={i}>
                      <dt>{item.title}</dt>
                      <dd>{item.text}</dd>
                    </div>
                  );
                })} */}
                  {otc.contraindications === null ? " " : otc.contraindications}
                </dl>
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title red">原則禁忌</dt>
              <dd className="p-searchMedicine__detail__text">
                {/* {data.prohibition2.text1}
              <br />
              <span>{data.prohibition2.text2}</span>
              <br />
              <span>{data.prohibition2.text3}</span>
              {data.prohibition2.text4} */}
                {otc.contraindications_in_principle === null
                  ? " "
                  : otc.contraindications_in_principle}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">効能・効果</dt>
              <dd className="p-searchMedicine__detail__text">
                <dl className="p-searchMedicine__detail__detail">
                  {/* {data.effect.map((item, i) => {
                  return (
                    <div key={i}>
                      <dt>{item.title}</dt>
                      <dd>{item.text}</dd>
                    </div>
                  );
                })} */}
                  {otc.efficacy === null ? " " : otc.efficacy}
                </dl>
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">用法・用量</dt>
              <dd className="p-searchMedicine__detail__text">
                <dl className="p-searchMedicine__detail__detail">
                  {/* {data.usage.map((item, i) => {
                  return (
                    <div key={i}>
                      <dt>{item.title}</dt>
                      <dd>{item.text}</dd>
                    </div>
                  );
                })} */}
                  <div>
                    {otc.usage_dosage === null ? " " : otc.usage_dosage}
                  </div>
                </dl>
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">
                用法・用量に関連する使用上の注意
              </dt>
              <dd className="p-searchMedicine__detail__text">
                <dl className="p-searchMedicine__detail__detail">
                  {/* {data.usageCaution.map((item, i) => {
                  return (
                    <div key={i}>
                      <dt>{item.title}</dt>
                      <dd>{item.text}</dd>
                    </div>
                  );
                })} */}
                  <div>
                    {otc.precautions_related_to_usage === null
                      ? " "
                      : otc.precautions_related_to_usage}
                  </div>
                </dl>
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">使用上の注意</dt>
              <dd className="p-searchMedicine__detail__text">
                <dl className="p-searchMedicine__detail__detail">
                  {/* {data.useCation.map((item, i) => {
                  return (
                    <div key={i}>
                      <dt>{item.title}</dt>
                      <dd>{item.text}</dd>
                    </div>
                  );
                })} */}
                  <div>
                    {otc.precautions_for_use === null
                      ? " "
                      : otc.precautions_for_use}
                  </div>
                </dl>
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">
                重要な基本的注意
              </dt>
              <dd className="p-searchMedicine__detail__text">
                <dl className="p-searchMedicine__detail__detail">
                  {/* {data.basicCaution.map((item, i) => {
                  return (
                    <div key={i}>
                      <dt>{item.title}</dt>
                      <dd>{item.text}</dd>
                    </div>
                  );
                })} */}
                  <div>
                    {otc.important_basic_notes === null
                      ? " "
                      : otc.important_basic_notes}
                  </div>
                </dl>
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">相互作用</dt>
              <dd className="p-searchMedicine__detail__text">
                {otc.important_basic_notes === null
                  ? " "
                  : otc.important_basic_notes}
              </dd>
            </div>
            {/* <div>
              <dt className="p-searchMedicine__detail__title">薬剤名等</dt>
              <dd className="p-searchMedicine__detail__text">
                {otc.drug_name === null ? " " : otc.drug_name}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">
                臨床症状・措置方法
              </dt>
              <dd className="p-searchMedicine__detail__text">
                {otc.clinical_symptoms === null ? " " : otc.clinical_symptoms}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">
                機序・危険因子
              </dt>
              <dd className="p-searchMedicine__detail__text">
                {otc.mechanism_riskfactors === null
                  ? " "
                  : otc.mechanism_riskfactors}
              </dd>
            </div> */}
            <div>
              <dt className="p-searchMedicine__detail__title">併用禁忌</dt>
              <dd className="p-searchMedicine__detail__text">
                <dl className="p-searchMedicine__detail__detail">
                  <div>
                    {otc.contraindications_for_concurrent_use === null
                      ? " "
                      : otc.contraindications_for_concurrent_use}
                  </div>
                </dl>
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">併用注意</dt>
              <dd className="p-searchMedicine__detail__text">
                <dl className="p-searchMedicine__detail__detail">
                  {/* {data.interactionCaution.map((item, i) => {
                  return (
                    <div key={i}>
                      <dt>{item.title}</dt>
                      <dd>{item.text}</dd>
                    </div>
                  );
                })} */}
                  <div>
                    {otc.caution_for_combined_use === null
                      ? " "
                      : otc.caution_for_combined_use}
                  </div>
                </dl>
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">副作用</dt>
              <dd className="p-searchMedicine__detail__text flex">
                {/* {data.sideEffects.map((item, i) => {
                return (
                  <div key={i}>
                    <span>{item.title}</span>
                    {item.text}
                  </div>
                );
              })} */}
                <div>{otc.side_effects === null ? " " : otc.side_effects}</div>
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">
                その他の副作用
              </dt>
              <dd className="p-searchMedicine__detail__text">
                <dl className="p-searchMedicine__detail__detail">
                  {/* <div>
                  <dt>
                    {data.otherSideEffects1.title}
                    <span>注1）</span>
                  </dt>
                  <dd>{data.otherSideEffects1.text}</dd>
                </div>
                <div>
                  <dt>{data.otherSideEffects2.title}</dt>
                  <dd>{data.otherSideEffects2.text}</dd>
                </div>
                <div>
                  <dt>{data.otherSideEffects3.title}</dt>
                  <dd>{data.otherSideEffects3.text}</dd>
                </div>
                <div>
                  <dt>{data.otherSideEffects4.title}</dt>
                  <dd>{data.otherSideEffects4.text}</dd>
                </div>
                <div>
                  <dt>{data.otherSideEffects5.title}</dt>
                  <dd>{data.otherSideEffects5.text}</dd>
                </div>
                {data.otherSideEffectsCaution && (
                  <p>注1）{data.otherSideEffectsCaution}</p>
                )} */}
                  <div>
                    {otc.other_side_effects === null
                      ? " "
                      : otc.other_side_effects}
                  </div>
                </dl>
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">
                高齢者への投与
              </dt>
              <dd className="p-searchMedicine__detail__text">
                {otc.administration_to_the_elderly === null
                  ? " "
                  : otc.administration_to_the_elderly}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">
                妊婦，産婦，授乳婦等への投与
              </dt>
              <dd className="p-searchMedicine__detail__text">
                <div>
                  {otc.administration_to_pregnant_women === null
                    ? " "
                    : otc.administration_to_pregnant_women}
                </div>
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">
                小児等への投与
              </dt>
              <dd className="p-searchMedicine__detail__text">
                {otc.administration_to_children === null
                  ? " "
                  : otc.administration_to_children}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">過量投与</dt>
              <dd className="p-searchMedicine__detail__text">
                {/* {data.overdose.map((item, i) => {
                return <div key={i}>{item}</div>;
              })} */}
                {otc.overdose === null ? " " : otc.overdose}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">適用上の注意</dt>
              <dd className="p-searchMedicine__detail__text flex">
                {/* {data.applicationCaution.map((item, i) => {
                return (
                  <div key={i}>
                    <span>{item.title}</span>
                    {item.text}
                  </div>
                );
              })} */}
                <div>
                  {otc.precautions_for_application === null
                    ? " "
                    : otc.precautions_for_application}
                </div>
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">薬物動態</dt>
              <dd className="p-searchMedicine__detail__text">
                <dl className="p-searchMedicine__detail__detail">
                  {/* {data.dynamics.map((item, i) => {
                  return (
                    <div key={i}>
                      <dt>{item.title}</dt>
                      <dd>{item.text}</dd>
                    </div>
                  );
                })} */}
                  <div>
                    {otc.pharmacokinetics === null ? " " : otc.pharmacokinetics}
                  </div>
                </dl>
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">薬効薬理</dt>
              <dd className="p-searchMedicine__detail__text">
                {otc.pharmacology === null ? " " : otc.pharmacology}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">薬理作用</dt>
              <dd className="p-searchMedicine__detail__text">
                <dl className="p-searchMedicine__detail__detail">
                  {/* {data.pharmacologicalAction.map((item, i) => {
                  return (
                    <div key={i}>
                      <dt>{item.title}</dt>
                      <dd>{item.text}</dd>
                    </div>
                  );
                })} */}
                  <div>
                    {otc.pharmacological_action === null
                      ? " "
                      : otc.pharmacological_action}
                  </div>
                </dl>
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">
                有効成分に関する理化学的知見
              </dt>
              <dd className="p-searchMedicine__detail__text flex">
                {/* {data.findings.map((item, i) => {
                return (
                  <p key={i}>
                    <span>{item.title}</span>
                    {item.text}
                  </p>
                );
              })} */}
                <div>
                  {otc.physicochemical_knowledge_about_active_ingredients ===
                  null
                    ? " "
                    : otc.physicochemical_knowledge_about_active_ingredients}
                </div>
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">包装</dt>
              <dd className="p-searchMedicine__detail__text">
                {/* {data.packaging.map((item, i) => {
                return <p key={i}>{item}</p>;
              })} */}
                <div>{otc.packaging === null ? " " : otc.packaging}</div>
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">
                主要文献及び文献請求先
              </dt>
              <dd className="p-searchMedicine__detail__text">
                {/* {data.literature.map((item, i) => {
                return <div key={i}>{item}</div>;
              })} */}
                <div>
                  {otc.main_documents_and_document_request_destinations === null
                    ? " "
                    : otc.main_documents_and_document_request_destinations}
                </div>
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">文献請求先</dt>
              <dd className="p-searchMedicine__detail__text">
                {/* {data.claim.map((item, i) => {
                return <div key={i}>{item}</div>;
              })} */}
                <div>
                  {otc.document_request_destination === null
                    ? " "
                    : otc.document_request_destination}
                </div>
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">
                投薬期間制限医薬品に関する情報
              </dt>
              <dd className="p-searchMedicine__detail__text">
                {otc.information_on_medications_with_limited_dosing_periods ===
                null
                  ? " "
                  : otc.information_on_medications_with_limited_dosing_periods}
              </dd>
            </div>
            <div>
              <dt className="p-searchMedicine__detail__title">
                製造販売業者等の氏名又は名称及び住所
              </dt>
              <dd className="p-searchMedicine__detail__text">
                {/* {data.address.map((item, i) => {
                return <div key={i}>{item}</div>;
              })} */}
                <div>
                  {otc.name_of_manufacturer_or_distributor === null
                    ? " "
                    : otc.name_of_manufacturer_or_distributor}
                </div>
              </dd>
            </div>
          </dl>
          <LinkText
            text="医薬品検索に戻る"
            url="/mypage/medicine/list"
            className="underline"
          />
        </section>
      )}
    </main>
  );
}
