import { MypageTopMenu } from "../components/ui";
import { LinkButton, History } from "../components/projects";
import { useEffect, useState } from "react";
import prescriptionAPI from "../../apis/sendprescription";
import { getAuthToken } from "../../helpers/storage";
import { Navigate } from "react-big-calendar";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import Loader from "../components/ui/loader";

// ダミーデータ
const items = [
  {
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "処方した医師の名前",
    name: "お薬の名前。お薬の名前。お薬の名前。お薬の名前。",
    limit: "YYYY年 MM月 DD日",
    red: true,
    id: 1,
  },
  {
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "処方した医師の名前",
    name: "お薬の名前。お薬の名前。お薬の名前。お薬の名前。",
    limit: "YYYY年 MM月 DD日",
    red: true,
    id: 2,
  },
  {
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "処方した医師の名前",
    name: "お薬の名前。お薬の名前。お薬の名前。お薬の名前。",
    limit: "YYYY年 MM月 DD日",
    red: true,
    id: 3,
  },
  {
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "処方した医師の名前",
    name: "お薬の名前。お薬の名前。お薬の名前。お薬の名前。",
    limit: "",
    red: true,
    id: 4,
  },
  {
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "処方した医師の名前",
    name: "お薬の名前。お薬の名前。お薬の名前。お薬の名前。",
    limit: "",
    red: true,
    id: 5,
  },
  {
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "処方した医師の名前",
    name: "お薬の名前。お薬の名前。お薬の名前。お薬の名前。",
    limit: "",
    red: false,
    id: 6,
  },
  {
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "処方した医師の名前",
    name: "お薬の名前。お薬の名前。お薬の名前。お薬の名前。",
    limit: "",
    red: false,
    id: 7,
  },
  {
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "処方した医師の名前",
    name: "お薬の名前。お薬の名前。お薬の名前。お薬の名前。",
    limit: "",
    red: false,
    id: 8,
  },
  {
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "処方した医師の名前",
    name: "お薬の名前。お薬の名前。お薬の名前。お薬の名前。",
    limit: "",
    red: false,
    id: 9,
  },
  {
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "処方した医師の名前",
    name: "お薬の名前。お薬の名前。お薬の名前。お薬の名前。",
    limit: "",
    red: false,
    id: 10,
  },
];

export default function PrescriptionHistory() {
  const [allPrescriptionData, setAllPrescriptionData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);

  const navigate = useNavigate();

  const onChange = (page) => {
    setPageNumber(page);
  };

  const getData = async () => {
    getAuthToken() === null ? navigate("/") : console.log("work");
    const response = await prescriptionAPI.getprescription(pageNumber);
    const { data } = response;
    setAllPrescriptionData(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [pageNumber]);

  if (isLoading) return <Loader></Loader>;
  return (
    <main className="p-prescription">
      <MypageTopMenu />

      <section className="p-prescription__history">
        <h2 className="c-sectionTitle c-mypageHeading">処方箋履歴</h2>
        <LinkButton
          text="処方箋の新規登録"
          url="/mypage/prescription/new"
          color="allBlue"
        />
        <ul>
          {allPrescriptionData?.results?.map((item, i) => {
              return item.red ? (
                <History key={i} item={item} class="red" />
              ) : (
                <History key={i} item={item} class="gray" />
              );
          })}
          <center>
            <Pagination
              size="medium"
              total={allPrescriptionData && allPrescriptionData.count} // set total = 1 when pagination.count = 0 or undefined to display correct
              onChange={onChange}
              current={pageNumber}
              showSizeChanger={false}
            />
          </center>
        </ul>
      </section>
    </main>
  );
}
