import { MypageTopMenu, SearchStore } from "../components/ui";
import { LinkButton, LinkText } from "../components/projects";
import moment from "moment";
import noticeAPI from "../../apis/notice";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import Loader from "../components/ui/loader";

// ダミーデータ
const item = {
  date: "2021/12/21",
  time: "13:22",
  title:
    "お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。",
  img: "/assets/img/news-dammy.jpg",
  text: "お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。お知らせ詳細テキスト。",
};

export default function MyNews() {
  const id = useParams("id");
  const [news, setNews] = useState(true);
  const [isLoading, setIsLoading] = useState();
  console.log("id", id);
  const getData = async () => {
    const response = await noticeAPI.noticeid(id.id);
    console.log(response);
    setNews(response);
    setIsLoading(false);
  };

  // useEffect(() => getData(), []);

  useEffect(() => {
    getData();
  }, []);

  if (isLoading) return <Loader />;

  console.log("inside mynews", news);

  return (
    <main className="p-newsList__mypage">
      <MypageTopMenu />

      <article className="p-news__article">
        <p className="p-news__date">
          <span>{moment(news.created_at).format("YYYY-MM-DD")}</span>
          <span>{moment(news.created_at).format("HH-mm")}</span>
        </p>
        <h2>{news.title}</h2>
        <div>
          <div className="p-news__img">
            {/* <img src={news.image_registration} alt={news.title} /> */}
          </div>
          <p className="p-news__text">{news.text}</p>
          {/* <p className="p-news__text">{item.description}</p>
          <p className="p-news__text">{item.description}</p>
          <p className="p-news__text">{item.description}</p> */}
          <LinkText
            text="お知らせ一覧に戻る"
            url="/mypage/news"
            className="underline"
          />
        </div>
      </article>

      <LinkButton
        text="じぶん薬局へのお問い合わせ"
        url="/contact"
        color="blue"
      />

      <SearchStore />
    </main>
  );
}
