import { memo } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import ReserveList from "../../pages/ReserveList";
const Reserve = memo((props) => {
  const {
    created_at,
    time,
    drugstore_name,
    message,
    is_remote,
    teach,
    red,
    id,
    is_remote_accepted,
    medication_guidance,
    is_accepted,
    is_seen_patient,
  } = props.item;

  const message1 = "New Messages";
  var date = created_at.slice(0, 10);
  var eTime = created_at.slice(11, 16);
  var edate = date.replaceAll("-", "/");

  return (
    <li className={`c-reserve ${is_remote ? "red" : "gray"}`}>
      <Link to={`/mypage/reserve/${id}`}>
        <p className="c-reserve__time">
          <span>{edate}</span>
          <span>{eTime}</span>
        </p>
        <p className="c-reserve__store">{props.item.pharmacy.drugstore_name}</p>
        {/* {message && <p className="c-reserve__message info">新着メッセージ</p>}
        新着メッセージ */}
        {!is_seen_patient && (
          <p className="c-reserve__reserve info">受取予約日の確定</p>
        )}
        {is_remote_accepted && medication_guidance && (
          <>
            {teach && (
              <p className="c-reserve__teach info">服薬指導実施日の確定</p>
            )}
          </>
        )}
      </Link>
    </li>
  );
});

export default Reserve;
