const regex = /^[0-9]+$/

export const isPhoneNumber = (value) => {
  if(!value) {
    return "電話番号は必須入力項目です。"
  } else if(!regex.test(value)) {
    return "電話番号は半角数字でご入力ください。"
  } else {
    return ""
  }
}