// 登録完了ページ
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { setLocalStorage } from "../../helpers/storage"
import { Message } from "../components/projects"
import { HowTo, SignIn } from "../components/ui"
import { setID } from "../../helpers/storage"
import { toast, ToastContainer } from "react-toastify";

export default function SignUpComp() {

  // 自動遷移（4秒後）
  const navigate = useNavigate()
  useEffect(() => {
    toast.success("会員登録が完了しました", {
      onClose: () => {
        const path = window.location.search;
        const urlParams = new URLSearchParams(path);
        const token = urlParams.get("token");
        const userId = urlParams.get("user_id");
        setLocalStorage({ token: token });
        setID(Number(userId));
        navigate("/mypage");
      },
      autoClose: 5000,
    });
  }, [navigate]);

  return (
    <main className="p-signUp">
      <ToastContainer />
      <HowTo />
      <Message 
        text="じぶん薬局への会員登録が完了しました"
      />

      <article className="c-formMessage">
        <p className="c-formMessage__bold">
          自分クリニックへの会員登録が完了しました。<br />
          しばらくお待ちいただきますと、自動的に会員用の<br />
          マイページに移動します。
        </p>

        <p>
          しばらく経っても移動しない場合には、お手数ですが、
          以下のフォームにIDとパスワードを入力いただき、
          ログインをお願い致します。
        </p>
      </article>
    
      <SignIn />

    </main>
  )
}