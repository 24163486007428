// 詳細画面で項目ごとに使用されているデザイン
import { memo } from "react";
import moment from "moment";

const ItemsList = memo((props) => {
  const {
    title,
    text,
    date,
    name,
    limit,
    red,
    prescription,
    // doctor,
    // hospital,
    // drugstore_name,
    // guidance_scheduled_time,
  } = props.item;
  // console.log("props.item", props.item);

  // var red = true;

  console.log("text");
  console.log(`${text}` === "true");
  console.log("prescriptions", prescription);

  return (
    <div className="c-itemsList">
      <dt>{title}</dt>
      {red ? (
        prescription.map((item) => {
          return (
            <dd className="c-itemsList__red">
              <p>
                <span className="c-mypageList__time">
                  {moment(item?.prescription_date).format("YYYY年MM月DD日")}
                </span>
                <span className="c-itemsList__limit">
                  有効期限 &#058;{" "}
                  {moment(
                    moment(item?.prescription_date).format("YYYY年MM月DD日"),
                    "YYYY年MM月DD日"
                  )
                    .add(4, "days")
                    .format("YYYY年MM月DD日")}
                  まで
                </span>
                <div>
                  <h1>works</h1>
                  <p className="c-mypageList__hospital">
                    {item?.medical_institution_name}
                    <span>{item?.prescribing_physician}</span>
                    <p className="c-mypageList__name">{item?.name}</p>
                  </p>
                </div>
              </p>
            </dd>
          );
        })
      ) : (
        <dd className="c-itemsList__text">{`${text}`}</dd>
      )}
    </div>
  );
});

export default ItemsList;

{
  /* <p className="c-mypageList__hospital">
            {props.item.prescription[0].medical_institution_name}
            <span>{props.item.prescription[0].prescribing_physician}</span>
          </p> */
}
