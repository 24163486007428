import { useEffect } from "react";
import { Navigate } from "react-big-calendar";
import { Link, useNavigate } from "react-router-dom";
import { getFromLocalStorage } from "../../helpers/storage";

import {
  NewsList,
  TipsList,
  SignUpLink,
  Message,
} from "../components/projects";
import { SearchStore } from "../components/ui";

// お知らせダミーデータ
const newsItems = [
  {
    date: "2021/12/21",
    time: "13:22",
    text: "お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。",
    id: 1,
  },
  {
    date: "2021/12/21",
    time: "13:22",
    text: "お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。",
    id: 2,
  },
];

export default function Thanks() {
  const navigate = useNavigate();

  const setPage = () => {
    console.log("breaker");
    setTimeout(() => {
      getFromLocalStorage == null ? navigate("/") : navigate("/");
    }, 4000);
  };

  useEffect(() => setPage(), []);

  return (
    <main className="p-thanks">
      <Message text="じぶん薬局へのお問い合わせを受け付けました" />

      <article className="c-formMessage">
        <p className="c-formMessage__bold">
          お問い合わせ内容を確認の上、担当者よりご返信致します。
        </p>
        <p>
          お問い合わせ後、5営業日以内に弊社からの返信がない場合、
          お問い合わせが到着していないなどの場合がございます。
          <br />
          その際は、お手数ですが、以下のメールアドレスまで、
          お問い合わせをお願い致します。
        </p>
        <p>「ingo@myclinique.com」</p>
      </article>

      {/* <SignUpLink /> */}

      {/* <section className="p-home__news">
        <h2 className="c-sectionTitle">じぶん薬局からのお知らせ</h2>
        <dl>
          {newsItems.map((item, i) => {
            return (
              <NewsList 
                key={i}
                to={`/news/${item.id}`}
                item={item}
              />
            )
          })}
        </dl>
      </section> */}

      {/* <section className="p-home__tips">
        <h2 className="c-sectionTitle">おくすりTips</h2>
        <TipsList 
          number={4}
        />
        <div className="c-textLinkBlue">
          <Link to='/'>おくすりTipsのコンテンツ一覧をみる</Link>
        </div>
      </section> */}

      <SearchStore />
    </main>
  );
}
