import { memo } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const MedicinePageList = memo((props) => {
  const { item, to } = props;

  return (
    <div>
      <Link to={to} className="c-newsPageList">
        <dt>
          <span>{moment(item.created_at).format("YYYY-MM-DD")}</span>
          <span>{moment(item.created_at).format("HH:mm")}</span>
        </dt>
        <dd>{item?.title}</dd>
      </Link>
    </div>
  );
});

export default MedicinePageList;
