import { memo } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const MedList = memo(({ item, to }) => {
  return (
    <Link to={to} className="p-articleList">
      <dt>
        <span className="p-articleList__date">
          {/* {moment(item.created_at).format("YYYY-MM-DD")} */}
          {item.created_at.substring(0,10)}
        </span>
        <span className="p-articleList__date">
          {/* {moment(item.created_at).format("h:mm:ss")} */}
          {item.created_at.substring(11,16)}
        </span>
      </dt>
      <dd>{item.title}</dd>
    </Link>
  );
});

export default MedList;
