import { memo, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { getDateFromWPValue, getTimeFromWPValue } from "../../../utils/hooks"
import { URL } from "../../../utils/data";

const TipsPageList = memo((props) => {
  const { id, type, number } = props

  const [ data, setData ] = useState({isLoaded: false, items: '', error: false})

  useEffect(() => {
    const url = URL
    let endpoint
    if (type === 'category') {
      endpoint = `posts?categories=${id}&per_page=${number}`
    }
    const dataURL = url + "wp-json/wp/v2/" + endpoint
    fetch(dataURL, {method: "GET"})
    .then(res => res.json())
    .then(data => {
      setData({
        isLoaded: true,
        items: data,
        error: false
      })
    })
    .catch(error => {
      console.log('error', error)
      setData({
        isLoaded: true,
        items: '',
        error: true
      })
    })

  }, [id, number, type])

  return (
    <dl>
      {data.isLoaded && !data.error &&
        data.items.map((item, i) => {
          return (
            <div key={i} className="c-tipsPageList">
              <Link to={`/tips/${item.id}`}>
                <div className="c-tipsPageList__top">
                  <p>
                    <span>{getDateFromWPValue(item.date)}</span>
                    <span>{getTimeFromWPValue(item.date)}</span>
                  </p>
                  <span>{item.category_name}</span>
                </div>
                <dt>{item.title.rendered}</dt>
                <dd
                  dangerouslySetInnerHTML={{
                    __html: item.excerpt.rendered.replace('[&hellip;]', '')
                  }} />
              </Link>
            </div>
          )
        })
      }
      {data.isLoaded && data.error &&
        <div className="p-articleList__error">
          <dt>データの取得に失敗しました。</dt>
          <dd>運営までお問い合わせください。</dd>
        </div>
      }
    </dl>
  )

})

export default TipsPageList