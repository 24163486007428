import { useState, memo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import drugStoreAPi from "../../../apis/drugstore";
import { getFromLocalStorage } from "../../../helpers/storage";
import { Contextdata } from "../../../UserContext";

import { prefectures } from "../../../utils/data";

import { MainButton } from "../projects";

const SearchStore = memo(() => {
  const { prescriptionData, setPrescriptionData } = useContext(Contextdata);

  // 市区町村の取得（国土交通省）
  const [city, setCity] = useState([{ name: "都道府県を選択してください" }]);
  const handleChangeSelect = (e) => {
    const code = e.target.value;
    console.log("code", code);

    if (code !== "") {
      fetch(`https://www.land.mlit.go.jp/webland/api/CitySearch?area=${code}`)
        .then((res) => res.json())
        .then((json) => {
          setCity(json.data);
        })
        .catch((error) => {
          // エラー処理
          console.log("error", error);
        });
    } else if (code === "") {
      setCity([{ name: "都道府県を選択してください" }]);
    }
  };

  // 検索ボタン押下
  const navigate = useNavigate();
  const handleSubmit =  (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const values = Object.fromEntries(data.entries());
    let prefecture = "";
    // if (values.prefecture) {
    //   prefecture = prefectures[values.prefecture - 1].name;
    // }
    // const url = `/stores?prefecture=${prefecture}&city=${values.city}&word=${values.word}`;

    // const prefecture_id = parseInt(values.prefecture_name_jp);

    // const prefecture_name = prefectures[prefecture_id - 1].name;

    // const sendData = {
    //   ...values,
    // };

    // sendData.prefecture_name_jp = prefecture_name;

    // console.log(sendData);

    // const response = await drugStoreAPi.storeSearch(
    //   sendData.prefecture_name_jp,
    //   sendData.city,
    //   sendData.drugstore_name
    // );

    // console.log("response", response);
    // setPrescriptionData(response);
    // navigate(url);


  
    var response;
    const prefecture_name=prefectures.filter((item)=>(item.code === values.prefecture_name_jp));

    let prefectureName = '';
    if (prefecture_name.length > 0) {
      prefectureName = prefecture_name[0].name
    }
    response=drugStoreAPi.storeSearch(values, prefectureName)
    response.then((res)=>{
      setPrescriptionData(res.data);
    })
    setTimeout(()=>{
      navigate("/stores");
    },1000)

  };

  return (
    <section className="p-searchStore">
      <h2>ドラッグストア検索</h2>
      <form onSubmit={handleSubmit}>
        <p>あなたの近所の薬局・薬店・ドラッグストアを検索できます。</p>
        <div className="p-searchStore__box1">
          <div className="c-selectBox__container black">
            <select
              name="prefecture_name_jp"
              onChange={(e) => handleChangeSelect(e)}
            >
              <option value="">都道府県</option>
              {prefectures.map((item, i) => {
                return (
                  <option value={item.code} key={i}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="c-selectBox__container black">
            <select name="city">
              <option value="">市区町村</option>
              {city.map((item, i) => {
                return (
                  <option value={item.name} key={i}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="p-searchStore__box2">
          <input
            type="text"
            name="drugstore_name"
            placeholder="ドラッグストア名を入力してください"
            className="c-input"
          />
        </div>

        <MainButton text="検索する" color="black" />
      </form>
    </section>
  );
});

export default SearchStore;
