export const isEntered = (name, value, select) => {
  if (!value && !select) {
    return `${name}は必須入力項目です。`;
  } else if (!value && select) {
    return `${name}は必須選択項目です。`;
  } else if (!select && typeof value == "object" && value.name === "") {
    return `${name}は必須選択項目です。`;
  } else if (name === "保険者番号" && value) {
    //  const checkChar1=/^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{6,}$/g.test(value)
    //  const checkChar2=/^[0-9]+$/.test(value);
    //  const checkChar3=/^[a-zA-Z]+$/.test(value);

    const checkChar1 = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value);
    const checkChar2 = /\d/.test(value);
    const checkChar3 = /[a-zA-Z]/.test(value);
    const checkChar4 =
      /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/.test(
        value
      );

    if (
      (checkChar2 && checkChar3 && !checkChar1) ||
      (checkChar4 && checkChar2 && !checkChar1)
    )
      return "";

    return "半幅の英数字を追加してください";
  } else if (name === "連絡先" && value) {
    const checkChar1 = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value);
    const checkChar2 = /\d/.test(value);
    const checkChar3 = /[a-zA-Z]/.test(value);
    const checkChar4 =
      /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/.test(
        value
      );

    if (
      (checkChar2 && checkChar3 && !checkChar1) ||
      (checkChar4 && checkChar2 && !checkChar1)
    )
      return "";

    return "半幅の英数字を追加してください";
  } else {
    return "";
  }
};
