import axios from "axios";
import * as qs from 'qs'
// log in
const getAccesstokenLine = async (code) =>{
    var data = qs.stringify({
        'grant_type': 'authorization_code',
       'code': `${code}`,
       'client_id': '1660823504',
       'client_secret': '95e04ee602211ff25456393b8a07713e',
       'redirect_uri': window.location.origin
       });

      return axios.post("https://api.line.me/oauth2/v2.1/token", data);

}
  
const LineAccessToken = {
    getAccesstokenLine,
};

export default LineAccessToken;
