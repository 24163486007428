import axios from 'axios'
import { getAuthToken } from '../helpers/storage'

const search = config =>
  axios.get(`https://dev-api-pharmacy.automagicjapan.tech/api/pharma_otc/search/`, config)

const searchOCR = data => {
  const token = getAuthToken()
  return axios.post(
    `https://dev-api-pharmacy.automagicjapan.tech/api/drug_ocr/search/`,
    data,
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  )
}
const otcAPI = {
  search,
  searchOCR,
}

export default otcAPI
