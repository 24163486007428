
import axios from "axios";
import { getAuthToken, getID } from "../helpers/storage";


const id = getID()
var response;

const userEditApi1 = async (values) => {
  
  let token = getAuthToken();
  token = `token ${token}`
  const gender = (values.gender == "男") ? "male" : (values.gender == "女") ? "female": "";

  var dataForm = JSON.stringify({
    name: values.userName,
    nickname: values.nickname,
    dob: values.birthday,
    phone_no: values.phoneNumber,
    email: values.email,
    gender: gender,
    allergy_info: values.allergy,
    allergy_reactive: values.drugAllergy,
    type: 6,
    user: 7,
    detail: "fff",
  });

  var config = {
    method: "put",
    url: `https://dev-api-pharmacy.automagicjapan.tech/api/patient_api/${id}/`,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    data: dataForm,
  };

  response = await axios(config);

  return response;
};

const userEditApi2 = async (values, fileUpload) => {
  
const token = "token" + " " + getAuthToken();
  var insurerName,
    insurerAddress,
    delivary_date,
    office,
    symbol,
    householdname,
    limits,
    issure;
  console.log(values);

  var data = new FormData();

  if (values.hasOwnProperty("limit")) limits = values.limit;
  else limits = "";

  if (values.hasOwnProperty("insuranceCode")) symbol = values.insuranceCode;
  else {
    symbol = values.insuranceCode1 + " " + values.insuranceCode2;
  }

  if (values.hasOwnProperty("delivary")) delivary_date = values.delivary;
  else if (values.hasOwnProperty("delivary1")) delivary_date = values.delivary1;

  console.log(delivary_date);

  if (
    values.hasOwnProperty("insurer") &&
    values.hasOwnProperty("insurerAddress")
  ) {
    insurerName = values.insurer;
    insurerAddress = values.insurerAddress;
  } else {
    insurerName = "";
    insurerAddress = "";
  }

  if (values.hasOwnProperty("office")) {
    office = values.office;
  } else office = "";

  if (values.hasOwnProperty("household")) householdname = values.household;
  else householdname = "";

  if (values.hasOwnProperty("issure")) issure = values.issure;
  else issure = " ";

  console.log("limit is", limits);

  data.append("card_choices ", "1");
  data.append("card_image", fileUpload);
  data.append("symbol", symbol);
  data.append("insurance_number", values.insuranceNumber);
  data.append("patient ", "3");
  data.append("date_of_issuance", delivary_date);
  data.append("expiration_date", limits);
  data.append("family_dispensing_pharmacy", values.store);
  data.append("family_medical_institution", values.hospital);
  data.append("insurer_location", insurerAddress);
  data.append("insurer_name", insurerName);
  data.append("office_name", office);
  data.append("pharmacist_in_charge", values.pharmacist);
  data.append("underlying_disease", values.disease);
  data.append("name_of_head_of_household", householdname);
  data.append("recipient_name", issure);
  data.append("date_of_qualification", values.getDate);

  var config = {
    method: "put",
    url: `https://dev-api-pharmacy.automagicjapan.tech/api/patient_insurance_api/${id}/`,
    headers: {
      Authorization: token,
    },
    data: data,
  };

  response = await axios(config);
  return response;
};
export const userEditApi3 = async (values) => {
  
const token = "token" + " " + getAuthToken();
  var data = JSON.stringify({
    id: 5,
    address: values.postNumber,
    city: values.city,
    prefecture: values.prefecture,
    telephone: values.address,
    patient: 9,
  });

  var config = {
    method: "put",
    url: `https://dev-api-pharmacy.automagicjapan.tech/api/address_api/${id}/`,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  response = await axios(config);
  return response;
};

export const fetchpatientApi = async (headers, id) => {
  
const token = "token" + " " + getAuthToken();
  response = await axios.get(
    `https://dev-api-pharmacy.automagicjapan.tech/api/patient_information/${id}/`,
    { headers: headers }
  );

  return response;
};

export const fetchpatientInsApi = async (headers, id) => {
  
const token = "token" + " " + getAuthToken();
  response = await axios.get(
    `https://dev-api-pharmacy.automagicjapan.tech/api/patient_insurance_api/${id}/`,
    { headers: headers }
  );
  return response;
};

export const fetchpatientAddressApi = async (headers, id) => {
  
const token = "token" + " " + getAuthToken();
  response = await axios.get(
    `https://dev-api-pharmacy.automagicjapan.tech/api/address_api/${id}/`,
    { headers: headers }
  );
  return response;
};

export const userDetails = async (headers, id) => {
  
const token = "token" + " " + getAuthToken();
  response = await axios.get(
    `https://dev-api-pharmacy.automagicjapan.tech/api/patient_information/${id}/`
  );

  return response;
};


const userDetailsEdit=async (values,detailsId,oldPatient,patientId)=>{
  
const token = "token" + " " + getAuthToken();
  console.log(detailsId);

  console.log(values)
  var insurerName,
    insurerAddress,
    delivary_date,
    office,
    symbol,
    householdname,
    limits,
    issure;
  console.log(values);

  const DataForm=new FormData()

  const gender = values.gender == "男" ? "male"  : (values.gender == "女") ? "female": "";

  if (values.hasOwnProperty("limit")) 
  limits = values.limit;
  else 
  limits = "";

  if (values.hasOwnProperty("insuranceCode")) symbol = values.insuranceCode;
  else {
    symbol = values.insuranceCode1 + " " + values.insuranceCode2;
  }

  if (values.hasOwnProperty("delivary")) delivary_date = values.delivary;
  else if (values.hasOwnProperty("delivary1")) delivary_date = values.delivary1;

  console.log(delivary_date);

  if (
    values.hasOwnProperty("insurer") &&
    values.hasOwnProperty("insurerAddress")
  ) {
    insurerName = values.insurer;
    insurerAddress = values.insurerAddress;
  } else {
    insurerName = "";
    insurerAddress = "";
  }

  if (values.hasOwnProperty("office")) {
    office = values.office;
  } else office = "";

  if (values.hasOwnProperty("household")) householdname = values.household;
  else householdname = "";

  if (values.hasOwnProperty("issure")) issure = values.issure;
  else issure = " ";

  console.log(values);


  DataForm.append("name",values.userName);
  DataForm.append("nickname",values.nickname);
  DataForm.append("dob",values.birthday);
  DataForm.append("email",values.email)
  //DataForm.append("phone_no",9886768);
  DataForm.append("phone_no",values.phoneNumber);
  DataForm.append("gender",gender);
  DataForm.append("allergy_info",values.allergy);
  DataForm.append("allergy_reactive",values.drugAllergy)

  console.log(limits)

  const tempObj1={
  
    address: values.postNumber+","+values.address,
    city:values.city,
    prefecture: values.prefecture,
    telephone: values.phoneNumber,
  }

  const tempObj2={
      
    symbol: symbol,
    date_of_qualification: values.getDate,
    insurance_number: values.insuranceNumber,
    underlying_disease:values.disease,
    family_medical_institution: values.hospital,
    family_dispensing_pharmacy: values.store,
    card_choices: limits!=""?2: 1,
    pharmacist_in_charge: values.pharmacist,
    date_of_issuance: values.delivary,
   }

  if(oldPatient){
    tempObj1.id=detailsId.addressId
    tempObj2.id=detailsId.insuranceId
   
  }
  if(limits!=""){
    tempObj2.expiration_date=limits;
    tempObj2.name_of_head_of_household=householdname;
    tempObj2.recipient_name=issure;
  }
  else{
    tempObj2.office_name=office;
    tempObj2.insurer_name=insurerName;
    tempObj2.insurer_location=insurerAddress;
  }

  DataForm.append("address",JSON.stringify(tempObj1))
  DataForm.append("patient_insurance",JSON.stringify([tempObj2])
  
)


console.log(token);


  const config={
    method: "put",
    url: `https://dev-api-pharmacy.automagicjapan.tech/api/patient_api/${patientId}/`,
    headers: {
      "Authorization" : token,
      "Content-Type": "application/json"
    },
    data: DataForm
  }
  var reponse;
  response=await axios(config)
  return response;
    
  
  
}

export const userEdit = {
 
  fetchpatientApi,
  fetchpatientInsApi,
  fetchpatientAddressApi,
  userDetails,
  userDetailsEdit,
};
