import { useContext, useEffect, useState } from "react";
import { MainButton, LinkButton, SignUpLink } from "../components/projects";
import { SearchStore } from "../components/ui";
import { Contextdata } from "../../UserContext";
import { useParams } from "react-router-dom";
import drugStoreAPi from "../../apis/drugstore";
import { addToFavorite } from "../../apis/favorities";
import { getAuthToken } from "../../helpers/storage";
import useNavigateLogin from "../../utils/hooks/useNavigateLogin";
import { ToastContainer, toast } from "react-toastify";

const userId=window.localStorage.getItem("id") ? window.localStorage.getItem("id") : null;



export default function Store() {
  const { userData } = useContext(Contextdata);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const id = useParams();
  const navigateLogin = useNavigateLogin();
  const token = getAuthToken();
  const getData = async () => {
    try {
      if (!getAuthToken()) {
        navigateLogin();
      }
      const response = await drugStoreAPi.storeDetail(id.id);
      setData(response);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
    
  };

  const handleSubmit = async () => {
    try {
      if (!getAuthToken()) {
        navigateLogin();
      }
      const drugstore = id.id;
      const is_favorite = 1;
      const user = userId;
      const response = await addToFavorite( drugstore, is_favorite, user);
      toast.success('登録ができました。', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        window.location.href = "/stores?searchAll=true";
      }, 3000);
      console.log(response);
    } catch (error) {
      console.error(error);
      toast.error('登録に失敗しました。', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };




  useEffect(() => {
    getData();
  }, []);


  const renderStoreAddress = (item) => {
    return (
      <div>
        {item?.postal_code && <div>〒{item?.postal_code}</div>}
        <div>
          {item?.prefectures?.prefecture_name_jp || ""} {item?.city || ""}{" "}
          {item?.address || ""}
        </div>
      </div>
    );
  };

  if (isLoading) return <></>;
  return (
    <main className="p-store">
      <ToastContainer limit={3} />
      <section className="p-store__detail">
        <h2>{data.data.drugstore_name}</h2>
        <div className="p-store__map">
          <div></div>
          <button>
            <span>大きな地図でみる</span>
          </button>
        </div>
        <dl>
          <div className="p-store__content">
            <dt className="c-contentTitle">住所</dt>
            <dd>{renderStoreAddress(data.data)}</dd>
          </div>
          <div className="p-store__content">
            <dt className="c-contentTitle">営業時間</dt>
            <dd>{data.data.time_raw == null ? "" : data.data.time_raw}</dd>
            {/* <dd>《平日》{data.data.time1_start}</dd>
            <dd>《土・日・祝日》{data.data.time1_end}</dd> */}
          </div>
          <div className="p-store__content">
            <dt className="c-contentTitle">取り扱い</dt>
            <div className="c-stores__note">
              {/* {item.note.map((note, i) => {
                return <dd key={i}>{note}</dd>;
              })} */}
              {data.data.handling_classification}
            </div>
          </div>
          <div className="p-store__content">
            <dt className="c-contentTitle">電話番号</dt>
            <div className="p-store__phone">
              <dd>{data.data.telephone_number}</dd>
              <dd>{data.data.fax} (FAX)</dd>
            </div>
          </div>
          <div className="p-store__content">
            <dt className="c-contentTitle">ホームページ</dt>
            <dd>
              {/*
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  {item.url}
                </a>
              */}
            </dd>
          </div>
        </dl>
        <MainButton
          text="かかりつけ薬局に登録する"
          color="black"
          onClick={handleSubmit}
        />
      </section>

      <SearchStore />
      {!token && <SignUpLink />}
      <LinkButton
        text="じぶん薬局へのお問い合わせ"
        url="/contact"
        color="blue"
      />
    </main>
  );
}
