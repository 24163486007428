import moment from "moment";
import { useEffect, useState } from "react";
import noticeAPI from "../../apis/notice";
import { todayDate } from "../../utils/data";
import { getDateFromValue } from "../../utils/hooks";
import { NewsPageList as Lists, LinkButton } from "../components/projects";
import { MypageTopMenu, SearchStore } from "../components/ui";
import Loader from "../components/ui/loader";
import { Pagination } from "antd";

// お知らせダミーデータ
const newsItems = [
  {
    date: "2021/12/21",
    time: "13:22",
    text: "お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。",
    id: 1,
  },
  {
    date: "2021/12/21",
    time: "13:22",
    text: "お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。",
    id: 2,
  },
  {
    date: "2021/12/21",
    time: "13:22",
    text: "お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。",
    id: 3,
  },
  {
    date: "2021/12/21",
    time: "13:22",
    text: "お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。",
    id: 4,
  },
  {
    date: "2021/12/21",
    time: "13:22",
    text: "お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。",
    id: 5,
  },
];

export default function MyNewsList() {
  const [isLoading, setisLoading] = useState(true);
  const [news, setNews] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  const onChange = (page) => {
    setPageNumber(page);
  };

  const getData = async () => {
    const response = await noticeAPI.notice(pageNumber);
    console.log("response", response);
    setNews(response && response);
    setisLoading(false);
  };

  const todaysDate = moment(moment().toDate()).format("YYYY-MM-DD");
  useEffect(() => getData(), [pageNumber]);
  if (isLoading) return <Loader />;

  return (
    <main className="p-newsList__mypage">
      <MypageTopMenu />

      <section>
        <h2 className="c-sectionTitle c-mypageHeading">
          じぶんクリニックからのお知らせ
        </h2>
        <dl>
          {news?.data.results.map((item, i) => (
            <Lists key={i} to={`/news/${item.id}`} item={item} />
          ))}
        </dl>
        <center>
          <Pagination
            size="medium"
            total={news && news.data.count}
            onChange={onChange}
            current={pageNumber}
            showSizeChanger={false}
          />
        </center>
      </section>

      <LinkButton
        text="じぶん薬局へのお問い合わせ"
        url="/contact"
        color="blue"
      />

      <SearchStore />
    </main>
  );
}
