import moment from "moment";
import { memo } from "react";

const MemoDisplay = memo(({ item }) => {
  const {
    title,
    text,
    date,
    hospital,
    doctor,
    name,
    limit,
    red,
    memo_scheduled_time,

    prescription,
  } = item;

  console.log("item in description", item);

  return (
    <div className="c-itemsList">
      <dt>{title}</dt>
      {red ? (
        prescription?.map((item) => {
          return (
            <dd className="c-itemsList__red">
              <p>
                <span className="c-mypageList__time">
                  {moment(item?.prescription_date).format("YYYY年MM月DD日")}
                </span>
                <span className="c-itemsList__limit">
                  有効期限 &#058;{" "}
                  {moment(
                    moment(item?.prescription_date).format("YYYY年MM月DD日"),
                    "YYYY年MM月DD日"
                  )
                    .add(4, "days")
                    .format("YYYY年MM月DD日")}
                  まで
                </span>
                <div>
                  <h1>works</h1>
                  <p className="c-mypageList__hospital">
                    {item?.medical_institution_name}
                    <span>{item?.prescribing_physician}</span>
                    <p className="c-mypageList__name">{item?.name}</p>
                  </p>
                </div>
              </p>
            </dd>
          );
        })
      ) : (
        <dd className="c-itemsList__text">{`${text}`}</dd>
      )}
    </div>
  );
});

export default MemoDisplay;
