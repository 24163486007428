import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { todayDate } from "../../utils/data";
import { MypageTopMenu } from "../components/ui";
import { useContext } from "react";
import { Contextdata } from "../../UserContext";
import Loader from "../components/ui/loader";
import { Pagination } from "antd";

import {
  FormBorderInput,
  FormDateTimeInput,
  FormCheckBox,
  MainButton,
  LinkText,
  FormRadio,
} from "../components/projects";
import { isEntered, isChecked } from "../../utils/validation";
import reserveAPI from "../../apis/reserve";
import getReceipt from "../../apis/prescriptionreceipt";
import {
  getAuthToken,
  getID,
  getLocaldrop,
  setLocaldrop,
} from "../../helpers/storage";
import moment from "moment";
import { fontSize, fontWeight } from "@mui/system";
// import $ from "jquery";
import { backdropClasses } from "@mui/material";
import { getFavorite } from "../../apis/favorities";
import { ToastContainer, toast } from "react-toastify";

// ダミーデータ
const items = [
  {
    title: "申請する処方箋",
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "処方した医師の名前",
    name: "お薬の名前。お薬の名前。お薬の名前。お薬の名前。",
    limit: "YYYY年 MM月 DD日",
    id: "1",
  },
  {
    title: "申請する処方箋",
    date: "2021/12/21",
    hospital: "医療機関名",
    doctor: "処方した医師の名前",
    name: "お薬の名前。お薬の名前。お薬の名前。お薬の名前。",
    limit: "YYYY年 MM月 DD日",
    id: "2",
  },
];

export default function ReserveInput() {
  const [toggle, setToggle] = useState(false);
  const [enable, setEnable] = useState(false);
  const [chosenPres, setChosenPres] = useState([]);
  const [dropdownCount, setdropdownCount] = useState(0);
  const { storeName, setStoreName } = useContext(Contextdata);
  const [isLoading, setIsLoading] = useState(true);
  const [checkDisabledButtonCreate, setCheckDisabledButtonCreate] = useState(false);
  const [drp, setdrp] = useState();
  const [pageNumberPrescription, setPageNumberPrescription] = useState(1);

  const drugstoreName = window.sessionStorage.getItem("drugstore");

  // if (drugstoreName != undefined && dropdownCount == 0) {
  //   setTimeout(() => {
  //     $("#dropdown_id option")
  //       .toArray()
  //       .map((item, i) => {
  //         if (item.text == drugstoreName)
  //           document.getElementById("dropdown_id").selectedIndex = i;
  //       });

  //     setdropdownCount(dropdownCount + 1);
  //   }, 1000);
  // }

  setStoreName(drugstoreName);

  const initialValue = todayDate;

  // リモート服薬指導の処方箋受け取り方法     //How to receive a prescription for remote medication instruction
  const options = [
    {
      label: "郵送で処方を受け取る",
      value: "郵送",
      initial: true,
    },
    {
      label: "別の予約で受け取る",
      value: "予約",
      initial: false,
    },
  ];

  // リモート指導を希望するかどうか        //Whether you want remote instruction
  const [remortDetail, setRemortDetail] = useState(false);
  const handleChecked = (e) => {
    setRemortDetail(e.target.checked);
  };

  // 処方箋チェックボックス        //Prescription checkbox
  const [checkedItems, setCheckedItems] = useState({});
  const handleChange = (e) => {
    setCheckedItems({
      ...checkedItems,
      [e.target.value]: e.target.checked,
    });
  };
  const onChangePagePrescription = (page) => {
    setPageNumberPrescription(page);
  };
  // バリデーション      //Validation
  const [errorMessages, setErrorMessages] = useState({});
  const isValidate = (values) => {
    let messages = {};
    const storeName = document.getElementById("dropdown_id");
    
    const text = storeName.options[storeName.selectedIndex].text;

    messages.storeName = isEntered(
      "かかりつけ（薬局・薬店）",
      text,
      false
    );
    messages.reservationDateTime =
      isEntered("予約申請日時", values.reservationDate, false) ||
      isEntered("予約申請日時", values.reservationTime, false);
    messages.prescription = isChecked(values, "prescription")
      ? ""
      : "受け取り予約申請する処方箋を最低1つ選択してください。";
    messages.teachDateTime = remortDetail
      ? isEntered("予約申請日時", values.teachDate, false) ||
        isEntered("予約申請日時", values.teachTime, false)
      : "";

    setErrorMessages({ ...messages });
    return Object.values(messages).every((message) => message === "");
  };

  // 確認画面  //confirmation screen
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState("");

  const [data, setData] = useState([]);
  // console.log(data);
  useEffect(() => {
    if (getAuthToken() === null) {
      navigate("/");
    }

    const token = "token " + getAuthToken();
    const headers = {
      Authorization: token,
    };
    const response = reserveAPI.reserveGet(headers, pageNumberPrescription); //rendering dr cooger
    // console.log(response);
    response.then((res) => {
      // console.log(res);

      setData(res.data);
      setChosenPres(res.data.results);
    });
  }, [pageNumberPrescription]);

  const [get, setGet] = useState([]);
  // console.log(get);
  useEffect(() => {
    const token = "token " + getAuthToken();
    const headers = {
      Authorization: token,
    };
    const response = reserveAPI.receivePrescriptionGet(headers); //for radio button
    // console.log(response);
    response.then((res) => {
      // console.log("ggggg");
      // console.log(res);
      setGet(res.data);
    });
  }, []);

  const [drop, setDrop] = useState([]);
  const [droparr, setDroparr] = useState();
  const [drugstore, setDrugStore] = useState("");
  const [prescription, setPrescription] = useState([]);
  
  const [val, setVal] = useState({});

  const token = "token " + getAuthToken();
  const headers = {
    Authorization: token,
  };
  const getData = async () => {
    const userId= window.localStorage.getItem("id") ? window.localStorage.getItem("id") : null;
    var response = await getFavorite(userId);
    setdrp(response?.map((favoriteStore) => {
      return { drugstore_name: favoriteStore.drugstore_name, id: favoriteStore.drugstore}
    }) ?? []);
    setIsLoading(false);
  };

  useEffect(
    () => getData(),

    // console.log("droparr", droparr);

    //  console.log("drop");
    // console.log(response);
    // response.then((res) => {
    //   console.log(res);
    //   setDrop(res.data.results);
    // }
    // )

    []
  );

  const [selectedPres, setSelectedPres] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const values = Object.fromEntries(data.entries());
    setVal(values);

    const drugStoreData = document.getElementById("dropdown_id");
    const text = drugStoreData.options[drugStoreData.selectedIndex].text;
    // const value = drugStoreData.options[drugStoreData.selectedIndex].value;

    window.sessionStorage.setItem("drugstore", text);
    setStoreName(text);

    setDrugStore(text);
    const selectedPres = document.getElementsByName("prescription");

    const temp_arr = [];
    for (var i = 0; i < selectedPres.length; i++) {
      if (selectedPres[i].checked) {
        for (var j = 0; j < chosenPres.length; j++) {
          if (chosenPres[j].id === selectedPres[i].value) {
            temp_arr.push(chosenPres[i]);
          }
        }
      }
    }

    setSelectedPres(temp_arr);

    // postData(values);

    // const ogValues = { ...values };

    // チェックボックス の値取得       //Get the value of a checkbox
    if (values.prescription) {
      delete values.prescription;
      const checkedValue = Object.entries(checkedItems).reduce(
        (pre, [key, value]) => {
          value && pre.push(key);
          return pre;
        },
        []
      );
      values.prescription = checkedValue;
    }

    // console.log(206)

    //function start

    if (isValidate(values)) {
      setOpen((prevState) => !prevState);

      setFormValues(values);

      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    // リモート服薬指導を希望しない場合     //If you do not wish to receive remote medication guidance
    if (!remortDetail) {
      delete values.teachDateTime;
      delete values.receive;
    }

    // if (isValidate(values)) {
    //   setFormValues(values);
    //   window.scrollTo({ top: 0, behavior: "smooth" });
    //   setOpen((prevState) => !prevState);
    // }
    // navigate("/mypage/reserve");
    // window.location.reload();
  };

  // 確認画面を閉じる   //Close the confirmation screen
  const handleClickClose = () => {
    setOpen((prevState) => !prevState);
  };

  // 申請   //Application
  const navigate = useNavigate();
  const handleCreate = () => {
    var values = val;
    var reservationDate = values.reservationDate;
    var reservationTime = values.reservationTime;
    var teachDate = values.teachDate;
    var teachTime = values.teachTime;

    var newDate = new Date();
    var reservation =
      reservationDate +
      "T" +
      reservationTime +
      ":" +
      newDate.getSeconds() +
      ".00Z";
    console.log(reservation);

    var guidance =
      teachDate + "T" + teachTime + ":" + newDate.getSeconds() + "Z";
    console.log(guidance);

    var dropdown_id = document.getElementById("dropdown_id").value;
    console.log(dropdown_id);

    var receiveId = 1;
    var rec_radio;
    var rate = values.rate;

    if (rate === "郵送") {
      rec_radio = document.querySelector('input[name="rate"]:checked').value;
      if (rec_radio === "郵送") {
        receiveId = 1;
      }
    } else if (rate === "予約") {
      receiveId = 2;
    }

    console.log(receiveId);

    // console.log("rrrrrrrr")
    // console.log(values.medication_guidence)
    //dropdown:pharmacy:id(pass id)

    var pres_arr = [];

    //commented part

    // console.log(values.prescription[0]);
    // if (values.prescription[0] == "1") {
    //   pres_arr = [12];
    // } else {
    //   pres_arr = [1];
    // }
    // console.log("llllllllll");
    // console.log(pres_arr);

    var medical;
    if (values.hasOwnProperty("receive")) {
      medical = true;
    } else {
      medical = false;
    }

    // const headers = {
    //   Authorization: "token 614bf272df3a2f10769624c9f7e351a64fd17cc1",
    //   "Content-Type": "application/json",
    // };

    const data_object = {
      message: values.message, //message
      prescription: pres_arr, //prescription field
      receive_prescription_by: receiveId, //radio button
      reservation_scheduled_time: reservation, //time
      medication_guidence: medical, //checkbox
      guidance_scheduled_time: guidance, //time
      pharmacy: dropdown_id, //dropdown
    };
    // const id = getID("id");
    const pharmacy = parseInt(data_object.pharmacy);
    data_object.pharmacy = pharmacy;
    //data_object.id = id;
    // data_object.guidance_scheduled_time = moment(
    //   guidance_scheduled_time
    // ).format("YYYY-MM-DDThh:mm[:ss[.uuuuuu]][+HH:MM|-HH:MM|Z]");
    // const api = reserveAPI.createPost(headers, JSON.stringify(data_object));

    data_object.prescription = Object.keys(checkedItems);


    if (data_object.guidance_scheduled_time.includes("undefined")) {
      delete data_object.guidance_scheduled_time;
    }
    setCheckDisabledButtonCreate(true);
    const response = getReceipt.sendData(data_object, headers);
    console.log("response", response);
    //console.log(api);

    response
      .then((res) => {
        toast.success(
          "処方箋を受け取りを予約できました",
          {
            position: toast.POSITION.TOP_RIGHT,
          },
          1000
        );
        setTimeout(() => {
          window.location.href = "/mypage/reserve";
        }, 2000);
      })
      .catch((err) => {})
      .finally(() => {
        setCheckDisabledButtonCreate(false);
      });
  };

  const getDrop = (item) => {
    getLocaldrop("drop") == null ? setLocaldrop(item) : console.log("work");
  };

  if (isLoading) return <Loader />;
  // if (isLoading) return <></>;

  return (
    <>
      <main className="p-reserve">
        <MypageTopMenu />
        <ToastContainer />
        <section className="p-reserve__new">
          <h2 className="c-sectionTitle c-mypageHeading">処方箋受け取り予約</h2>
          <center>
            <div
              style={{
                marginLeft: "23px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "12%",
                lineHeight: "12px",
              }}
            >
              {Object.values(errorMessages).length !== 0 ? (
                <>
                  {errorMessages && errorMessages.storeName !== "" ? (
                    <p
                      className="l-formInput__errorMessage"
                      style={{ color: "red" }}
                    >
                      {errorMessages.storeName}
                    </p>
                  ) : (
                    <span style={{ display: "none" }}>{""}</span>
                  )}
                  <br />
                  {errorMessages && errorMessages.teachDateTime !== "" ? (
                    <p
                      className="l-formInput__errorMessage"
                      style={{ color: "red" }}
                    >
                      {errorMessages.teachDateTime}
                    </p>
                  ) : (
                    <span style={{ display: "none" }}>{""}</span>
                  )}
                  <br />
                  {errorMessages && errorMessages.prescription !== "" ? (
                    <p
                      className="l-formInput__errorMessage"
                      style={{ color: "red" }}
                    >
                      {errorMessages.prescription}
                    </p>
                  ) : (
                    <span style={{ display: "none" }}>{""}</span>
                  )}
                  <br />
                  {errorMessages && errorMessages.reservationDateTime !== "" ? (
                    <p
                      className="l-formInput__errorMessage"
                      style={{ color: "red" }}
                    >
                      {errorMessages.reservationDateTime}
                    </p>
                  ) : (
                    <span style={{ display: "none" }}>{""}</span>
                  )}
                  <br />
                </>
              ) : (
                ""
              )}
            </div>
          </center>

          <div className="p-reserve__new__container">
            <form onSubmit={handleSubmit}>
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "18px",
                    justifyContent: "space-between",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11px",
                      fontWeight: "bold",
                      fontFamily: "NotoSansJP_bold",
                    }}
                  >
                    かかりつけ（薬局・薬店）
                  </span>
                  <span
                    className="l-formInput__required"
                    style={{
                      //marginBottom: "px",
                      // marginLeft: "750px",
                      paddingTop: "1px",
                      paddingLeft: "9px",
                      paddingRight: "9px",

                      // marginBottom: "20px",
                    }}
                  >
                    必須
                  </span>
                </div>
                <div className="c-selectBox black">
                  <div className="c-selectBox__container">
                    <select
                      id="dropdown_id"
                      className={errorMessages.storeName ? "error": ""}
                      defaultValue={
                        getLocaldrop(drop) == null ? 0 : getLocaldrop(drop)
                      }
                    >
                      {drp.map((item, i) => {
                        return (
                          <option
                            value={item.id}
                            key={item.id}
                            onClick={() => getDrop(item.id)}
                            selected={
                              item.drugstore_name === storeName ? true : ""
                            }
                          >
                            {item.drugstore_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                { 
                  errorMessages.storeName ? (
                    <span class="l-formInput__errorMessage">{errorMessages.storeName}</span>
                  ) : ""
                }
                {/* 
{droparr?.map((item) => {
                        return (
                          <FormBorderInput
                            name="drugstore_name"
                            label={item.drugstore_name}
                            placeholder="かかりつけ薬局・薬店を入力してください"
                            required="true"
                            value={item.drugstore_name}
                            type="text"
                            errorMessage={
                              errorMessages.storeName
                                ? errorMessages.storeName
                                : ""
                            }
                          />
                        );
                      })} */}
              </>
              <div style={{ paddingTop: "13px" }}>
                <FormDateTimeInput
                  label="予約申請日時"
                  required={true}
                  errorMessage={
                    errorMessages.reservationDateTime
                      ? errorMessages.reservationDateTime
                      : ""
                  }
                  initialValue={initialValue}
                  dateName="reservationDate"
                  timeName="reservationTime"
                />
              </div>
              <div className="p-reserve__new__prescription l-formInput__checkbox">
                <p className="l-formInput__checkbox__title">
                  申請する処方箋
                  <span className="l-formInput__required">必須</span>
                </p>
                {data?.results?.map((item, i) => {
                  return (
                    <label key={i}>
                      <input
                        type="checkbox"
                        name="prescription"
                        value={item.id}
                        onChange={handleChange}
                        checked={checkedItems[item.id]}
                      />

                      <div className="l-formInput__checkbox__text">
                        <div>
                          <span className="c-mypageList__time">
                            {item.prescription_date}
                          </span>

                          <span className="c-itemsList__limit">
                            有効期限 &#058; {item.limit}まで
                          </span>
                        </div>
                        <div className="c-mypageList__hospital">
                          {item.medical_institution_name}
                          <span>{item.prescribing_physician}</span>
                        </div>
                        <div className="c-mypageList__name">
                          {item.prescription_details}
                        </div>
                      </div>
                    </label>
                  );
                })}
                {errorMessages.prescription && (
                  <span className="l-formInput__errorMessage">
                    {errorMessages.prescription}
                  </span>
                )}
                <center style={{ marginTop: '30px' }}>
                  <Pagination
                    size="medium"
                    total={data && data.count}
                    onChange={onChangePagePrescription}
                    current={pageNumberPrescription}
                    showSizeChanger={false}
                  />
                </center>
              </div>
              {/* <div className="c-mainButton photo center">
                処方箋を撮影して追加する
              </div> */}
              <FormCheckBox
                title="リモート服薬指導について"
                name="teach"
                value="希望する"
                label="リモート服薬指導を希望する"
                handleChecked={(e) => handleChecked(e)}
              />
              {remortDetail && (
                <>
                  <FormDateTimeInput
                    label="予約申請日時"
                    required={true}
                    errorMessage={
                      errorMessages.teachDateTime
                        ? errorMessages.teachDateTime
                        : ""
                    }
                    initialValue={initialValue}
                    dateName="teachDate"
                    timeName="teachTime"
                  />
                  <FormRadio
                    name="receive"
                    title="リモート服薬指導時の処方受け取りについて"
                    options={options}
                    required={true}
                  />
                </>
              )}
              <div className="l-formInput__form border">
                <label>
                  予約申請メッセージ
                  <span className="l-formInput__any">任意</span>
                </label>
                <textarea
                  name="message"
                  placeholder="予約申請メッセージの詳細を入力してください"
                  className="c-textarea__border"
                />
              </div>
              <MainButton
                text="この内容で処方箋予約を申請する"
                color="blue min"
              />
            </form>
          </div>

          <LinkText
            text="処方受け取り予約一覧に戻る"
            url="/mypage/reserve"
            className="underline"
          />
        </section>

        <section className={open ? "l-confirm open" : "l-confirm"}>
          <button className="l-confirm__closeBtn" onClick={handleClickClose} />
          <h2>処方箋受け取りを予約</h2>
          <div className="l-confirm__table">
            <dl>
              <div>
                <strong>
                  <dt
                    style={{
                      fontSize: "11px",
                      fontWeight: 900,
                      fontFamily: "NotoSansJP_bold",
                    }}
                  >
                    かかりつけ（薬局・薬店）
                  </dt>
                </strong>
                {drugstore && <dd>{drugstore}</dd>}
              </div>
              <div>
                <dt>予約申請日時</dt>
                {formValues.reservationDate && (
                  <dd>
                    {formValues.reservationDate.replaceAll("-", "/")}{" "}
                    {formValues.reservationTime}
                  </dd>
                )}
              </div>
              <div className="p-reserve__new__prescription">
                <dt>申請する処方箋</dt>
                {selectedPres.map((item, i) => {
                  return (
                    selectedPres &&
                    selectedPres.length !== 0 && (
                      <dd key={i}>
                        <div>
                          <span className="c-mypageList__time">
                            {item.prescription_date}
                          </span>
                          <span className="c-itemsList__limit">
                            有効期限 &#058; {""}まで
                          </span>
                        </div>
                        <div className="c-mypageList__hospital">
                          {item.medical_institution_name}
                          <span>{item.prescribing_physician}</span>
                        </div>
                        <div className="c-mypageList__name">{item.name}</div>
                      </dd>
                    )
                  );
                })}
              </div>
              <div>
                <dt>リモート服薬指導について</dt>
                <dd>{remortDetail ? "希望する" : "希望しない"}</dd>
              </div>
              {remortDetail && (
                <>
                  <div>
                    <dt>リモート服薬指導予約申請日時</dt>
                    {formValues.teachDate && (
                      <dd>
                        {formValues.teachDate.replaceAll("-", "/")}{" "}
                        {formValues.teachTime}
                      </dd>
                    )}
                  </div>
                  <div>
                    <dt>リモート服薬の処方受け取り</dt>
                    {formValues.receive && (
                      <dd>
                        {formValues.receive === "郵送"
                          ? "郵送で処方を"
                          : "別の予約で"}
                        受け取る
                      </dd>
                    )}
                  </div>
                </>
              )}
              <div>
                <dt>予約申請メッセージ</dt>
                {formValues.message && <dd>{formValues.message}</dd>}
              </div>
            </dl>
            <MainButton
              text="この内容で処方箋を受け取りを予約する"
              color="blue min"
              onClick={handleCreate}
              disabled={checkDisabledButtonCreate}
            />
          </div>
        </section>
      </main>
    </>
  );
}

// {
//   "id": 12,
//   "prescription_date": "2022-05-25",
//   "time": null,
//   "isRed": false,
//   "insurer_number": "12345678@qwerty",
//   "name": "anandan",
//   "birthday": "1970-01-01",
//   "gender": "male",
//   "medical_institution_name": "dr cooger",
//   "contact": "82341567",
//   "prescribing_physician": "dr cooger",
//   "prescription_details": "dr cooger daignosis is lorem iipsum",
//   "change_to_generic_drugs": "possible",
//   "number_of_split_dispensing": 6,
//   "prescription_image": "/media/patient/prescriptions/images/Screenshot_2022-05-25_134353.png"
// },

//   message: "qqqqq"
// prescription: Array(2)
// 0: "1"
// 1: "12"
// length: 2
// [[Prototype]]: Array(0)
// receive: "郵送"
// reservationDate: "2022-06-06"
// reservationTime: "12:15"
// storeName: "gggggg"
// teach: "希望する"
// teachDate: "2022-06-06"
// teachTime: "12:00"

// message: "zzzzz"   //https://dev-api-pharmacy.automagicjapan.tech/api/drugstore/     //get request for dropdown(drugstore)
// prescription: (2) ['1', '2']
// receive: "郵送"       //id (separate API )   //reci  https://dev-api-pharmacy.automagicjapan.tech/api/recieve_choice/
// reservationDate: "2022-06-03"
// reservationTime: "12:15"
// storeName: "bmbmbm"   //pharmacy field id
// teach: "希望する"                 //"medication_guidence" : true
// teachDate: "2022-06-03"
// teachTime: "10:15"                //guidance_scheduled_time : 2022-05-16T09:15:43Z(in string)

//reservation_scheduled_time (in string)
//2022-05-16T09:15:43Z

//https://dev-api-pharmacy.automagicjapan.tech/api/prescription_api/

//choice_en
//choice_jpn

//new comment
