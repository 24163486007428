import { useEffect, useState, useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Calendar, Select, Radio, Col, Row, Typography } from "antd";
import locale from "antd/es/date-picker/locale/ja_JP";
import moment from "moment";
import "moment/locale/ja";
import "antd/dist/antd.css";

import { MypageTopMenu } from "../components/ui";
import calendar from "../../apis/calender";
import { getAuthToken } from "../../helpers/storage";
import { Reserve } from "../components/projects";
import { Contextdata } from "../../UserContext";
import Loader from "../components/ui/loader";

export default function Calandar() {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [enable, setEnable] = useState(false);

  const navigate = useNavigate();
  const { selectedCalendarDate, setSelectedCalendarDate } =
    useContext(Contextdata);

  const prescriptions = useMemo(() => {
    if (!data || !selectedCalendarDate) return undefined;
    const selectedDateItems = data[selectedCalendarDate.format("YYYY-MM-DD")];
    return selectedDateItems?.map((item) => item.reservation_details);
  }, [data, selectedCalendarDate]);

  const token = "token" + " " + getAuthToken();
  const headers = { Authorization: token };

  const getData = async () => {
    try {
      const response = await calendar.getCalendar(headers);
      const validItems = response.data.filter((item) => item.reservation_date);

      const itemMap = validItems.reduce((acc, item) => {
        const reservationDate = moment(item.reservation_date).format(
          "YYYY-MM-DD"
        );
        if (reservationDate in acc) {
          const dateItems = acc[reservationDate];
          return { ...acc, [reservationDate]: [...dateItems, item] };
        } else {
          return { ...acc, [reservationDate]: [item] };
        }
      }, {});
      setData(itemMap);
    } catch (e) {
      console.log("error", e);
    } finally {
      setIsLoading(false);
    }
  };

  const dateCellRender = (date) => {
    if (!data) return;

    const currentDateFormatted = date.format("YYYY-MM-DD");
    const currentDateItems = data[currentDateFormatted];

    return (
      <div
        style={{
          fontSize: "50px",
          color: "blue",
          visibility: currentDateItems ? "visible" : "hidden",
        }}
      >
        {".".repeat(currentDateItems?.length || 1)}
      </div>
    );
  };

  const onSelect = (date) => setSelectedCalendarDate(date);

  useEffect(() => {
    getAuthToken() === null ? navigate("/") : setEnable(true);
  }, []);

  useEffect(() => getData(), [enable]);

  if (isLoading) return <Loader />;

  return (
    <main className="p-calendar">
      <MypageTopMenu />

      <section>
        <h2 className="c-sectionTitle c-mypageHeading">通院カレンダー</h2>

        <div className="p-calendar__calendar">
          <div
            className="site-calendar-customize-header-wrapper"
            style={{
              backgroundColor: "white",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <Calendar
              fullscreen={false}
              locale={locale}
              headerRender={({ value, type, onChange, onTypeChange }) => {
                const start = 0;
                const end = 12;
                const monthOptions = [];
                const current = value.clone();
                const localeData = value.localeData();
                const months = [];

                for (let i = 0; i < 12; i++) {
                  current.month(i);
                  months.push(localeData.monthsShort(current));
                }

                for (let index = start; index < end; index++) {
                  monthOptions.push(
                    <Select.Option className="month-item" key={`${index}`}>
                      {months[index]}
                    </Select.Option>
                  );
                }

                const month = value.month();
                const year = value.year();
                const options = [];

                for (let i = year - 10; i < year + 10; i += 1) {
                  options.push(
                    <Select.Option key={i} value={i} className="year-item">
                      {i}
                    </Select.Option>
                  );
                }

                return (
                  <div
                    style={{
                      padding: 8,
                    }}
                  >
                    <Typography.Title level={4}></Typography.Title>
                    <Row gutter={8}>
                      <Col>
                        <Radio.Group
                          size="small"
                          onChange={(e) => onTypeChange(e.target.value)}
                          value={type}
                        >
                          <Radio.Button value="month">月</Radio.Button>
                          <Radio.Button value="year">年</Radio.Button>
                        </Radio.Group>
                      </Col>
                      <Col>
                        <Select
                          size="small"
                          dropdownMatchSelectWidth={false}
                          className="my-year-select"
                          onChange={(newYear) => {
                            const now = value.clone().year(Number(newYear));
                            onChange(now);
                          }}
                          value={String(year)}
                        >
                          {options}
                        </Select>
                      </Col>
                      <Col>
                        <Select
                          size="small"
                          dropdownMatchSelectWidth={false}
                          value={String(month)}
                          onChange={(selectedMonth) => {
                            const newValue = value.clone();
                            newValue.month(parseInt(selectedMonth, 10));
                            onChange(newValue);
                          }}
                        >
                          {monthOptions}
                        </Select>
                      </Col>
                    </Row>
                  </div>
                );
              }}
              dateCellRender={dateCellRender}
              defaultValue={selectedCalendarDate}
              onSelect={onSelect}
            />
          </div>
        </div>
        <div>
          <ul>
            <div
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "10px",
              }}
            >
              {prescriptions?.map((item, i) => {
                return <Reserve key={i} item={item} />;
              })}
            </div>
          </ul>
        </div>
      </section>
    </main>
  );
}
//updates
