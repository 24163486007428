import axios from "axios";
// log in
const logIn = (data) =>
  axios.post("https://dev-api-pharmacy.automagicjapan.tech/api/patient/login/ ", data);

const logInApi = {
  logIn,
};

export default logInApi;

//login API has been changed