import { memo, useState } from "react";
import { isEntered } from "../../../utils/validation";
import { FormBorderInput, MainButton } from ".";
import userMemo from "../../../apis/usermemo";
import { getAuthToken, getFromLocalStorage } from "../../../helpers/storage";
import { useNavigate, useParams } from "react-router-dom";
import { getFavorite } from "../../../apis/favorities";
import { useEffect } from "react";

const FormMemoField = memo(({ onSubmit, item }) => {
  // 入力バリデーション
  const [errorMessages, setErrorMessages] = useState({});

  const navigate = useNavigate();

  const token = "token" + " " + getFromLocalStorage("token");
  const headers = { Authorization: token };
  const [favorites, setFavorites] = useState([]);
  const [phamacyName, setPhamacyName] = useState(item?.pharmacy_name)
  const userId=window.localStorage.getItem("id") ? window.localStorage.getItem("id") : null;

  const id = useParams();

  const isValidate = (values) => {
    let messages = {};

    // messages.store = isEntered(
    //   "かかりつけ薬局・薬店",
    //   values.pharmacy_name,
    //   false
    // );
    // messages.personName = isEntered("担当薬剤師", values.pharmacist, false);
    messages.title = isEntered("服薬指導メモタイトル", values.title, false);

    setErrorMessages({ ...messages });
    return Object.values(messages).every((message) => message === "");
  };

  const getData = async () => {
    const response = await getFavorite(userId);
    setFavorites(response);
  };

  useEffect(() => {
    getData();
  }, []);

  // 新規メモ登録
  // const navigate = useNavigate()
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const values = Object.fromEntries(data.entries());

    if (isValidate(values)) {
      // 新規登録処理
      onSubmit();

      // navigate('/mypage/memo')
    }
    console.log("pharmcy", item.pharmacy);
    console.log("values", values);

    item.prescription_reservation == null
      ? console.log("test")
      : delete values.pharmacy_name;
    item.prescription_reservation == null
      ? console.log("test")
      : delete values.pharmacist;

    try {
      values.is_usermemo_added = true;
      values.pharmacy_name = phamacyName;
      await userMemo.memoMessage(id.id, values, headers);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="p-memo__new__container">
      <form onSubmit={handleSubmit}>
        <div className="l-formInput__form border">
        <label>  かかりつけ薬局・薬店 </label>
        <div className="c-selectBox__container black" style={{marginTop: '10px'}} >
          <select
            disabled={item.prescription_reservation == null ? false : true}
            name="pharmacy_name"
            onChange={(e) => setPhamacyName(e.target.value)}
            value={Number(phamacyName)}
          >
            <option value="">都道府県</option>
            {favorites.map((item, i) => {
              return (
                <option value={item.drugstore} key={i}>
                  {item.drugstore_name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
        <FormBorderInput
          name="pharmacist"
          label="担当薬剤師"
          placeholder={item?.pharmacist}
          data1={item?.pharmacist}
          required="true"
          type="text"
          disabled={item.prescription_reservation == null ? false : true}

          // errorMessage={
          // errorMessages.personName ? errorMessages.personName : ""
          //}
        />
        <FormBorderInput
          name="title"
          label="タイトル"
          placeholder="服薬指導メモタイトルを入力してください"
          required="true"
          data1={item?.title}
          type="text"
          errorMessage={errorMessages.title ? errorMessages.title : ""}
        />
        <div className="l-formInput__form border">
          <label>
            服薬指導メモ詳細
            <span className="l-formInput__any">任意</span>
          </label>
          <textarea
            name="description"
            placeholder="服薬指導メモの詳細を入力してください"
            className="c-textarea__border"
            defaultValue={item?.description}
          />
        </div>
        <MainButton text="この内容で服薬指導メモを登録する" color="blue min" />
      </form>
    </div>
  );
});

export default FormMemoField;
