import {
  NewsPageList as Lists,
  LinkButton,
  SignUpLink,
} from "../components/projects";
import { SearchStore } from "../components/ui";
import { useEffect } from "react";
import noticeAPI from "../../apis/notice";
import { useState } from "react";
import moment from "moment";
import Loader from "../components/ui/loader";
import { getAuthToken } from "../../helpers/storage";
import { Pagination } from "antd";

// お知らせダミーデータ
const newsItems = [
  {
    date: "2021/12/21",
    time: "13:22",
    text: "お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。",
    id: 1,
  },
  {
    date: "2021/12/21",
    time: "13:22",
    text: "お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。",
    id: 2,
  },
  {
    date: "2021/12/21",
    time: "13:22",
    text: "お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。",
    id: 3,
  },
  {
    date: "2021/12/21",
    time: "13:22",
    text: "お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。",
    id: 4,
  },
  {
    date: "2021/12/21",
    time: "13:22",
    text: "お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。お知らせタイトルテキスト。",
    id: 5,
  },
];

export default function NewsList() {
  const token = getAuthToken();
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);

  const todaysDate = moment(moment().toDate()).format("YYYY-MM-DD");

  const onChange = (page) => {
    setPageNumber(page);
  };
  const getData = async () => {
    try {
      const response = await noticeAPI.notice(pageNumber);
      setNews(response && response);
      console.log("response", response);
    } catch (e) {
      console.log("error", e);
    }
    setIsLoading(false);
  };

  useEffect(() => getData(), [pageNumber]);

  if (isLoading) return <Loader />;

  return (
    <main className="p-newsList">
      {!token && <SignUpLink />}

      <section>
        <h2 className="c-sectionTitle">じぶん薬局からのお知らせ一覧</h2>
        <dl>
          {news?.data.results.map((item, i) => (
            <Lists key={i} to={`/news/${item.id}`} item={item} />
          ))}
        </dl>
        <center>
          <Pagination
            size="medium"
            total={news && news.data.count}
            onChange={onChange}
            current={pageNumber}
            showSizeChanger={false}
          />
        </center>
      </section>

      <LinkButton
        tex t="じぶん薬局へのお問い合わせ"
        url="/contact"
        color="blue"
      />

      <SearchStore />
    </main>
  );
}
